import { createSlice } from '@reduxjs/toolkit';

import { axiosAuthInstance } from '../utils/axios-utils';
import { API_BASE, CROSS_ENDPOINTS } from '../constants';
import { AppThunk } from '.';
import { enqueueSnackbar } from './snackbarSlice';

type Support = {
  loading: boolean;
  error: boolean;
  data: { [k: string]: any }[];
};

export const initialState: Support = {
  loading: true,
  error: false,
  data: [],
};

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setData, setError } = supportSlice.actions;

export const getTickets =
  ({ email, limit }): AppThunk =>
  async (dispatch) => {
    if (!email) {
      dispatch(setData([]));
      dispatch(setLoading(false));
      return;
    }
    try {
      const formdata = new FormData();
      formdata.append('email', email);
      formdata.append('max', `${limit}`);

      const res = await axiosAuthInstance.post(
        `${API_BASE}${CROSS_ENDPOINTS.getTickets}`,
        formdata,
      );
      dispatch(setData(res.data));
      if (res.data?.message === 'no_tickets') {
        dispatch(setData([]));
        dispatch(setLoading(false));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createTicket =
  ({ email, type, details }): AppThunk =>
  async (dispatch) => {
    if (!email) {
      return dispatch(setLoading(false));
    }
    try {
      const formdata = new FormData();
      formdata.append('email', email);
      formdata.append('type', type);
      formdata.append('details', details || '');
      axiosAuthInstance
        .post(`${API_BASE}${CROSS_ENDPOINTS.createTicket}`, formdata)
        .then((res) => {
          if (res.data.message === 'ticket_successfully_created') {
            dispatch(
              enqueueSnackbar({
                message: 'Ticket successfully created',
                variant: 'success',
              }),
            );
          }
        });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export default supportSlice.reducer;
