import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '../store/snackbarSlice';
import { AppDispatch, RootState } from '../store';

const Notifier = () => {
  const dispatch = useDispatch<AppDispatch>();
  const notifications = useSelector(
    (store: RootState) => store.snackbar.notifications || {},
  );
  const displayedRef = useRef([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    Object.keys(notifications)
      .filter((key) => !displayedRef.current.includes(key))
      .forEach((key) => {
        const { message, ...options } = notifications[key];
        displayedRef.current.push(options.key);
        enqueueSnackbar(message, {
          ...options,
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(myKey));
          },
        });
      });
  }, [notifications, enqueueSnackbar, dispatch]);
  return null;
};

export default Notifier;
