import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import type { RootState } from './../store/index';
import type { AuthState } from '../store/authSlice';
import type { Profile } from '../store/profileSlice';

export function useAuth() {
  const auth: Partial<AuthState> = useSelector<RootState>(
    (state) => state.auth,
  );

  return auth;
}

export function useAuthRedirect(protect = true, omit = false) {
  const { token }: Partial<AuthState> = useSelector<RootState>(
    (state) => state.auth,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (omit) {
      return;
    }
    if (protect && !token) {
      navigate('/login');
    }

    if (!protect && token) {
      navigate('/dashboard');
    }
  }, [protect, navigate, token, omit]);
}

export function useProfileRedirect() {
  const profile: Partial<Profile> = useSelector<RootState>(
    (state) => state.profile,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!profile.data.retailer_contact_first_name) {
      navigate('/profile');
    }
  }, [profile.data.retailer_contact_first_name, navigate]);
}

export function useDashboardRedirect() {
  const profile: Partial<Profile> = useSelector<RootState>(
    (state) => state.profile,
  );

  const navigate = useNavigate();

  useEffect(() => {
   
      navigate('/dashboard');
      alert("HERE")

  }, [profile.data.retailer_contact_first_name, navigate]);
}