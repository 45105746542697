import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton, Link,
  Menu,
  MenuItem, Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import palette, { customColors } from '../theme/palette';
import { ReactComponent as Apple } from "../Landing Page/src/assets/Apple.svg";
import { ReactComponent as GooglePlay } from "../Landing Page/src/assets/GP.svg";
import { ReactComponent as Windows } from "../Landing Page/src/assets/Windows.svg";
import styles from "../Landing Page/src/styles";
import { ReactComponent as Shopify } from '../Landing Page/src/assets/Shopify.svg';

const style = {
  appBar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: customColors.white,
    justifyContent: "space-between",
    boxShadow: "0px 0px",
    padding: "50px",
    alignItems: "center",
    paddingRight: "110px",
    ['@media (max-width:1300px)']: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: "10px",
      paddingRight: "0px",
    },
  },
}

export default ({appLinks}: any) => {
  return (
    <AppBar sx={{ ...style.appBar }} position="static">
      <Box sx={{['@media (max-width:1300px)']: {
          marginBottom: "10px"
        }}}>
        { appLinks.ios_app_link ?
          <Button href={appLinks.ios_app_link}>
            <Apple style={{ ...styles.imageIcon }} />
          </Button>
          : null}
        { appLinks.android_app_link ?
          <Button href={appLinks.android_app_link}>
            <GooglePlay style={{ ...styles.imageIcon }} />
          </Button>
          : null}
        { appLinks.win_app_link ?
          <Button href={appLinks.win_app_link}>
            <Windows style={{ ...styles.imageIcon }} />
          </Button>
          : null}
        { appLinks.shopify_app_link ?
          <Button href={appLinks.shopify_app_link}>
            <Shopify style={{ ...styles.imageIcon, maxHeight: '48px', maxWidth: '48px' }} />
          </Button>
          : null}
      </Box>
      <Typography variant="subtitle2" sx={{fontWeight: "bold", ['@media (max-width:1300px)']: {
          marginBottom: "10px"
        }}}>
        © GoFlyy, 2022
      </Typography>
      <Box sx={{['@media (max-width:1300px)']: {
          marginBottom: "10px"
        }}}>
        <Link href="#" sx={{marginRight: "10px", color: customColors.black}}>Terms of Service</Link>
        <Link href="#" sx={{marginRight: "10px", color: customColors.black}}>Privacy Policy</Link>
        <Link href="#" sx={{color: customColors.black}}>Offer Agreement</Link>
      </Box>
        {/*
      <Box sx={{['@media (max-width:1300px)']: {
          marginBottom: "10px"
        }}}>
        
        <Link href="https://shop.goflyy.com" sx={{color: customColors.purple, fontWeight: "bold", marginRight: "10px", }} underline="hover" target="_blank">For Shopper</Link>
        <Link href="https://driver.goflyy.com/" sx={{color: customColors.purple, fontWeight: "bold", }} underline="hover" target="_blank">For Driver</Link>

      </Box>
      */}
      
      <Typography variant="subtitle2">
        Design — <b>Atwinta</b>
      </Typography>
    </AppBar>
  )
}