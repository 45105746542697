export const accordions = [
  {
    question: 'Shiftforshop',
    answerKey: 'api_doc_shiftforshop',
    videoKey: 'api_doc_video_guide_shiftforshop',
  },
  {
    question: 'Bigcommerce',
    answerKey: 'api_doc_text_bigcommerce',
    videoKey: 'api_doc_video_guide_bigcommerce',
  },
  {
    question: 'Magento',
    answerKey: 'api_doc_text_magento',
    videoKey: 'api_doc_video_guide_magento',
  },
  {
    question: 'Shopify',
    answerKey: 'api_doc_text_shopify',
    videoKey: 'api_doc_video_guide_shopify',
  },
  {
    question: 'Shopwired',
    answerKey: 'api_doc_text_shopwired',
    videoKey: 'api_doc_video_guide_shopwired',
  },
  {
    question: 'Suarespace',
    answerKey: 'api_doc_text_suarespace',
    videoKey: 'api_doc_video_guide_suarespace',
  },
  {
    question: 'Volusion',
    answerKey: 'api_doc_text_volusion',
    videoKey: 'api_doc_video_guide_volusion',
  },
  {
    question: 'Wix',
    answerKey: 'api_doc_text_wix',
    videoKey: 'api_doc_video_guide_wix',
  },
  {
    question: 'WooCommerce',
    answerKey: 'api_doc_text_woocommerce',
    videoKey: 'api_doc_video_guide_woocommerce',
  },
  {
    question: 'Zyro',
    answerKey: 'api_doc_text_zyro',
    videoKey: 'api_doc_video_guide_zyro',
  },
];
