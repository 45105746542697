import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';

import { API_BASE, CROSS_ENDPOINTS } from '../../constants';
import Box from '../../components/Box';
import { AppDispatch } from '../../store';
import { enqueueSnackbar } from '../../store/snackbarSlice';

export default function ConfirmEmail() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const searchSplit = search.split('&');

  const email = searchSplit[0].replace('?email=', '');
  const code = searchParams.get('code');

  useEffect(() => {
    if (email && code) {
      const validateCode = async () => {
        const formdata = new FormData();
        formdata.append('email', email);
        formdata.append('code', code);

        const res = await axios.post(
          `${API_BASE}${CROSS_ENDPOINTS.verifyEmailCode}`,
          formdata,
        );

        if (res.data.message === 'email_successfully_verified') {
          dispatch(
            enqueueSnackbar({
              message: 'Email successfully verified',
              variant: 'success',
            }),
          );
        }
        if (res.data.message === 'wrong_code') {
          dispatch(
            enqueueSnackbar({
              message: 'Wrong code',
              variant: 'error',
            }),
          );
        }
        if (res.data.message === 'user_not_exist') {
          dispatch(
            enqueueSnackbar({
              message: 'User not exist',
              variant: 'error',
            }),
          );
        }

        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 3000);
      };
      validateCode();
    }
  }, []); // eslint-disable-line

  return (
    <Box
      as="h1"
      css={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      You will be redirected soon!
    </Box>
  );
}
