import Box from '../../components/Box';
import Button from '../../components/Button';
import LinkStyled from '../../components/LinkStyled';
import PagesLayout from '../../components/PagesLayout';
import * as Select from '@radix-ui/react-select';
import { styled } from '../../stitches.config';
import Card from '../../components/Card';
import TextArea from '../../components/TextArea';
import { useState } from 'react';
import {
  useAuth,
  useAuthRedirect,
  useProfileRedirect,
} from '../../utils/use-auth';
import { axiosAuthInstance } from '../../utils/axios-utils';
import { API_BASE, CROSS_ENDPOINTS } from '../../constants';
import { ArrowSmLeftIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { enqueueSnackbar } from '../../store/snackbarSlice';
import { createTicket as doCreateTicket } from '../../store/supportSlice';

const Trigger = styled(Select.Trigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '$5',
  padding: '0 1rem',
  height: '2.6rem',
  width: '100%',
  border: 'solid .15rem $gray',
  backgroundColor: 'white',
  color: '$dark',
  marginTop: '2rem',
  gap: 10,
  fontSize: 10,
  '&:hover': { color: '$calmYellow' },
  '@sm': { width: '50%' },
});

const Content = styled(Select.Content, {
  overflow: 'hidden',
  borderRadius: '$5',
  backgroundColor: '$white',
  width: '100%',
  height: 'auto',
  border: 'solid .15rem $gray',
  justifyItems: 'center',
});

const Viewport = styled(Select.Viewport, { padding: '.5rem' });

const Item = styled(Select.Item, {
  all: 'unset',
  color: '$dark',
  display: 'flex',
  alignItems: 'center',
  height: '1.5rem',
  padding: '0 25px 0 25px',
  userSelect: 'none',
  fontSize: '.7rem',
  '&:focus': {
    color: '$calmYellow',
  },
});

const NewTiket = () => {
  useAuthRedirect();
  useProfileRedirect();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { email } = useAuth();

  const [createTicketData, setCreateTicketData] = useState({
    type: '',
    details: '',
  });

  const createTicket = () => {
    const { type, details } = createTicketData;
    dispatch(doCreateTicket({ email, type, details }));
    navigate('/support');
  };

  return (
    <PagesLayout>
      <Box
        as="section"
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Box css={{ display: 'flex', alignItems: 'center' }}>
          <LinkStyled to="/support">
            <Button color="white" css={{ padding: '2px 5px 1px 5px' }}>
              <ArrowSmLeftIcon style={{ height: '24px' }} />
            </Button>
          </LinkStyled>
          <Box as="h1" css={{ fontSize: '1.5rem', marginLeft: '2rem' }}>
            New ticket
          </Box>
        </Box>
        <Box css={{ marginRight: '1.5rem' }}>
          <Select.Root
            defaultValue="type"
            onValueChange={(value) =>
              setCreateTicketData((state) => ({
                ...state,
                type: value,
              }))
            }
          >
            <Trigger>
              <Select.Value />
              <Select.Icon style={{ paddingTop: '.5rem' }}>
                <ChevronDownIcon style={{ height: '22px' }} />
              </Select.Icon>
            </Trigger>
            <Content>
              <Select.ScrollUpButton></Select.ScrollUpButton>
              <Viewport>
                <Item value="type">
                  <Select.ItemText>Type</Select.ItemText>
                </Item>
                <Item value="package">
                  <Select.ItemText>Package</Select.ItemText>
                </Item>
                <Item value="website">
                  <Select.ItemText>Website</Select.ItemText>
                </Item>
                <Item value="delivery">
                  <Select.ItemText>Delivery</Select.ItemText>
                </Item>
                <Item value="other">
                  <Select.ItemText>Other</Select.ItemText>
                </Item>
              </Viewport>
            </Content>
          </Select.Root>
        </Box>
        <Card
          color="gray"
          css={{
            width: '100%',
            height: 'auto',
            marginTop: '2rem',
            padding: '.5rem',
            br: '$5',
            display: 'flex',
            flexDirection: 'column',
            '@sm': { width: '50%' },
          }}
        >
          <Box
            as="span"
            css={{ fontSize: '.7rem', padding: '.5rem', color: '$gray2' }}
          >
            Details
          </Box>
          <TextArea
            rows={3}
            css={{ backgroundColor: '$gray', border: 'none' }}
            onChange={(e) =>
              setCreateTicketData((state) => ({
                ...state,
                details: e.target.value,
              }))
            }
          />
        </Card>
        <Button
          color="calmYellow"
          disabled={!createTicketData.details || !createTicketData.type}
          css={{ width: '105%', marginTop: '2rem', '@sm': { width: '15rem' } }}
          onClick={createTicket}
        >
          Create ticket
        </Button>
      </Box>
    </PagesLayout>
  );
};

export default NewTiket;
