import { createSlice } from '@reduxjs/toolkit';

import { axiosAuthInstance } from '../utils/axios-utils';
import { API_BASE, ECOMMERCE_ENDPOINTS } from '../constants';

import { AppThunk } from '.';

type Dashboard = {
  loading: boolean;
  error: boolean;
  data: { [k: string]: any }[];
};

export const initialState: Dashboard = {
  loading: true,
  error: false,
  data: [],
};

export const profleSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setData, setError } = profleSlice.actions;

export const getOrders =
  ({ email, apiKey, limit, dateFilter, filter }): AppThunk =>
  async (dispatch) => {
    if (!email || !apiKey) {
      dispatch(setData([]));
      dispatch(setLoading(false));
      return;
    }

    try {
      dispatch(setLoading(true));

      const formdataOrders = new FormData();
      formdataOrders.append('email', email);
      formdataOrders.append('limit', `${limit}`);
      formdataOrders.append('age_days', dateFilter);
      // formdataOrders.append('skip', `skip-value`);
      formdataOrders.append('type_order', filter);

      const resOrders = await axiosAuthInstance.post(
        `${API_BASE}${ECOMMERCE_ENDPOINTS.getOrdersList}`,
        formdataOrders,
        {
          headers: { apiKey },
        },
      );

      if (resOrders.data?.message === 'no_orders_found') {
        dispatch(setData([]));
        dispatch(setLoading(false));
      }

      const orderDetailListProm = resOrders.data?.map((order) => {
        const formdataOrder = new FormData();
        formdataOrder.append('email', email);
        formdataOrder.append('order_id', order.order_id);

        return axiosAuthInstance.post(
          `${API_BASE}${ECOMMERCE_ENDPOINTS.getMyOrder}`,
          formdataOrder,
          {
            headers: { apiKey },
          },
        );
      });

      const orderDetails = await Promise.all(orderDetailListProm);

      dispatch(
        setData(
          orderDetails.map((order: { [k: string]: any }) => {
            return order.data[0];
          }),
        ),
      );
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export default profleSlice.reducer;
