import type { PropertyValue, ScaleValue } from '@stitches/react';
import { createStitches } from '@stitches/react';

export const {
  config,
  createTheme,
  css,
  getCssText,
  globalCss,
  keyframes,
  styled,
  theme,
} = createStitches({
  theme: {
    space: Object.fromEntries(
      new Map(
        Object.entries([
          0,
          '0.625rem', // 10px
          '0.75rem', // 12px
          '0.875rem', // 14px
          '1rem', // 16px
          '1.25rem', // 20px
          '1.5rem', // 24px
          '1.875rem', // 30px
          '2rem', // 32px
          '2.25rem', // 36px
          '3rem', // 48px
          '4rem', //64px
          '4.5rem', // 72px
        ]),
      ),
    ),
    fonts: {
      regular: 'pt_root_uiregular',
      light: 'pt_root_uilight',
      medium: 'pt_root_uimedium',
      bold: 'pt_root_uibold',
    },
    fontSizes: Object.fromEntries(
      new Map(
        Object.entries([
          '0.625rem', // 10px
          '0.75rem', // 12px
          '0.875rem', // 14px
          '1rem', // 16px
          '1.25rem', // 20px
          '1.5rem', // 24px
          '1.875rem', // 30px
          '2rem', // 32px
          '2.25rem', // 36px
          '3rem', // 48px
          '4rem', //64px
          '4.5rem', // 72px
        ]),
      ),
    ),
    fontWeights: {
      body: 400,
      heavy: 500,
      bold: 700,
      heading: 400,
    },
    colors: {
      white: '#FFFFFF',
      calmYellow: '#FFCF54',
      pinkyRed: '#FA4651',
      dark: '#0B0B0B',
      gray: '#F2F4F4',
      gray2: '#79838A',
      gray3: '#555D67',
      lightRedOp: '#FF6D7633',
      lightRed: '#FF6D76',
      lightGreenOp: '#C3E57A33',
      lightGreen: '#B5DB62',
      green: '#0CCF71',
      purple: '#6B70F2',
      blue: '#29A4FD',
      yellow: '#F1C605',
    },
    radii: Object.fromEntries(
      new Map(
        Object.entries([0, '2px', '3px', '4px', '8px', '16px', '32px', '64px']),
      ),
    ),
    shadows: {},
    sizes: Object.fromEntries(
      new Map(
        Object.entries(['0.75rem', '1rem', '2rem', '4rem', '8rem', '16rem']),
      ),
    ),
    transitions: { standard: '0.1s ease-in-out' },
    zIndices: {
      dialog: 1000,
      dialogContent: 1001,
    },
  },
  media: {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
  },
  utils: {
    bc: (value: PropertyValue<'backgroundColor'>) => ({
      backgroundColor: value,
    }),
    // Abbreviated margin properties
    m: (value: ScaleValue<'space'> | number) => ({
      marginTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: (value: ScaleValue<'space'> | number) => ({
      marginTop: value,
    }),
    mr: (value: ScaleValue<'space'> | number) => ({
      marginRight: value,
    }),
    mb: (value: ScaleValue<'space'> | number) => ({
      marginBottom: value,
    }),
    ml: (value: ScaleValue<'space'> | number) => ({
      marginLeft: value,
    }),
    mx: (value: ScaleValue<'space'> | number) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: ScaleValue<'space'> | number) => ({
      marginTop: value,
      marginBottom: value,
    }),
    // Abbreviated padding properties
    p: (value: ScaleValue<'space'> | number | string) => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: (value: ScaleValue<'space'> | number) => ({
      paddingTop: value,
    }),
    pr: (value: ScaleValue<'space'> | number) => ({
      paddingRight: value,
    }),
    pb: (value: ScaleValue<'space'> | number) => ({
      paddingBottom: value,
    }),
    pl: (value: ScaleValue<'space'> | number) => ({
      paddingLeft: value,
    }),
    px: (value: ScaleValue<'space'> | number) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: ScaleValue<'space'> | number) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    // A property for applying width/height together
    size: (value: ScaleValue<'space'> | number) => ({
      width: value,
      height: value,
    }),

    // A property to apply linear gradient
    linearGradient: (value: ScaleValue<'space'> | number) => ({
      backgroundImage: `linear-gradient(${value})`,
    }),

    // An abbreviated property for border-radius
    br: (value: ScaleValue<'space'> | number) => ({
      borderRadius: value,
    }),
  },
});
