/* eslint-disable */
// @ts-nocheck

import Box from '../../components/Box';
import Button from '../../components/Button';
import PagesLayout from '../../components/PagesLayout';
import { styled } from '../../stitches.config';
import { useTable } from 'react-table';
import { useEffect, useMemo, useState } from 'react';
import LinkStyled from '../../components/LinkStyled';
import {
  useAuth,
  useAuthRedirect,
  useProfileRedirect,
} from '../../utils/use-auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getTickets as doGetTicket } from '../../store/supportSlice';

const Styles = styled('div', {
  marginTop: '1rem',
  width: '100%',
  marginLeft: '0',

  table: {
    borderSpacing: 0,
    width: '100%',
    tr: {
      ':last-child': {
        td: {},
      },
    },
    th: {
      paddingLeft: '.5rem',
      borderBottom: '1px solid $gray2',
      color: '$gray2',
      textAlign: 'start',
    },
    td: { margin: 0, padding: '.5rem' },
  },
});
const Support = () => {
  useAuthRedirect();
  useProfileRedirect();
  const dispatch = useDispatch<AppDispatch>();
  const support = useSelector((state: RootState) => state.support);
  const { email } = useAuth();
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('All');
  useEffect(() => {
    const getTickets = async () => {
      dispatch(doGetTicket({ email, limit }));
    };

    getTickets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, limit]);

  const showMore = () => {
    setLimit((currLimit) => currLimit + 10);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'N°',
        accessor: 'id',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Details',
        accessor: 'detail',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Status date',
        accessor: 'statusDate',
      },
      {
        Header: '',
        accessor: 'details',
      },
    ],
    [],
  );

  const borderColor = {
    Open: '$calmYellow',
    Reject: '$red',
    Resolve: '$green',
  };
  const data = useMemo(
    () =>
      support.data
        ?.filter((tiketFilter) => {
          if (filter === 'All') {
            return true;
          }

          return tiketFilter.status === filter;
        })
        .map((ticket) => {
          return {
            id: ticket.ticket_count,
            type: ticket.type,
            date: ticket.created_at,
            detail: ticket.details,
            status: (
              <Box
                css={{
                  border: `solid .12rem ${borderColor[ticket.status]}`,
                  width: '4rem',
                  textAlign: 'center',
                  br: '$5',
                }}
              >
                {ticket.status}
              </Box>
            ),
            statusDate: ticket.updated_at,
            details: (
              <LinkStyled
                to={`/ticket/${ticket.ticket_id}`}
                css={{
                  textDecoration: 'underline',
                  textDecorationColor: '$calmYellow',
                  color: '$gray2',
                }}
              >
                details
              </LinkStyled>
            ),
          };
        }) ?? [],
    [support, filter],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <PagesLayout>
      <Box as="section">
        <Box as="h1" css={{ fontSize: '1.5rem' }}>
          Support
        </Box>
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '@md': { flexDirection: 'row', justifyContent: 'space-between' },
          }}
        >
          <Box
            css={{
              bc: '$gray',
              br: '$6',
              width: '21.19rem',
              '> .active': { bc: '$calmYellow', color: '$dark' },
            }}
          >
            <Button
              color="gray"
              css={{ br: '$6', color: '$gray2' }}
              className={filter === 'All' ? 'active' : ''}
              onClick={() => setFilter('All')}
            >
              All tickets
            </Button>
            <Button
              color="gray"
              css={{ br: '$6', color: '$gray2' }}
              className={filter === 'Open' ? 'active' : ''}
              onClick={() => setFilter('Open')}
            >
              Open
            </Button>
            <Button
              color="gray"
              css={{ br: '$6', color: '$gray2' }}
              className={filter === 'Resolve' ? 'active' : ''}
              onClick={() => setFilter('Resolve')}
            >
              Resolve
            </Button>
            <Button
              color="gray"
              css={{ br: '$6', color: '$gray2' }}
              className={filter === 'Reject' ? 'active' : ''}
              onClick={() => setFilter('Reject')}
            >
              Reject
            </Button>
          </Box>
          <Box
            css={{
              display: 'flex',
              marginTop: '.5rem',
              '@sm': { marginTop: '0rem' },
            }}
          >
            <LinkStyled to="/newticket">
              <Button color="blue">New ticket</Button>
            </LinkStyled>
          </Box>
        </Box>
        {support.loading ? (
          <Box
            css={{
              color: '$gray2',
              textAlign: 'center',
              width: '100%',
              padding: '$2',
              fontSize: '$4',
            }}
          >
            Loading...
          </Box>
        ) : (
          <>
            {data.length > 0 ? (
              <Box css={{ width: '100%', overflowY: 'auto', margin: 0 }}>
                <Styles>
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Styles>
              </Box>
            ) : (
              <Box
                css={{
                  color: '$gray2',
                  textAlign: 'center',
                  width: '100%',
                  padding: '$2',
                  fontSize: '$4',
                }}
              >
                There are not tickets.
              </Box>
            )}
            {data.length > 0 && (
              <Box css={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  color={limit > data.length ? 'gray' : 'black'}
                  onClick={showMore}
                  disabled={limit > data.length}
                  css={{
                    width: '100%',
                    margin: '1rem 0rem',
                    '@md': { width: '15rem' },
                  }}
                >
                  Show more
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </PagesLayout>
  );
};

export default Support;
