import axios from 'axios';
import { useState } from 'react';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import { API_BASE, CROSS_ENDPOINTS, SAFE_PASS_REGEX } from '../../constants';
import { axiosAuthInstance } from '../../utils/axios-utils';
import Box from '../../components/Box';
import { useAuthRedirect } from '../../utils/use-auth';
import LoginLayout from '../../components/LoginLayout';
import Card from '../../components/Card';
import Input from '../../components/Input';
import ButtonLink from '../../components/ButtonLink';
import Button from '../../components/Button';

import { addWaitingList } from '../../store/signupSlice';
import { enqueueSnackbar } from '../../store/snackbarSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';

enum CheckTypes {
  UNCHECKED,
  CHECKED,
  NOT_COVERED,
}

export default function Signup() {
  useAuthRedirect(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [createAccountState, setCreateAccountState] = useState({
    email: '',
    password: '',
    repeatPassword: '',
  });

  const [zipCode, setZipCode] = useState('');

  const [zipCodeStatus, setZipCodeStatus] = useState<CheckTypes>(
    CheckTypes.UNCHECKED,
  );

  const createAccount = async () => {
    const { email, password, repeatPassword } = createAccountState;

    if (password !== repeatPassword) {
      dispatch(
        enqueueSnackbar({
          message: 'Both passwords should be the same',
          variant: 'warning',
        }),
      );

      return;
    }

    if (!password.match(SAFE_PASS_REGEX)) {
      dispatch(
        enqueueSnackbar({
          message: 'Password should match security level',
          variant: 'warning',
        }),
      );

      return;
    }

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('password', password);
    formdata.append('account_type', 'retailer');

    const res = await axios.post(
      `${API_BASE}${CROSS_ENDPOINTS.createAccount}`,
      formdata,
    );

    if (res.data.message === 'account_successfully_created') {
      const formdataVerify = new FormData();
      formdataVerify.append('email', email.replaceAll('+', '%2B'));
      axiosAuthInstance
        .post(`${API_BASE}${CROSS_ENDPOINTS.sendCodeEmail}`, formdataVerify)
        .then(() => {
          dispatch(
            enqueueSnackbar({
              message: 'Please check your email to verify your account',
              variant: 'success',
            }),
          );
          navigate('/login');
        });
    }
    if (res.data.message === 'email_already_registered') {
      dispatch(
        enqueueSnackbar({
          message: 'Email already registered',
          variant: 'error',
        }),
      );
    }
  };

  const checkZip = async () => {
    const formdata = new FormData();
    formdata.append('zip', zipCode);

    const res: { data: { message: string | boolean; code: string } } =
      await axios.post(`${API_BASE}${CROSS_ENDPOINTS.isZipAllowed}`, formdata);

    if (res?.data?.message) {
      setZipCodeStatus(CheckTypes.CHECKED);
    } else {
      setZipCodeStatus(CheckTypes.NOT_COVERED);
    }
  };

  const sendWaitingList = () => {
    const { email } = createAccountState;
    dispatch(addWaitingList({ email, zipCode, navigate }));
  };

  return (
    <LoginLayout>
      <Card
        css={{
          width: '70%',
          maxWidth: '25rem',
          display: 'flex',
          flexDirection: 'column',
        }}
        color="gray"
        padd="medium"
      >
        <Box
          as="h1"
          css={{
            alignSelf: 'center',
            margin: '2rem',
            fontSize: '1.3rem',
            fontFamily: '$regular',
          }}
        >
          Sign up to GoFlyy
        </Box>
        <Box as="p" css={{ fontFamily: '$light', textAlign: 'center' }}>
          {zipCodeStatus === CheckTypes.UNCHECKED && (
            <>
              Before creating an account, we must check the availability of work
              in your region using the ZIP.
            </>
          )}
          {zipCodeStatus === CheckTypes.NOT_COVERED && (
            <>
              Unfortunately, we do not work in this region yet and can’t
              register an account with this ZIP. You can leave email and we will
              contact you when it is will be fixed.
            </>
          )}
          {zipCodeStatus === CheckTypes.CHECKED && (
            <>
              Check completed successfully. To continue registration, fill in
              the mail and create a password, as well as agree to the documents
              below.
            </>
          )}
        </Box>
        <Input
          color="white"
          margin="normal"
          disabled={
            zipCodeStatus === CheckTypes.CHECKED ||
            zipCodeStatus === CheckTypes.NOT_COVERED
          }
          css={{
            marginBottom: '1.5rem',
          }}
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          label="  Zip code"
        />
        {(zipCodeStatus === CheckTypes.CHECKED ||
          zipCodeStatus === CheckTypes.NOT_COVERED) && (
          <Input
            color="white"
            margin="normal"
            css={{
              marginBottom: '1.5rem',
            }}
            value={createAccountState.email}
            onChange={(e) =>
              setCreateAccountState((state) => ({
                ...state,
                email: e.target.value,
              }))
            }
            label="  Email address"
          />
        )}
        {zipCodeStatus === CheckTypes.CHECKED && (
          <>
            <Input
              color="white"
              margin="normal"
              css={{
                marginBottom: '1.5rem',
              }}
              value={createAccountState.password}
              onChange={(e) =>
                setCreateAccountState((state) => ({
                  ...state,
                  password: e.target.value,
                }))
              }
              type="password"
              label="  Password"
            />
            <Input
              color="white"
              margin="normal"
              css={{
                marginBottom: '1.5rem',
              }}
              value={createAccountState.repeatPassword}
              onChange={(e) =>
                setCreateAccountState((state) => ({
                  ...state,
                  repeatPassword: e.target.value,
                }))
              }
              type="password"
              label="  Repeat password"
            />
            <Box as="div" css={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                as="p"
                css={{
                  fontFamily: '$light',
                  color: '$gray3',
                  fontSize: '.7rem',
                  textAlign: 'center',
                }}
              >{`One of which must support uppercase, lowercase, numeric or special characters such as ~ ! @ # $ % ^ * - _ = + [ { ] } / ; : , . ? except spaces.`}</Box>
            </Box>
          </>
        )}

        <Box
          as="div"
          css={{
            marginTop: '4rem',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
            justifyContent: 'space-evenly',
          }}
        >
          {zipCodeStatus === CheckTypes.CHECKED && (
            <>
              <Button
                onClick={createAccount}
                color="calmYellow"
                css={{ width: '11.3rem' }}
                disabled={!createAccountState.email}
              >
                Create account
              </Button>
            </>
          )}
          {zipCodeStatus === CheckTypes.UNCHECKED && (
            <>
              <Button
                onClick={checkZip}
                color="calmYellow"
                css={{ width: '11.3rem' }}
              >
                Check
              </Button>
            </>
          )}
          {zipCodeStatus === CheckTypes.NOT_COVERED && (
            <>
              <Button
                onClick={sendWaitingList}
                color="calmYellow"
                css={{ width: '11.3rem' }}
              >
                Send
              </Button>
            </>
          )}
          <ButtonLink href="/login" color="gray">
            Back to sign in
          </ButtonLink>
        </Box>
      </Card>
      {zipCodeStatus === CheckTypes.CHECKED && (
        <>
        
         
        </>
      )}
    </LoginLayout>
  );
}
