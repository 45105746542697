import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '../../components/Box';

const TransactionStatus = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/dashboard', { replace: true });
    }, 3000);
  }, [navigate]);

  return (
    <Box
      as="h1"
      css={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {pathname.includes('Canceled')
        ? 'Your transaction was canceled, will be redirected soon'
        : 'Your transaction was succeeded, will be redirected soon'}
    </Box>
  );
};

export default TransactionStatus;
