import Box from '../../components/Box';
import Card from '../../components/Card';
import Button from '../../components/Button';
import PagesLayout from '../../components/PagesLayout';
import * as Select from '@radix-ui/react-select';
import { styled } from '../../stitches.config';
import LinkStyled from '../../components/LinkStyled';
import { ArrowNarrowRightIcon, RefreshIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { API_BASE, CROSS_ENDPOINTS, RETAILER_ENDPOINTS } from '../../constants';
import {
  useAuth,
  useAuthRedirect,
  useProfileRedirect,
  useDashboardRedirect,
} from '../../utils/use-auth';
import { axiosAuthInstance } from '../../utils/axios-utils';
import { ChevronDownIcon } from '@heroicons/react/solid';
import {
  CopyAllOutlined,
  Twitter,
  Instagram,
  Facebook,
  LinkedIn,
} from '@mui/icons-material';
import Map from '../../components/Map';
import { useNavigate } from 'react-router-dom';
import routeVector from '../../assets/images/routeVector.png';
import { enqueueSnackbar } from '../../store/snackbarSlice';
import { getOrders as doGetOrders } from '../../store/dashboardSlice';
import {
  getApiKey,
  generateApiKey as doGenerateApiKey,
} from '../../store/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import placeHolderImage from '../../assets/images/placeholdeImage.png';
import { usdFormat } from '../../utils/utils';
import Input from '../../components/Input';
import Modal from '../../components/Modal';

import type { Profile } from '../../store/profileSlice';

//import ReactDOM from 'react-dom';
import InnerHTML from 'dangerously-set-html-content'

const Trigger = styled(Select.Trigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '$5',
  padding: '0 1rem',
  height: '2.6rem',
  maxWidth: '8rem',
  border: 'solid .15rem $gray',
  backgroundColor: 'white',
  color: '$dark',
  gap: 10,
  fontSize: 10,
  '&:hover': { color: '$calmYellow' },
});

const Content = styled(Select.Content, {
  overflow: 'hidden',
  borderRadius: '$5',
  backgroundColor: '$white',
  width: '12rem',
  height: 'auto',
  border: 'solid .15rem $gray',
  justifyItems: 'center',
});

const Viewport = styled(Select.Viewport, { padding: '.5rem' });

const Item = styled(Select.Item, {
  all: 'unset',
  color: '$dark',
  display: 'flex',
  alignItems: 'center',
  height: '1.5rem',
  padding: '0 25px 0 25px',
  userSelect: 'none',
  fontSize: '.7rem',
  '&:focus': {
    color: '$calmYellow',
  },
});

const headerCardStyles = {
  width: '90%',
  height: '6rem',
  padding: '1.5rem',
  margin: '.5rem 0',
  '@lg': { width: '27%' },
};

const Dashboard = () => {
  useAuthRedirect();
  //useProfileRedirect();
  //useDashboardRedirect();

  const dispatch = useDispatch<AppDispatch>();
  const dashboard = useSelector((state: RootState) => state.dashboard);

  const { email } = useAuth();
  const [apiKey, setApiKey] = useState('');
  const [limit, setLimit] = useState(10);
  const [amount, setAmount] = useState('');
  const [filter, setFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('30');
  const [profile, setProfile] = useState({
    info_completed: true,
    payment_added: true,
    agreement_signed: true,
    current_balance_usd: null,
  });
  const [countOrder, setCountOrder] = useState({
    count_delivery: 0,
    count_dropoff: 0,
    count_exchange: 0,
    count_pickup: 0,
    count_return: 0,
    count_total: 0,
    status: '',
  });

  const addBalance = async () => {
    if (!email) return;
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('amount', amount);

    axiosAuthInstance
      .post(`${API_BASE}${RETAILER_ENDPOINTS.addBalance}`, formdata)
      .then((res) => {
        if (res.data.data === 'payment_method_not_found') {
          dispatch(
            enqueueSnackbar({
              message: 'Payment method not found',
              variant: 'error',
            }),
          );
        }
        if (res.data.data === 'transaction_declined') {
          dispatch(
            enqueueSnackbar({
              message: 'Transaction declined',
              variant: 'error',
            }),
          );
        }
        if (res.data.data === 'transaction_successfully_completed') {
          dispatch(
            enqueueSnackbar({
              message: 'Transaction successfully completed',
              variant: 'success',
            }),
          );
        }
      });

    //window.open(res.data.session_url);
  };

  const showMore = () => {
    setLimit((currLimit) => currLimit + 10);
  };

  const generateApiKey = async () => {
    if (!email) return;

    const res: any = await dispatch(doGenerateApiKey({ email }));

    setApiKey(res.apikey);
  };

  useEffect(() => {
    const getKey = async () => {
      const res: any = await dispatch(getApiKey({ email }));
      setApiKey(res.apikey);
    };

    getKey();
  }, [email, dispatch]);

  useEffect(() => {
    const getOrders = async () => {
      dispatch(doGetOrders({ email, limit, dateFilter, filter, apiKey }));
    };

    getOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, limit, filter, dateFilter]);

  useEffect(() => {
    const getProfile = async () => {
      if (!email) return;

      const formdata = new FormData();
      formdata.append('email', email);
      const res = await axiosAuthInstance.post(
        `${API_BASE}${RETAILER_ENDPOINTS.getProfile}`,
        formdata,
      );

      setProfile(res.data);
    };

    const getCountAllOrders = async () => {
      if (!email) return;

      const formdata = new FormData();
      formdata.append('email', email);
      const res = await axiosAuthInstance.post(
        `${API_BASE}${CROSS_ENDPOINTS.getCountAllOrders}`,
        formdata,
      );

      setCountOrder(res.data);
    };

    getProfile();
    getCountAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copy = () => {
    navigator.clipboard.writeText(apiKey).then(() => {
      dispatch(
        enqueueSnackbar({
          message: 'API key copied!',
          variant: 'success',
        }),
      );
    });
  };

  //AVOCHATO OLD
  //const avochato_tag = '<script type="text/javascript">!function(e,s){function a(o){function e(e){switch(e.data.type){case"sizeUpdate":o.style.height=e.data.height,o.style.width=e.data.width,o.style.bottom=e.data.bottom,o.style.right=e.data.right;break;case"fetchSession":var t;try{t=JSON.parse(s.localStorage.getItem(n))}catch(a){t={}}t.type="fetchSession",t.accessedAt=(new Date).getTime(),o.contentWindow.postMessage(t,o.src);break;case"saveSession":s.localStorage.setItem(n,JSON.stringify(e.data))}}var n="avochato-widget-7D9JaYQBmP";return e}function t(){if(!e.getElementById("avochato-text-us")&&n===s.location.href){s._avo_handle_message_func&&s.removeEventListener("message",s._avo_handle_message_func);var t=e.createElement("iframe");t.id="avochato-text-us",t.src="https://www.avochato.com/live_chat/7D9JaYQBmP?iframe_domain="+encodeURIComponent(s.location.href),t.setAttribute("style","position:fixed;bottom:2%;right:2%;border:none;height:100px;width:130px;z-index: 999999;"),s._avo_handle_message_func=a(t),s.addEventListener("message",s._avo_handle_message_func),s.Avochato=s.Avochato||{},s.Avochato.widgets=s.Avochato.widgets||{},s.Avochato.widgets["7D9JaYQBmP"]={hide:function(){t.style.setProperty("display","none")},openWithOption:function(e){t.style.setProperty("display",null),t.contentWindow.postMessage({type:"openWithOption",value:e},t.src)}},e.body.appendChild(t)}}function o(){i||(i=!0,t())}var n=s.location.href,i=!1;e.addEventListener&&e.addEventListener("DOMContentLoaded",o,!1),/loaded|interactive|complete/.test(e.readyState)&&o(),s.addEventListener("load",o)}(document,window);</script>';

  //AVOCHATO NEW
  const avochato_tag = '<script type="text/javascript">!function(e,s){function a(o){function e(e){switch(e.data.type){case"sizeUpdate":o.style.height=e.data.height,o.style.width=e.data.width,o.style.bottom=e.data.bottom,o.style.right=e.data.right;break;case"fetchSession":var t;try{t=JSON.parse(s.localStorage.getItem(n))}catch(a){t={}}t.type="fetchSession",t.accessedAt=(new Date).getTime(),o.contentWindow.postMessage(t,o.src);break;case"saveSession":s.localStorage.setItem(n,JSON.stringify(e.data))}}var n="avochato-widget-Q6EyZyrB2D";return e}function t(){if(!e.getElementById("avochato-text-us")&&n===s.location.href){s._avo_handle_message_func&&s.removeEventListener("message",s._avo_handle_message_func);var t=e.createElement("iframe");t.id="avochato-text-us",t.src="https://www.avochato.com/text_us/Q6EyZyrB2D?iframe_domain="+encodeURIComponent(s.location.href),t.setAttribute("style","position:fixed;bottom:2%;right:2%;border:none;height:100px;width:130px;z-index: 999999;"),s._avo_handle_message_func=a(t),s.addEventListener("message",s._avo_handle_message_func),s.Avochato=s.Avochato||{},s.Avochato.widgets=s.Avochato.widgets||{},s.Avochato.widgets.Q6EyZyrB2D={hide:function(){t.style.setProperty("display","none")},openWithOption:function(e){t.style.setProperty("display",null),t.contentWindow.postMessage({type:"openWithOption",value:e},t.src)}},e.body.appendChild(t)}}function o(){i||(i=!0,t())}var n=s.location.href,i=!1;e.addEventListener&&e.addEventListener("DOMContentLoaded",o,!1),/loaded|interactive|complete/.test(e.readyState)&&o(),s.addEventListener("load",o)}(document,window);</script>'

  //REDERICT IF PROFILE IS NOT FULL COMPLETED 
  //const navigate = useNavigate();
  if (profile.info_completed !== true || profile.payment_added !== true || profile.agreement_signed !== true) {
    //location.reload();
    //navigate('/profile');
    window.location.replace("/profile");
    //window.location.href = "/profile";
  }



  return (
    <PagesLayout>
      <Box as="section" css={{ marginBottom: '1rem' }}>
        <Box
          as="div"
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            '@lg': { flexDirection: 'row', justifyContent: 'space-between' },
          }}
        >

          { /*
          <Card color="gray" css={headerCardStyles}>
            <Box
              as="div"
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box as="span" css={{ fontSize: '$5', color: '$gray2' }}>
                Balance
              </Box>
              <LinkStyled to="/billingHistory">
                <Button
                  color="gray"
                  css={{
                    border: 'solid .15rem $gray2',
                    width: '10rem',
                    fontSize: '.89rem',
                  }}
                >
                  Billing history
                </Button>
              </LinkStyled>
            </Box>
            <Box
              as="div"
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                marginTop: '.5rem',
              }}
            >
              <Box as="span" css={{ textAlign: 'center', fontSize: '2rem' }}>
                {usdFormat(profile.current_balance_usd || 0)}
              </Box>
              <Modal
                title="Add Money"
                okText="add balance"
                onOk={addBalance}
                cssTrigger={{
                  bc: '$blue',
                  height: '3rem',
                  width: '8rem',
                  color: 'white',
                }}
                content={
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                    }}
                  >
                    <Input
                      color="white"
                      label="Amount"
                      usd
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setAmount(value);
                      }}
                    />
                  </Box>
                }
              >
                Add Money
              </Modal>
            </Box>
          </Card>
          */
          }

          <Card
            color="white"
            css={{
              ...headerCardStyles,
              padding: 0,
              height: '9rem',
              width: '100%',
              '@lg': { width: '52%' },
            }}
          >
            <Map />
          </Card>
          <Card
            color="white"
            css={{
              ...headerCardStyles,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              '@lg': { width: '40%' },
            }}
            
          >
            <Box
              as="div"
              css={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box as="span" css={{ fontSize: '$5' }}>
                API Key
              </Box>
              <Box
                as="span"
                title={apiKey}
                css={{
                  fontSize: '$4',
                  color: '$yellow',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                ••••••{apiKey?.slice(15, 22) ?? ' - '}
                <Button
                  css={{
                    color: '$gray2',
                    border: 'none',
                    bc: '$white',
                    padding: '1px',
                  }}
                  onClick={copy}
                >
                  <CopyAllOutlined />
                </Button>
              </Box>
            </Box>
            <Box
              as="div"
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                as="p"
                css={{ fontSize: '$2', maxWidth: '10rem', color: '$gray2' }}
              >
                Generate new API key<br></br>
                <a href="http://api.goflyy.com" target="blank">API Documentation</a>
              </Box>
              
              <Button
                color="white"
                onClick={generateApiKey}
                css={{ color: '$gray3' }}
              >
                <RefreshIcon style={{ height: '20px' }} />
              </Button>
            </Box>
          </Card>
        </Box>
        <Box
          css={{
            marginBottom: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '@md': { flexDirection: 'row', justifyContent: 'space-between' },
          }}
        >
          <Box
            css={{
              bc: '$gray',
              br: '$6',
              display: 'flex',
              '> .active': {
                bc: '$calmYellow',
                color: '$dark',
                '&:hover': { color: '$dark' },
              },
            }}
          >
            <Button
              className={filter === '' ? 'active' : ''}
              color="gray"
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                br: '$6',
                color: '$gray2',
                height: '2rem',
                width: '4rem',
                fontSize: '.8rem',
                padding: '.2rem',
                '@sm': {
                  width: '6rem',
                  height: '3rem',
                  fontSize: '1rem',
                  padding: '.5rem',
                },
                '&:hover': { color: '$calmYellow' },
              }}
              onClick={() => {
                setFilter('');
                setLimit(10);
              }}
            >
              All
              <Box
                as="span"
                css={{
                  fontSize: '$1',
                  alignSelf: 'start',
                  marginLeft: '.3rem',
                  textAlign: 'initial',
                }}
              >
                {countOrder.count_total}
              </Box>
            </Button>
            <Button
              className={filter === 'delivery' ? 'active' : ''}
              color="gray"
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                br: '$6',
                color: '$gray2',
                height: '2rem',
                width: '4rem',
                fontSize: '.8rem',
                padding: '.2rem',
                '@sm': {
                  width: '6rem',
                  height: '3rem',
                  fontSize: '1rem',
                  padding: '.5rem',
                },
                '&:hover': { color: '$calmYellow' },
              }}
              onClick={() => {
                setFilter('delivery');
                setLimit(10);
              }}
            >
              Delivery
              <Box
                as="span"
                css={{
                  fontSize: '$1',
                  alignSelf: 'start',
                  marginLeft: '.3rem',
                  textAlign: 'initial',
                }}
              >
                {countOrder.count_delivery}
              </Box>
            </Button>
            <Button
              className={filter === 'return' ? 'active' : ''}
              color="gray"
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                br: '$6',
                color: '$gray2',
                height: '2rem',
                width: '4rem',
                fontSize: '.8rem',
                padding: '.2rem',
                '@sm': {
                  width: '6rem',
                  height: '3rem',
                  fontSize: '1rem',
                  padding: '.5rem',
                },
                '&:hover': { color: '$calmYellow' },
              }}
              onClick={() => {
                setFilter('return');
                setLimit(10);
              }}
            >
              Return
              <Box
                as="span"
                css={{
                  fontSize: '$1',
                  alignSelf: 'start',
                  marginLeft: '.3rem',
                  textAlign: 'initial',
                }}
              >
                {countOrder.count_return}
              </Box>
            </Button>
            <Button
              className={filter === 'exchange' ? 'active' : ''}
              color="gray"
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                br: '$6',
                color: '$gray2',
                height: '2rem',
                width: '4rem',
                fontSize: '.8rem',
                padding: '.2rem',
                '@sm': {
                  width: '6rem',
                  height: '3rem',
                  fontSize: '1rem',
                  padding: '.5rem',
                },
                '&:hover': { color: '$calmYellow' },
              }}
              onClick={() => {
                setFilter('exchange');
                setLimit(10);
              }}
            >
              Tryon
              <Box
                as="span"
                css={{
                  fontSize: '$1',
                  alignSelf: 'start',
                  marginLeft: '.3rem',
                  textAlign: 'initial',
                }}
              >
                {countOrder.count_exchange}
              </Box>
            </Button>
          </Box>
          <Box
            css={{
              display: 'flex',
              margin: '1rem',
              order: '-1',
              '@md': { order: '1', margin: 'unset' },
            }}
          >
            <Box css={{ marginRight: '.5rem' }}>
              <Select.Root value={dateFilter} onValueChange={setDateFilter}>
                <Trigger>
                  <Select.Value />
                  <Select.Icon style={{ paddingTop: '.5rem' }}>
                    <ChevronDownIcon style={{ height: '22px' }} />
                  </Select.Icon>
                </Trigger>
                <Content>
                  <Select.ScrollUpButton></Select.ScrollUpButton>
                  <Viewport>
                    <Item value="1">
                      <Select.ItemText>Last 24 hours</Select.ItemText>
                    </Item>
                    <Item value="7">
                      <Select.ItemText>Last week</Select.ItemText>
                    </Item>
                    <Item value="30">
                      <Select.ItemText>Last month</Select.ItemText>
                    </Item>
                  </Viewport>
                </Content>
              </Select.Root>
            </Box>
            <LinkStyled to="/neworder">
              <Button
                color="blue"
                css={{ fontSize: '.5rem', '@sm': { fontSize: '1rem'} }}
              >
                Create order
              </Button>
            </LinkStyled>

          </Box>
        </Box>
        <Box className="history" css={{ marginBottom: '1rem' }}>
          {dashboard.loading ? (
            <Box
              css={{
                color: '$gray2',
                textAlign: 'center',
                width: '100%',
                padding: '$2',
                fontSize: '$4',
              }}
            >
              Loading...
            </Box>
          ) : (
            <>
              {dashboard.data.length > 0 ? (
                dashboard.data.map((order, index) => {
                  return (
                    <Modal
                      css={{
                        width: '80%',
                        maxHeight: '80%',
                        '@sm': {
                          padding: '2rem',
                        },
                        '@md': {
                          width: '70%',
                          padding: '3rem',
                        },
                        '@lg': {
                          width: '50%',
                        },
                        '&:focus': { outline: 'none' },
                      }}
                      key={order.order_id}
                      cssTrigger={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '$white',
                        border: 'solid 1px transparent',
                        padding: '.5rem',
                        '&:hover': {
                          border: 'solid 1px $calmYellow',
                          borderRadius: '$5',
                        },
                      }}
                      title={
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '.5rem',
                          }}
                        >
                          <Box
                            as="span"
                            css={{
                              fontSize: '2rem',
                              textTransform: 'capitalize',
                            }}
                          >
                            {order.order_type === 'exchange'
                              ? 'tryon'
                              : order.order_type}
                            <Box
                              as="span"
                              css={{
                                fontSize: '2rem',
                                color: '$gray2',
                                marginLeft: '.5rem',
                              }}
                            >
                              &#183; {order.order_id}
                            </Box>
                          </Box>
                          <Box
                            css={{
                              bc: '$calmYellow',
                              height: '1.5rem',
                              br: '$4',
                              minWidth: '2rem',
                              textAlign: 'center',
                              p: '0.3rem',
                            }}
                          >
                            {usdFormat(order.retailer_cost ?? 0)}
                          </Box>
                        </Box>
                      }
                      content={
                        <Box
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            css={{
                              display: 'grid',
                              gridTemplateColumns: '15% auto 20%',
                              gridTemplateRows: 'auto',
                              alignItems: 'center',
                              rowGap: '0.5rem',
                              borderBottom: 'solid 1px $calmYellow',
                              height: '100%',
                              margin: '1rem 0px',
                              '@sm': {
                                minHeight: '30rem',
                              },
                            }}
                          >
                            <Box
                              as="span"
                              css={{ color: '$gray2', fontSize: '$3' }}
                            >
                              Order
                            </Box>
                            <Box
                              css={{
                                display: 'flex',
                                gridColumnStart: '2',
                                gridColumnEnd: '4',
                                color: '$gray2',
                              }}
                            >
                              <Box
                                css={{
                                  bc: '#D3D3D3',
                                  br: '$4',
                                  padding: '3px .5rem ',
                                  color: '$white',
                                }}
                              >
                                {order.order_status}
                              </Box>
                              {order.order_completed_at}
                            </Box>
                            <Box
                              as="span"
                              css={{ color: '$gray2', fontSize: '$3' }}
                            >
                              Route
                            </Box>
                            <Box>
                              {order.approx_completion_time} {' '}
                              {order.total_miles}
                            </Box>
                            <Box
                              css={{
                                width: '5rem',
                                height: '3rem',
                                bc: '$gray',
                                br: '$5',
                                color: '$pinkyRed',
                                textAlign: 'center',
                                padding: '.9rem .1rem .4rem .1rem',
                                justifySelf: 'flex-end',
                              }}
                            >
                              <img src={routeVector} />
                            </Box>
                            <Box
                              as="span"
                              css={{ color: '$gray2', fontSize: '$3' }}
                            >
                              Pickup
                            </Box>
                            <Box
                              css={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box as="p">
                                {order.pickup_address1} {order.pickup_city},{' '}
                                {order.pickup_state} {order.pickup_zip}
                              </Box>
                              <Box as="p" css={{ color: '$gray2' }}>
                                {order.order_picked_at}
                              </Box>
                              <Box as="p" css={{ color: '$gray2' }}>
                                {order.pickup_location_details}
                              </Box>
                            </Box>
                            <Box
                              as="img"
                              src={order.picked_photo || placeHolderImage}
                              css={{
                                width: '5rem',
                                height: '5rem',
                                bc: '$gray',
                                br: '$5',
                                justifySelf: 'flex-end',
                              }}
                            />
                            <Box
                              as="span"
                              css={{ color: '$gray2', fontSize: '$3' }}
                            >
                              Destination
                            </Box>
                            <Box
                              css={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box as="p">
                                {order.dropoff_address1} {order.dropoff_city},{' '}
                                {order.dropoff_state} {order.dropoff_zip}
                              </Box>
                              <Box as="p" css={{ color: '$gray2' }}>
                                {order.order_delivered_at}
                              </Box>
                              <Box as="p" css={{ color: '$gray2' }}>
                                {order.dropoff_location_details}
                              </Box>
                            </Box>
                            <Box
                              as="img"
                              src={order.delivered_photo || placeHolderImage}
                              css={{
                                width: '5rem',
                                height: '5rem',
                                bc: '$gray',
                                br: '$5',
                                justifySelf: 'flex-end',
                                objectFit: 'cover',
                              }}
                            />
                            <Box
                              as="span"
                              css={{ color: '$gray2', fontSize: '$3' }}
                            >
                              Shopper
                            </Box>
                            <Box>
                              {order.shopper_first_name}{' '}
                              {order.shopper_last_name} • {order.shopper_phone}{' '}
                            </Box>
                            <Box
                              css={{
                                justifySelf: 'flex-end',
                                display: 'flex',
                                gap: '1rem',
                                width: '5rem',
                                justifyContent: 'space-between',
                              }}
                            >
                              {order.shopper_social_in ? (
                                <Instagram
                                  style={{
                                    height: '35px',
                                    width: '35px',
                                    color: '#29A4FD',
                                  }}
                                  onClick={() => {
                                    if (!order.shopper_social_in) {
                                      return;
                                    }
                                    window.open(order.shopper_social_in);
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              {order.shopper_social_tw ? (
                                <Twitter
                                  style={{
                                    height: '35px',
                                    width: '35px',
                                    color: '#29A4FD',
                                  }}
                                  onClick={() => {
                                    if (!order.shopper_social_tw) {
                                      return;
                                    }
                                    window.open(order.shopper_social_tw);
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              {order.shopper_social_fb ? (
                                <Facebook
                                  style={{
                                    height: '35px',
                                    width: '35px',
                                    color: '#29A4FD',
                                  }}
                                  onClick={() => {
                                    if (!order.shopper_social_fb) {
                                      return;
                                    }
                                    window.open(order.shopper_social_fb);
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              {order.shopper_social_ln ? (
                                <LinkedIn
                                  style={{
                                    height: '35px',
                                    width: '35px',
                                    color: '#29A4FD',
                                  }}
                                  onClick={() => {
                                    if (!order.shopper_social_ln) {
                                      return;
                                    }
                                    window.open(order.shopper_social_ln);
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box
                              as="span"
                              css={{ color: '$gray2', fontSize: '$3' }}
                            >
                              Details
                            </Box>
                            <Box
                              css={{
                                display: 'flex',
                                gridColumnStart: '2',
                                gridColumnEnd: '4',
                              }}
                            >
                              {order.order_details}
                            </Box>
                          </Box>
                          {order.list_of_products.map((product) => {
                            return (
                              <Box
                                css={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  margin: '.5rem 0rem',
                                }}
                                key={product.product_id}
                              >
                                <Box css={{ display: 'flex' }}>
                                  <Box
                                    as="img"
                                    src={
                                      product.picture_url || placeHolderImage
                                    }
                                    css={{
                                      width: '5rem',
                                      height: '5rem',
                                      bc: '$gray2',
                                      br: '$5',
                                      objectFit: 'cover',
                                      marginRight: '1rem',
                                      '@sm': { width: '7rem', height: '6rem' },
                                      '@md': { width: '9rem', height: '7rem' },
                                    }}
                                  />
                                  <Box
                                    css={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      height: '3rem',
                                    }}
                                  >
                                    <Box as="p">{product.product_name}</Box>
                                    <Box
                                      as="p"
                                      css={{
                                        color: '$gray2',
                                        fontSize: '.8rem',
                                      }}
                                    >
                                      {product.product_description}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  css={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifySelf: 'flex-end',
                                  }}
                                >
                                  <Box
                                    css={{
                                      display: 'flex',
                                      gap: '12px',
                                      justifyContent: 'flex-end',
                                      marginBottom: '.5rem',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Box
                                      as="span"
                                      css={{
                                        border: 'solid 1px $green',
                                        br: '$5',
                                        fontSize: '$1',
                                        textAlign: 'center',
                                        padding: '.2rem .7rem',
                                        fontFamily: '$light',
                                      }}
                                    >
                                      {product.product_status}
                                    </Box>
                                    <Box as="span" css={{ fontSize: '' }}>
                                      {usdFormat(product.product_price)}
                                    </Box>
                                  </Box>
                                  <Box
                                    css={{
                                      display: 'flex',
                                      bc: '$gray',
                                      padding: '.7rem 1rem',
                                      br: '$5',
                                      gap: '.5rem',
                                      '@sm': { gap: '2rem' },
                                    }}
                                  >
                                    <Box
                                      css={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <Box
                                        as="span"
                                        css={{
                                          color: '$gray3',
                                          fontSize: '.7rem',
                                          fontFamily: '$light',
                                        }}
                                      >
                                        Weigth
                                      </Box>
                                      <Box as="span">
                                        {product.product_weight}
                                      </Box>
                                    </Box>
                                    <Box
                                      css={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <Box
                                        as="span"
                                        css={{
                                          color: '$gray3',
                                          fontSize: '.7rem',
                                          fontFamily: '$light',
                                        }}
                                      >
                                        Dimensions
                                      </Box>
                                      <Box
                                        as="span"
                                        css={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          fontSize: '',
                                        }}
                                      >
                                        {product.product_dimentions_height}
                                        <Box
                                          css={{
                                            color: '$gray2',
                                            margin: '0rem .5rem',
                                          }}
                                        >
                                          •
                                        </Box>
                                        {product.product_dimentions_width}
                                        <Box
                                          css={{
                                            color: '$gray2',
                                            margin: '0rem .5rem',
                                          }}
                                        >
                                          •
                                        </Box>
                                        {product.product_dimentions_length}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      }
                    >
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%',
                          '@lg': {
                            flexDirection: 'row',
                          },
                        }}
                        className="deliveryDetails"
                      >
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            borderBottom: 'dashed .15rem $gray ',
                            justifyContent: 'space-between',
                            height: '100%',
                            width: '100%',
                            gap: '.5rem',
                            '@lg': {
                              width: '35rem',
                              borderRight: 'dashed .15rem $gray ',
                              borderBottom: 'none',
                              paddingRight: '1rem',
                            },
                          }}
                        >
                          <Box
                            css={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              height: '4rem',
                            }}
                          >
                            <Box
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '.5rem',
                              }}
                            >
                              <Box
                                as="span"
                                css={{
                                  bc: '#D3D3D3',
                                  fontSize: '.7rem',
                                  textAlign: 'center',
                                  padding: '.2rem',
                                  br: '$4',
                                  color: '$white',
                                }}
                              >
                                {order.order_status}
                              </Box>
                              <Box
                                as="p"
                                css={{
                                  fontSize: '.7rem',
                                  fontFamily: '$bold',
                                }}
                              >
                                
                                {order.order_created_at}
                              </Box>
                            </Box>
                            <Box as="p" css={{ textAlign: 'left' }}>
                              {order.list_of_products[0]?.product_name}
                              {order.list_of_products.length > 1 && (
                                <LinkStyled
                                  to=""
                                  css={{
                                    color: '$gray2',
                                    textDecoration: 'underline',
                                    textDecorationColor: '$calmYellow ',
                                    marginLeft: '4px',
                                  }}
                                >
                                  and {order.list_of_products.length - 1}{' '}
                                  item(s)
                                </LinkStyled>
                              )}
                            </Box>
                          </Box>
                          <Box
                            css={{
                              width: '4rem',
                              height: '2.5rem',
                              bc: '$gray',
                              br: '$5',
                              color: '$pinkyRed',
                              textAlign: 'center',
                              padding: '.9rem .1rem .4rem .1rem',
                            }}
                          >
                            <img src={routeVector} />
                          </Box>
                          <Box
                            css={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              height: '2.5rem',
                            }}
                          >
                            <Box as="span" css={{ fontSize: '.8rem' }}>
                              {order.approx_completion_time}
                            </Box>
                            <Box as="span" css={{ fontSize: '.8rem' }}>
                              {order.total_miles}
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: '.8rem',
                            '@sm': {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                          }}
                        >
                          <Box
                            css={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                              '@sm': {
                                minWidth: '60%',
                              },
                            }}
                          >
                            <Box
                              css={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                marginLeft: '1rem',
                                '@md': { width: '12rem' },
                                '@lg': { width: '13rem' },
                              }}
                            >
                              <Box
                                as="span"
                                css={{ fontSize: '.7rem', color: '$gray2' }}
                              >
                                Pickup
                              </Box>
                              <Box
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '.5rem',
                                }}
                              >
                                <Box
                                  as="img"
                                  src={order.picked_photo || placeHolderImage}
                                  css={{
                                    width: '5rem',
                                    height: '4rem',
                                    color: '$white',
                                    bc: '$dark',
                                    br: '$5',
                                    textAlign: 'center',
                                    objectFit: 'cover',
                                  }}
                                />
                                <Box as="p" css={{ fontSize: '.8rem' }}>
                                  {order.pickup_address1} <br />{' '}
                                  {order.pickup_city}, {order.pickup_state}{' '}
                                  {order.pickup_zip}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              css={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                margin: '0rem 1rem',
                              }}
                            >
                              <Box css={{ color: '$calmYellow' }}>
                                <ArrowNarrowRightIcon
                                  style={{ height: '20px' }}
                                />
                              </Box>
                              <Box
                                css={{
                                  minWidth: '2.5rem',
                                  bc: '$calmYellow',
                                  br: '$4',
                                  textAlign: 'center',
                                  p: '0.3rem',
                                  fontSize: '.8rem',
                                }}
                              >
                                {usdFormat(order.retailer_cost ?? 0)}
                              </Box>
                            </Box>
                            <Box
                              css={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                '@md': { width: '12rem' },
                                '@lg': { width: '13rem' },
                              }}
                            >
                              <Box
                                as="span"
                                css={{
                                  fontSize: '.7rem',
                                  color: '$gray2',
                                  alingSelf: 'start',
                                }}
                              >
                                Destination
                              </Box>
                              <Box
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '.5rem',
                                }}
                              >
                                <Box
                                  as="img"
                                  src={
                                    order.delivered_photo || placeHolderImage
                                  }
                                  css={{
                                    width: '5rem',
                                    height: '4rem',
                                    color: '$white',
                                    bc: '$dark',
                                    br: '$5',
                                    textAlign: 'center',
                                    objectFit: 'cover',
                                  }}
                                />
                                <Box as="p" css={{ fontSize: '.8rem' }}>
                                  {order.dropoff_address1} <br />{' '}
                                  {order.dropoff_city}, {order.dropoff_state}{' '}
                                  {order.dropoff_zip}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            css={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '1rem',
                              alignItems: 'start',
                              marginTop: '1rem',
                              minWidth: '10rem',
                              maxWidth: '20rem',
                              '@sm': { marginTop: '0rem' },
                            }}
                          >
                            <Box
                              as="span"
                              css={{ fontSize: '.7rem', color: '$gray2' }}
                            >
                              Shopper
                            </Box>
                            <Box css={{ display: 'flex' }}>
                              <Box
                                as="p"
                                css={{
                                  fontSize: '.8rem',
                                  textAlign: 'start',
                                }}
                              >
                                {order.shopper_first_name}{' '}
                                {order.shopper_last_name} <br />
                                {order.shopper_phone}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Modal>
                  );
                })
              ) : (
                <Box
                  css={{
                    color: '$gray2',
                    textAlign: 'center',
                    width: '100%',
                    padding: '$2',
                    fontSize: '$4',
                  }}
                >
                  There are not {filter} orders.
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {dashboard.data.length > 0 && (
        <Box css={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            color={limit > dashboard.data.length ? 'gray' : 'black'}
            disabled={limit > dashboard.data.length}
            onClick={showMore}
            css={{
              width: '100%',
              margin: '1rem 0rem',
              '@md': { width: '15rem' },
            }}
          >
            Show more
          </Button>
        </Box>
      )}

      <InnerHTML html={avochato_tag} />

    </PagesLayout>
  );
};

export default Dashboard;
