/* eslint-disable */
// @ts-nocheck

import { ArrowLeftIcon } from '@heroicons/react/outline';
import Box from '../../components/Box';
import Button from '../../components/Button';
import Card from '../../components/Card';
import LinkStyled from '../../components/LinkStyled';
import PagesLayout from '../../components/PagesLayout';
import { useTable } from 'react-table';
import { useEffect, useMemo, useState } from 'react';
import { styled } from '../../stitches.config';
import { useAuth, useAuthRedirect } from '../../utils/use-auth';
import { axiosAuthInstance } from '../../utils/axios-utils';
import { API_BASE, CROSS_ENDPOINTS, RETAILER_ENDPOINTS } from '../../constants';
import { usdFormat } from '../../utils/utils';
import Modal from '../../components/Modal';

const Styles = styled('div', {
  marginTop: '1rem',
  width: '100%',
  marginLeft: '0',

  table: {
    borderSpacing: 0,
    width: '100%',

    tr: {
      ':last-child': {
        td: {},
      },
    },
    th: {
      paddingLeft: '.5rem',
      borderBottom: '1px solid $gray2',
      color: '$gray2',
      textAlign: 'start',
    },
    td: { margin: 0, padding: '.5rem' },
  },
});

const BillingHistory = () => {
  useAuthRedirect();
  const { email } = useAuth();
  const [limit, setLimit] = useState(10);
  const [transactions, setTransactions] = useState([]);
  const [transactionsSummary, setTransactionsSummary] = useState({
    cost_30days: null,
    cost_90days: null,
    cost_total: null,
    count_30days: null,
    count_90days: null,
    count_total: null,
    delivery_cost_30days: null,
    delivery_cost_90days: null,
    delivery_cost_total: null,
    delivery_count_30days: null,
    delivery_count_90days: null,
    delivery_count_total: null,
    return_cost_30days: null,
    return_cost_90days: null,
    return_cost_total: null,
    return_count_30days: null,
    return_count_90days: null,
    return_count_total: null,
    status: '',
    tryon_cost_30days: null,
    tryon_cost_90days: null,
    tryon_cost_total: null,
    tryon_count_30days: null,
    tryon_count_90days: null,
    tryon_count_total: null,
  });

  const showMore = () => {
    setLimit((currLimit) => currLimit + 10);
  };

  useEffect(() => {
    const getTransactions = async () => {
      if (!email) return;
      const formdata = new FormData();
      formdata.append('email', email);
      formdata.append('limit', `${limit}`);

      const res = await axiosAuthInstance.post(
        `${API_BASE}${CROSS_ENDPOINTS.getTransactions}`,
        formdata,
      );

      const resSummary = await axiosAuthInstance.post(
        `${API_BASE}${RETAILER_ENDPOINTS.getTransactionSummary}`,
        formdata,
      );

      if (!res.data.message) {
        setTransactions(res.data);
      }
      if (!resSummary.data.message) {
        setTransactionsSummary(resSummary.data);
      }
    };

    getTransactions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const columns = useMemo(
    () => [
      {
        Header: 'N°',
        accessor: 'id',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Method',
        accessor: 'method',
      },
      {
        Header: '',
        accessor: 'details',
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      transactions?.map((transaction, index) => {
        return {
          id: index + 1,
          date: transaction.created_at,
          type: transaction.type,
          price: usdFormat(transaction.amount),
          status: (
            <Box
              css={{
                width: '4rem',
                textAlign: 'center',
                br: '$5',
              }}
            >
              -
            </Box>
          ),
          method: ' - ',
          details: (
            <Modal
              title={
                <Box css={{ fontSize: '1.5rem' }}>
                  Details
                  <Box
                    as="span"
                    css={{
                      color: '$gray2',
                      fontSize: '1.5rem',
                      margin: '0rem .5rem',
                    }}
                  >
                    • Delivery
                  </Box>
                </Box>
              }
              cssTrigger={{
                backgroundColor: '$white',
                textDecoration: 'underline',
                textDecorationColor: '$calmYellow',
                color: '$gray2',
              }}
              css={{
                paddingLeft: '2rem',
                width: '70%',
                '@md': { width: '80%', paddingLeft: '4rem' },
              }}
              content={
                <>
                  <Box
                    css={{
                      display: 'flex',
                      margin: '2rem 0rem',
                      gap: '10%',
                    }}
                  >
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        height: '6.5rem',
                      }}
                    >
                      <Box as="span">Building name</Box>
                      <Box as="span">address</Box>
                      <Box as="span">city,state,country</Box>
                      <Box as="span">zip</Box>
                    </Box>
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        height: '4.9rem',
                      }}
                    >
                      <Box as="span">phone</Box>
                      <Box as="span">email</Box>
                      <Box as="span">website</Box>
                    </Box>
                  </Box>
                  <Box as="span" css={{ fontSize: '.7rem', color: '$gray2' }}>
                    BILLED TO
                  </Box>
                  <Box
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '.5rem 0rem 1rem 0rem',
                      justifyContent: 'space-evenly',
                      height: '8.5rem',
                    }}
                  >
                    <Box as="span">client name</Box>
                    <Box as="span">address</Box>
                    <Box as="span">city,state,country</Box>
                    <Box as="span">zip</Box>
                    <Box as="span">phone</Box>
                  </Box>
                  <Box
                    css={{
                      display: 'flex',
                      gap: '4%',
                      overflowX: 'auto',
                    }}
                  >
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '13rem',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Box
                          as="span"
                          css={{ fontSize: '1.5rem', color: '$gray2' }}
                        >
                          Invoice
                        </Box>
                        <Box
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '1rem 0rem .5rem 0rem',
                          }}
                        >
                          <Box
                            as="span"
                            css={{ fontSize: '.8rem', color: '$gray2' }}
                          >
                            INVOICE NUMBER:
                          </Box>
                          <Box as="span">00000</Box>
                        </Box>
                        <Box
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '.5rem 0rem',
                          }}
                        >
                          <Box
                            as="span"
                            css={{ fontSize: '.8rem', color: '$gray2' }}
                          >
                            DATE OF ISSUE:
                          </Box>
                          <Box as="span">MM/DD/YY</Box>
                        </Box>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '.5rem 0rem',
                        }}
                      >
                        <Box
                          as="span"
                          css={{ fontSize: '.8rem', color: '$gray2' }}
                        >
                          TERMS
                        </Box>
                        <Box
                          as="p"
                          css={{ fontSize: '.7rem', color: '$gray2' }}
                        >
                          Please pay invoce by MM/DD/YY
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '45rem',
                        alignItems: 'end',
                        gap: '.5rem',
                      }}
                    >
                      <Box
                        css={{
                          display: 'flex',
                          bc: '$gray2',
                          padding: '.5rem 1rem',
                          justifyContent: 'space-between',
                          width: '40rem',
                        }}
                      >
                        <Box as="span" css={{ color: '$white' }}>
                          Description
                        </Box>
                        <Box
                          css={{
                            display: 'flex',
                            width: '50%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box as="span" css={{ color: '$white' }}>
                            Unit cost
                          </Box>
                          <Box as="span" css={{ color: '$white' }}>
                            QTY/HR Rate
                          </Box>
                          <Box as="span" css={{ color: '$white' }}>
                            Amount
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        css={{
                          display: 'flex',
                          bc: '$gray',
                          padding: '.5rem 1rem',
                          justifyContent: 'space-between',
                          width: '40rem',
                        }}
                      >
                        <Box as="span">Your item name</Box>
                        <Box
                          css={{
                            display: 'flex',
                            width: '50%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box as="span">$0000</Box>
                          <Box as="span">000</Box>
                          <Box as="span">$0000</Box>
                        </Box>
                      </Box>
                      <Box
                        css={{
                          display: 'grid',
                          gridTemplateColumns: '9rem 7rem',
                          marginRight: '1rem',
                          rowGap: '.5rem',
                        }}
                      >
                        <Box
                          as="span"
                          css={{ fontSize: '.8rem', color: '$gray2' }}
                        >
                          SUBTOTAL
                        </Box>
                        <Box as="span" css={{ justifySelf: 'end' }}>
                          $0000
                        </Box>
                        <Box
                          as="span"
                          css={{ fontSize: '.8rem', color: '$gray2' }}
                        >
                          DISCOUNT
                        </Box>
                        <Box as="span" css={{ justifySelf: 'end' }}>
                          $0000
                        </Box>
                        <Box
                          as="span"
                          css={{ fontSize: '.8rem', color: '$gray2' }}
                        >
                          TAX RATE
                        </Box>
                        <Box as="span" css={{ justifySelf: 'end' }}>
                          0%
                        </Box>
                        <Box
                          as="span"
                          css={{ fontSize: '.8rem', color: '$gray2' }}
                        >
                          TAX
                        </Box>
                        <Box as="span" css={{ justifySelf: 'end' }}>
                          $0000
                        </Box>
                        <Box
                          css={{
                            gridColumnStart: '1',
                            gridColumnEnd: '3',
                            borderBottom: 'solid 1px $gray2',
                          }}
                        ></Box>
                        <Box
                          as="span"
                          css={{
                            fontSize: '.8rem',
                            color: '$gray2',
                            gridColumnStart: '2',
                            gridColumnEnd: '3',
                            justifySelf: 'end',
                          }}
                        >
                          INVOICE TOTAL
                        </Box>
                        <Box
                          as="span"
                          css={{
                            fontSize: '1.2rem',
                            color: '$gray2',
                            gridColumnStart: '2',
                            gridColumnEnd: '3',
                            justifySelf: 'end',
                          }}
                        >
                          $0000
                        </Box>
                        <Box
                          as="span"
                          css={{
                            gridColumnStart: '1',
                            gridColumnEnd: '3',
                            fontSize: '.8rem',
                            color: '$gray2',
                            marginTop: '2rem',
                          }}
                        >
                          BANK ACCOUNT DETAILS
                        </Box>
                        <Box
                          as="p"
                          css={{
                            gridColumnStart: '1',
                            gridColumnEnd: '3',
                            fontSize: '.8rem',
                          }}
                        >
                          Account Holder:
                        </Box>
                        <Box
                          as="p"
                          css={{
                            gridColumnStart: '1',
                            gridColumnEnd: '3',
                            fontSize: '.8rem',
                          }}
                        >
                          Account Number:
                        </Box>
                        <Box
                          as="p"
                          css={{
                            gridColumnStart: '1',
                            gridColumnEnd: '3',
                            fontSize: '.8rem',
                          }}
                        >
                          ABA rtn:
                        </Box>
                        <Box
                          as="p"
                          css={{
                            gridColumnStart: '1',
                            gridColumnEnd: '3',
                            fontSize: '.8rem',
                          }}
                        >
                          Wire rtn:
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              }
            >
              details
            </Modal>
          ),
        };
      }) ?? [],
    [transactions],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <PagesLayout>
      <Box css={{ marginBottom: '2rem' }}>
        <Box
          css={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}
        >
          <Button
            color="white"
            css={{ padding: '.2rem', width: '3rem' }}
            onClick={() => {
              window.history.back();
            }}
          >
            <ArrowLeftIcon style={{ height: '22px' }} />
          </Button>
          <Box as="h1" css={{ fontSize: '1.5rem', marginLeft: '2rem' }}>
            Billing history
          </Box>
        </Box>
        <Box
          css={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            '@lg': { flexDirection: 'row', gap: '3%' },
          }}
        >
          <Box
            css={{
              display: 'flex',
              width: '100%',
              margin: '.5rem 0rem',
              '@lg': { width: '45%', marginBottom: '2rem' },
            }}
          >
            <Card
              color="white"
              css={{
                width: '70%',
                height: '6rem',
                padding: '1rem',
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0',
              }}
            >
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}
                >
                  <Box>Delivery</Box>
                  <Box css={{ display: 'flex', gap: '4px' }}>
                    {transactionsSummary?.delivery_count_30days ?? 0}
                    <Box css={{ color: '$green' }}>•</Box>{' '}
                    {usdFormat(transactionsSummary?.delivery_cost_30days ?? 0)}
                  </Box>
                </Box>
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}
                >
                  <Box>Return</Box>
                  <Box css={{ display: 'flex', gap: '4px' }}>
                    {transactionsSummary?.return_count_30days ?? 0}{' '}
                    <Box css={{ color: '$blue' }}>•</Box>{' '}
                    {usdFormat(transactionsSummary?.return_cost_30days ?? 0)}
                  </Box>
                </Box>
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}
                >
                  <Box>Tryon</Box>
                  <Box css={{ display: 'flex', gap: '4px' }}>
                    {transactionsSummary?.tryon_count_30days ?? 0}{' '}
                    <Box css={{ color: '$purple' }}>•</Box>{' '}
                    {usdFormat(transactionsSummary?.tryon_cost_30days ?? 0)}
                  </Box>
                </Box>
              </Box>
            </Card>
            <Card
              color="gray"
              css={{
                width: '30%',
                height: '6.22rem',
                padding: '1rem',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
              }}
            >
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Box>Last month</Box>
                <Box css={{ display: 'flex' }}>
                  <Box>{transactionsSummary?.count_30days ?? 0}</Box>
                </Box>
                <Box css={{ display: 'flex' }}>
                  <Box>{usdFormat(transactionsSummary?.cost_30days ?? 0)}</Box>
                </Box>
              </Box>
            </Card>
          </Box>
          <Box
            css={{
              display: 'flex',
              width: '100%',
              margin: '.5rem 0rem',
              '@lg': { width: '45%' },
            }}
          >
            <Card
              color="white"
              css={{
                width: '70%',
                height: '6rem',
                padding: '1rem',
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0',
              }}
            >
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}
                >
                  <Box>Delivery</Box>
                  <Box css={{ display: 'flex', gap: '4px' }}>
                    {transactionsSummary?.delivery_count_90days ?? 0}
                    <Box css={{ color: '$green' }}>•</Box>{' '}
                    {usdFormat(transactionsSummary?.delivery_cost_90days ?? 0)}
                  </Box>
                </Box>
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}
                >
                  <Box>Return</Box>
                  <Box css={{ display: 'flex', gap: '4px' }}>
                    {transactionsSummary?.return_count_90days ?? 0}
                    <Box css={{ color: '$blue' }}>•</Box>
                    {usdFormat(transactionsSummary?.return_cost_90days ?? 0)}
                  </Box>
                </Box>
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}
                >
                  <Box>Tryon</Box>
                  <Box css={{ display: 'flex', gap: '4px' }}>
                    {transactionsSummary?.tryon_count_90days ?? 0}{' '}
                    <Box css={{ color: '$purple' }}>•</Box>{' '}
                    {usdFormat(transactionsSummary?.tryon_cost_90days ?? 0)}
                  </Box>
                </Box>
              </Box>
            </Card>
            <Card
              color="gray"
              css={{
                width: '30%',
                height: '6.22rem',
                padding: '1rem',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
              }}
            >
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Box>Total: 3 month</Box>
                <Box css={{ display: 'flex' }}>
                  <Box>{transactionsSummary?.count_90days ?? 0}</Box>
                </Box>
                <Box css={{ display: 'flex' }}>
                  <Box>{usdFormat(transactionsSummary?.cost_90days ?? 0)}</Box>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
        <Box css={{ width: '100%', overflowY: 'auto', marginBottom: '1rem' }}>
          <Styles>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Styles>
        </Box>
        {!transactions.length && (
          <Box
            css={{
              color: '$gray2',
              textAlign: 'center',
              width: '100%',
              padding: '$2',
              fontSize: '$4',
            }}
          >
            There are not transactions.
          </Box>
        )}
        {transactions.length > 0 && (
          <Box css={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color={limit > transactions.length ? 'gray' : 'black'}
              disabled={limit > transactions.length}
              onClick={showMore}
              css={{ width: '100%', '@md': { width: '15rem' } }}
            >
              Show more
            </Button>
          </Box>
        )}
      </Box>
    </PagesLayout>
  );
};

export default BillingHistory;
