import styles from '../src/styles';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../theme/palette';
import { breakPoints } from '../src/breakpoints';
import BGroundRectangle from './BGroundRectangle';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../UI/StyledButton';

const breaks = breakPoints(1450, 684, 517, 20);

export default () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        ...styles.contentBox,
        ...styles.contentBoxLeft,
        alignItems: 'center',
        flexWrap: 'wrap-reverse',
        ['@media (max-width:1500px)']: {
          flexDirection: 'column-reverse',
        },
      }}
      className="Benefits"
    >
      <Box
        sx={{
          ...styles.innerBox,
          minWidth: '200px',
          width: '25%',
          marginRight: '10%',
          ['@media (max-width:1500px)']: {
            width: '30%',
            justifyContent: 'flex-start',
            marginRight: '0px',
            minHeight: '10%',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: '40px',
          },
          ['@media (max-width:900px)']: {
            width: '80%',
            minWidth: '0px',
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontWeight: 'bold', ...styles.resizeH2 }}
        >
          EASY
        </Typography>
        <Typography
          variant="h2"
          sx={{
            ...styles.defaultMargin,
            fontWeight: 'bold',
            color: customColors.purple,
            ...styles.resizeH2,
          }}
        >
          SETUP
        </Typography>
        <Typography
          variant="h3"
          sx={{
            ...styles.defaultMargin,
            width: '390px',
            ...styles.resizeH3,
            ['@media (max-width:900px)']: {
              width: '80%',
            },
          }}
        >
          Get started in days instead of weeks. Our team is here to help you.
        </Typography>
        <StyledButton
          sx={{ height: '65px', minWidth: '170px' }}
          text="Get started"
          href="/signup"
        />
      </Box>
      <CardMedia
        sx={{
          height: '593px',
          width: '784px',
          ['@media (max-width:1700px)']: {
            height: '517px',
            width: '684px',
          },
          ...breaks,
        }}
        image={require('../src/R64.png')}
      />
      <BGroundRectangle {...styles.rectRight} />
    </Box>
  );
};
