import type { ReactNode } from 'react';
import Box from './Box';
import Footer from './Footer';
import Logo from './Logo';

type Props = {
  children: ReactNode;
};

export default function LoginLayout({ children }: Props) {
  return (
    <Box
      as="section"
      css={{
        bc: '$calmYellow',
        minHeight: '100vh',
        width: '100vw',
        maxWidth: '100vw',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        className="layout-container"
        css={{
          alignItems: 'center',
          bc: '$white',
          borderBottomLeftRadius: '$6',
          borderBottomRightRadius: '$6',
          display: 'flex',
          flexDirection: 'column',
          //maxHeight: '95vh',
          //minHeight: '95vh',
          overflowY: 'auto',
          pb: '$1',
          pt: '$1',
          width: '90vw',
          paddingTop:'60px'
        }}
      >
        <a href="/"><Logo css={{ marginBottom: '2rem' }} /></a>
        {children}
        <Footer css={{ marginTop: 'auto', width: '95%' }}>
          <Box as="span" css={{ alignSelf: 'flex-end', fontFamily: '$medium' }}>
            &#169;Goflyy,2022
          </Box>
          <Box as="span" css={{ alignSelf: 'flex-end', fontFamily: '$medium' }}>
            Design — <b>Atwinta</b>
          </Box>
        </Footer>
      </Box>
    </Box>
  );
}
