import styles from '../src/styles';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../theme/palette';
import { breakPoints } from '../src/breakpoints';
import BGroundRectangle from './BGroundRectangle';
import StyledButton from '../../UI/StyledButton';

const breaks = breakPoints(1450, 684, 517, 20);

export default () => (
  <Box sx={{...styles.contentBox, ...styles.contentBoxRight, alignItems: "center", justifyContent: "center", flexWrap: "wrap-reverse",
  ['@media (max-width:1450px)']: {
    flexDirection: "column",
  },}}>
  <BGroundRectangle {...styles.rectLeft} backgroundColor={customColors.lightGreen} />
  <CardMedia sx={{
    height: "593px",
    width: "784px",
    borderRadius: "300px",
    ['@media (max-width:1700px)']: {
      height: "517px",
      width: "684px",
    },
    ...breaks

  }} image={require("../src/R74.jpg")}/>
  <Box sx={{...styles.innerBox, minWidth: "200px", width: "25%", marginLeft :"10%",
    ['@media (max-width:1450px)']: {
      width: "30%",
      justifyContent: "flex-start",
      alignItems: "center",
      textAlign: "center",
      marginLeft: "0px",
      minHeight: "10%",
      marginTop: "40px",
    },
    ['@media (max-width:900px)']: {
      minWidth: "100%",
      width: "100%",
    },}}>
    <Typography variant="h2" sx={{fontWeight: "bold", color: customColors.purple, ...styles.resizeH2}}>
      FLEXIBLE
    </Typography>
    <Typography variant="h2" sx={{...styles.defaultMargin, fontWeight: "bold", ...styles.resizeH2}}>
      AND SCALABLE
    </Typography>
    <Typography variant="h3" sx={{...styles.defaultMargin, width: "320px", ...styles.resizeH3}}>
    Whether you are a small or medium sized business, we want to grow with you.
    </Typography>
    <StyledButton
          sx={{ height: '65px', minWidth: '170px' }}
          text="Get started"
          href="/signup"
        />
  </Box>
</Box>
)