import { useEffect, useCallback, useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const center = { lat: 35.0194, lng: -118.411 };
const zoom = 4;
const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '1.8rem',
};

const mapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAFpUvr6VeIKf--CTji6Y_Cd0odWV3u5DE',
    //'AIzaSyAHLcR6k7xVNyMrUgFCXFOwEj2thAiRFZY'
    
  });
  const dashboard = useSelector((state: RootState) => state.dashboard);

  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState({});

  useEffect(() => {
    if (dashboard.data.length > 1 && !dashboard.loading) {
      // TODO: refresh map on order type change
      setDirectionsResponse({});
      const directions = new window.google.maps.DirectionsService();

      const directionPromises = dashboard.data.map((order, index) => {
        if (index > 9) {
          return;
        }

        return directions.route({
          origin: `${order.pickup_address1} ${order.pickup_city}`,
          destination: `${order.dropoff_address1} ${order.dropoff_city}`,
          provideRouteAlternatives: false,
          travelMode: google.maps.TravelMode.DRIVING,
        });
      });

      Promise.all(directionPromises).then((res) => {
        res.forEach((directionsResolved, i) => {
          setDirectionsResponse((state) => ({
            ...state,
            [i]: directionsResolved,
          }));
        });
      });
    }
  }, [dashboard.data, dashboard.loading]);

  const onLoad = useCallback(function callback(mapR) {
    const bounds = new window.google.maps.LatLngBounds(center);
    mapR.fitBounds(bounds);
    setMap(mapR);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      //onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        styles: mapStyles,
      }}
    >
      {Object.keys(directionsResponse).length && (
        <>
          {Object.values(directionsResponse).map((direction, i) => (
            <DirectionsRenderer key={i} directions={direction as any} />
          ))}
        </>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default Map;
