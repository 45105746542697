import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export const ENQUEUE_SNACKBAR = 'enqueueSnackbar';
export const CLOSE_SNACKBAR = 'closeSnackbar';
export const REMOVE_SNACKBAR = 'removeSnackbar';

const initialState = { notifications: {} };

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    [ENQUEUE_SNACKBAR]: (state, { payload }) => {
      const key = uuidv4();
      state.notifications = {
        ...state.notifications,
        [key]: { ...payload, key },
      };
    },
    [CLOSE_SNACKBAR]: (state) => {
      state.notifications = {};
    },
    [REMOVE_SNACKBAR]: (state, { payload }) => {
      Object.keys(state.notifications)
        .filter((key) => key !== payload)
        .reduce(
          (curr, acc) => ({ ...curr, [acc]: { ...state.notifications[acc] } }),
          {},
        );
      delete state.notifications[payload];
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
  snackbarSlice.actions;

export default snackbarSlice.reducer;
