import { Avatar, Box, Button, Typography } from '@mui/material';
import styles from '../src/styles';
import { useCallback, useMemo, useState } from 'react';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { customColors } from '../../theme/palette';
import typography from '../../theme/typography';

export default ({ tests }: any) => {
  const [index, setIndex] = useState(0);
  const next = useCallback(() => {
    setIndex((prev) => prev + 1);
  }, []);
  const previous = useCallback(() => {
    setIndex((prev) => prev - 1);
  }, []);
  const TestSlider = useMemo(() => {
    let title = ``;
    let message = ``;
    let name = ``;
    let date = ``;
    let picture = ``;
    if (tests[index]) {
      title = tests[index].title;
      message = tests[index].message;
      name = tests[index].name;
      date = tests[index].date;
      picture = tests[index].picture;
    }
    const isNext = !tests[index + 1];
    const isPrev = !tests[index - 1];
    return () => (
      <Box sx={{ ...styles.testsBox }}>
        <Button
          sx={{ ...styles.sliderButtons }}
          onClick={previous}
          disabled={isPrev}
        >
          <NavigateBefore />
        </Button>
        <Box sx={{ ...styles.reviewBox }}>
          <Typography
            sx={{
              ['@media (max-width:1300px)']: {
                fontSize: typography.subtitle2.fontSize,
              },
              fontWeight: 'bold',
            }}
            variant="subtitle1"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              ...styles.defaultMargin,
              height: '60%',
              minHeight: '100px',
              fontWeight: 500,
              ['@media (max-width:1300px)']: {
                fontSize: typography.subtitle1.fontSize,
              },
            }}
            variant="h4"
          >
            {message}
          </Typography>
          <Box sx={{ ...styles.ceoBox }}>
            <Avatar
              style={{ height: '56px', width: '56px', marginRight: '10px' }}
              src={picture}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="h4">{name}</Typography>
              <Typography variant="subtitle2" sx={{ color: customColors.gray }}>
                {date}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Button
          sx={{ ...styles.sliderButtons }}
          onClick={next}
          disabled={isNext}
        >
          <NavigateNext />
        </Button>
      </Box>
    );
  }, [index, next, previous, tests]);

  return (
    <Box
      sx={{
        ...styles.contentBox,
        justifyContent: 'center',
        alignItems: 'center',
        ['@media (max-width:1300px)']: {
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
        },
        ['@media (max-width:800px)']: {
          width: '100%',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        },
      }}
      className="Testimonials"
    >
      <Box
        sx={{
          ...styles.innerBox,
          minHeight: '300px',
          ...styles.sidedContent,
          ['@media (max-width:1300px)']: {
            minHeight: '50px',
            alignItems: 'center',
          },
          ['@media (max-width:800px)']: {
            width: '100%',
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            ...styles.defaultMargin,
            fontWeight: 'bold',
            ...styles.resizeH2,
          }}
        >
          TESTIMONIALS
        </Typography>
        <Typography variant="h3" sx={{ ...styles.resizeH3 }}>
          That's what our
        </Typography>
        <Typography variant="h3" sx={{ ...styles.resizeH3 }}>
          partners say about us
        </Typography>
      </Box>
      <Box
        sx={{
          ...styles.innerBox,
          minHeight: '300px',
          paddingLeft: '10px',
          ['@media (max-width:1300px)']: {
            justifyContent: 'flex-start',
            marginTop: '20px',
            maxWidth: '100%',
            width: '100%',
          },
        }}
      >
        <TestSlider />
      </Box>
    </Box>
  );
};
