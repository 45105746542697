import styles from '../src/styles';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../theme/palette';
import StyledButton from '../../UI/StyledButton';
import { breakPoints } from '../src/breakpoints';
import BGroundRectangle from './BGroundRectangle';

const breaks = breakPoints(1450, 684, 517, 20);

export default () => (
  <Box
  sx={{
    ...styles.contentBox,
    ...styles.contentBoxLeft,
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
    ['@media (max-width:1450px)']: {
      flexDirection: 'column-reverse',
    },
  }}
>
  <Box
    sx={{
      ...styles.innerBox,
      minWidth: '200px',
      width: '25%',
      marginRight: '10%',
      ['@media (max-width:1450px)']: {
        width: '30%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
        marginRight: '0px',
        minHeight: '10%',
        marginTop: '40px',
      },
      ['@media (max-width:900px)']: {
        width: '100%',
        minWidth: '100%',
      },
    }}
  >
    <Typography
      variant="h2"
      sx={{
        fontWeight: 'bold',
        color: customColors.purple,
        ...styles.resizeH2,
      }}
    >
      CONVERT NEW
    </Typography>
    <Typography
      variant="h2"
      sx={{ ...styles.defaultMargin, fontWeight: 'bold', ...styles.resizeH2 }}
    >
      CUSTOMERS
    </Typography>
    <Typography
      variant="h3"
      sx={{ ...styles.defaultMargin, width: '320px', ...styles.resizeH3 }}
    >
      Use at-home try ons to introduce your brand to new customers. 
    </Typography>
    <StyledButton
      sx={{ height: '65px', minWidth: '170px' }}
      text="Learn more"
      href="/signup"
    />
  </Box>
  <CardMedia
    sx={{
      height: '593px',
      width: '784px',
      borderRadius: "300px",
      ['@media (max-width:1700px)']: {
        height: '517px',
        width: '684px',
      },
      ...breaks,
    }}
    image={require('../src/R1new.jpg')}
  />
  <BGroundRectangle
    {...styles.rectRight}
    backgroundColor={customColors.informational}
  />
</Box>
);
