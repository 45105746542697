import { createSlice } from '@reduxjs/toolkit';

import { axiosAuthInstance } from '../utils/axios-utils';
import { API_BASE, RETAILER_ENDPOINTS, CROSS_ENDPOINTS } from '../constants';

import { AppThunk } from '.';
import { enqueueSnackbar } from './snackbarSlice';

export type ProfileData = {
  current_balance_usd: number | null;
  total_completed_orders: number | null;
  retailer_contact_first_name: string;
  retailer_contact_last_name: string;
  retailer_contact_middle_name: string;
  retailer_contact_dob: string;
  retailer_contact_phone: string;
  retailer_contact_country_code: string;
  retailer_contact_sex: string;
  retailer_social_fb: string;
  retailer_social_ln: string;
  retailer_social_in: string;
  retailer_social_tw: string;
  retailer_contact_title: string;
  retailer_contact_notes: string;
  retailer_pickup_or_dropoff_address1: string;
  retailer_pickup_or_dropoff_address2: string;
  retailer_pickup_or_dropoff_city: string;
  retailer_pickup_or_dropoff_state: string;
  retailer_pickup_or_dropoff_zip: string;
  retailer_pickup_or_dropoff_country: string;
  retailer_pickup_or_dropoff_details: string;
  retailer_name: string;
  retailer_description: string;
  retailer_website: string;
  status: string;
  retailer_profile_pic: string;
  integration_shopify_shop: null | string;
  integration_shopify_logo: null | string;
  integration_shopify_created_at: null | string;
  creditcards: null | Record<string, any>[];

  info_completed: boolean;
  payment_added: boolean;
  agreement_signed: boolean;

};

export type Profile = {
  loading: boolean;
  error: boolean;
  data: ProfileData;
};

export const initialState: Profile = {
  loading: false,
  error: false,
  data: {
    current_balance_usd: null,
    total_completed_orders: null,
    retailer_contact_first_name: '',
    retailer_contact_last_name: '',
    retailer_contact_middle_name: '',
    retailer_contact_dob: '',
    retailer_contact_phone: '',
    retailer_contact_country_code: '',
    retailer_contact_sex: '',
    retailer_social_fb: '',
    retailer_social_ln: '',
    retailer_social_in: '',
    retailer_social_tw: '',
    retailer_contact_title: '',
    retailer_contact_notes: '',
    retailer_pickup_or_dropoff_address1: '',
    retailer_pickup_or_dropoff_address2: '',
    retailer_pickup_or_dropoff_city: '',
    retailer_pickup_or_dropoff_state: '',
    retailer_pickup_or_dropoff_zip: '',
    retailer_pickup_or_dropoff_country: '',
    retailer_pickup_or_dropoff_details: '',
    retailer_name: '',
    retailer_description: '',
    retailer_website: '',
    status: '',
    retailer_profile_pic: '',
    integration_shopify_shop: null,
    integration_shopify_logo: null,
    integration_shopify_created_at: null,
    creditcards: null,

    info_completed: null,
    payment_added: null,
    agreement_signed: null,
    
  },
};

export const profleSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setData, setError } = profleSlice.actions;

export const getProfile =
  ({ email }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const formdata = new FormData();
      formdata.append('email', email);
      const res = await axiosAuthInstance.post(
        `${API_BASE}${RETAILER_ENDPOINTS.getProfile}`,
        formdata,
      );
      dispatch(
        setData({
          ...res.data,
          retailer_profile_pic: res.data.retailer_profile_pic
            ? `${res.data.retailer_profile_pic}?dummy=${Date.now()}`
            : null,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(setError(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

{/*
export const getOnboardingStatus = 
  ({ email }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const formdata = new FormData();
      formdata.append('email', email);
      const res = await axiosAuthInstance.post(
        `${API_BASE}${RETAILER_ENDPOINTS.getRetailerOnboardingStatus}`,
        formdata,
      );

      alert(res.data.info_completed)

      dispatch(
        setData({
          ...res.data,
          info_completed: res.data.info_completed,
          payment_added: res.data.payment_added,
          agreement_signed: res.data.agreement_signed,
        }),
      );

      //alert(JSON.stringify(info_completed))

    } catch (e) {
      console.error(e);
      dispatch(setError(false));
    } finally {
      dispatch(setLoading(false));
    }
  };

*/}


export const uploadProfilePicture =
  ({ email, file }): AppThunk =>
  async (dispatch) => {
    if (!email || !file) {
      return;
    }

    const avatar = file.file;

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('data', avatar, avatar.name);

    axiosAuthInstance
      .post(`${API_BASE}${CROSS_ENDPOINTS.uploadProfilePicture}`, formdata)
      .then((res) => {
        if (res.data.message === 'document_successfully_uploaded') {
          dispatch(
            enqueueSnackbar({
              message: 'Document successfully uploaded',
              variant: 'success',
            }),
          );
        }
      });
  };

export const addContact =
  ({
    email,
    retailer_contact_first_name,
    retailer_contact_last_name,
    retailer_contact_title,
    retailer_contact_notes,
  }: {
    [k: string]: any;
  }): AppThunk =>
  (dispatch) => {
    if (!email) return;

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('first_name', retailer_contact_first_name || '');
    formdata.append('last_name', retailer_contact_last_name || '');
    formdata.append('title', retailer_contact_title || '');
    formdata.append('notes', retailer_contact_notes || '');

    axiosAuthInstance
      .post(`${API_BASE}${CROSS_ENDPOINTS.addContact}`, formdata)
      .then((res) => {
        if (res.data.message === 'contact_successfully_added') {
          dispatch(
            enqueueSnackbar({
              message: 'Contact successfully added',
              variant: 'success',
            }),
          );
        }
      });
  };

export const addPhone =
  ({
    email,
    retailer_contact_phone,
    retailer_contact_country_code,
  }: {
    [k: string]: any;
  }): AppThunk =>
  (dispatch) => {
    if (!email) return;

    if (!retailer_contact_phone || !retailer_contact_country_code) {
      return;
    }

    const formdata = new FormData();
    const newPhone = retailer_contact_phone.slice(
      retailer_contact_country_code.length,
    );

    //alert("email " + email)
    //alert ("retailer_contact_country_code " + retailer_contact_country_code)
    //alert ("newPhone " + newPhone)

    formdata.append('email', email);
    formdata.append('country_code', retailer_contact_country_code || '');
    formdata.append('phone', newPhone || '');

    axiosAuthInstance
      .post(`${API_BASE}${CROSS_ENDPOINTS.addPhone}`, formdata)
      .then((res) => {

        //alert(res.data.message)
        if (res.data.message === 'phone_successfully_added') {
          dispatch(
            enqueueSnackbar({
              message: 'Confirmation code sent',
              variant: 'success',
            }),
          );
        }
        if (res.data.message === 'wrong_phone_format') {
          dispatch(
            enqueueSnackbar({
              message: 'Wrong phone format',
              variant: 'error',
            }),
          );
        }
        if (res.data.message === 'phone_already_exist') {
          dispatch(
            enqueueSnackbar({
              message: 'Phone already exist',
              variant: 'error',
            }),
          );
        }
      });
  };

export const addPhoneWithoutVerification =
  ({ email, phone, country_code }: { [k: string]: any }): AppThunk =>
  (dispatch) => {
    if (!email) return;

    if (!phone || !country_code) {
      return;
    }

    const formdata = new FormData();

    formdata.append('email', email);
    formdata.append('country_code', country_code || '');
    formdata.append('phone', phone || '');

    axiosAuthInstance
      .post(
        `${API_BASE}${CROSS_ENDPOINTS.addPhoneWithoutVerification}`,
        formdata,
      )
      .then((res) => {
        if (res.data.message === 'phone_successfully_added') {
          dispatch(
            enqueueSnackbar({
              message: 'Phone successfully added',
              variant: 'success',
            }),
          );
        }
        if (res.data.message === 'wrong_phone_format') {
          dispatch(
            enqueueSnackbar({
              message: 'Wrong phone format',
              variant: 'error',
            }),
          );
        }
        if (res.data.message === 'phone_already_exist') {
          dispatch(
            enqueueSnackbar({
              message: 'Phone already exist',
              variant: 'error',
            }),
          );
        }
      });
  };

export const verifyPhone =
  ({
    email,
    code,
    retailer_contact_phone,
    retailer_contact_country_code,
  }): AppThunk =>
  async (dispatch) => {
    if (!email) return;

    if (!retailer_contact_phone || !retailer_contact_country_code) {
      return;
    }

    const formdata = new FormData();
    const newPhone = retailer_contact_phone.slice(
      retailer_contact_country_code.length,
    );

    formdata.append('email', email);
    formdata.append('country_code', `+${retailer_contact_country_code}` || '');
    formdata.append('phone', newPhone || '');
    formdata.append('code', code || '');

    const res = await axiosAuthInstance.post(
      `${API_BASE}${CROSS_ENDPOINTS.verifyPhone}`,
      formdata,
    );

    if (res.data.message === 'phone_not_exist') {
      dispatch(
        enqueueSnackbar({ message: 'Phone not exist', variant: 'error' }),
      );
    }
    if (res.data.message === 'wrong_code') {
      dispatch(enqueueSnackbar({ message: 'Wrong code', variant: 'error' }));
    }

    if (res.data.message === 'phone_successfully_verified') {
      dispatch(
        enqueueSnackbar({ message: 'Phone verified', variant: 'success' }),
      );

      //WHEN PHONE CONFIRMED REDIRECT TO DASBOARD
      //setTimeout(function() {
      //  window.location.href = "/dashboard";
      //}, 5000); // 5000 milliseconds = 5 seconds
      //window.location.replace("/dashboard");

      
    }
  };

export const addAddress =
  ({
    email,
    retailer_pickup_or_dropoff_address1,
    retailer_pickup_or_dropoff_city,
    retailer_pickup_or_dropoff_state,
    retailer_pickup_or_dropoff_zip,
    retailer_pickup_or_dropoff_country,
    retailer_pickup_or_dropoff_details,
  }: {
    [k: string]: any;
  }): AppThunk =>
  (dispatch) => {
    if (!email) return;

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('address1', retailer_pickup_or_dropoff_address1 || '');
    formdata.append('city', retailer_pickup_or_dropoff_city || '');
    formdata.append('country', retailer_pickup_or_dropoff_country || '');
    formdata.append('state', retailer_pickup_or_dropoff_state || '');
    formdata.append('zip', retailer_pickup_or_dropoff_zip || '');
    formdata.append('details', retailer_pickup_or_dropoff_details || '');

    axiosAuthInstance
      .post(`${API_BASE}${CROSS_ENDPOINTS.addAddress}`, formdata)
      .then((res) => {
        if (res.data.message === 'address_successfully_added') {
          dispatch(
            enqueueSnackbar({
              message: 'Address successfully added',
              variant: 'success',
            }),
          );
        }
        if (res.data.message === 'wrong_address_format') {
          dispatch(
            enqueueSnackbar({
              message: 'Wrong address format',
              variant: 'error',
            }),
          );
        }
      });
  };

export const addSocial =
  ({
    email,
    retailer_social_fb,
    retailer_social_ln,
    retailer_social_in,
    retailer_social_tw,
  }): AppThunk =>
  (dispatch) => {
    if (!email) return;

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('social_fb', retailer_social_fb || '');
    formdata.append('social_ln', retailer_social_ln || '');
    formdata.append('social_in', retailer_social_in || '');
    formdata.append('social_tw', retailer_social_tw || '');

    axiosAuthInstance
      .post(`${API_BASE}${CROSS_ENDPOINTS.addSocial}`, formdata)
      .then((res) => {
        if (res.data.message === 'social_successfully_added') {
          dispatch(
            enqueueSnackbar({
              message: 'Social successfully added',
              variant: 'success',
            }),
          );
        }
      });
  };

export const addProfile =
  ({
    email,
    retailer_name,
    retailer_description,
    retailer_website,
  }: {
    [k: string]: any;
  }): AppThunk =>
  (dispatch) => {
    if (!email) return;

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('company_name', retailer_name || '');
    formdata.append('company_description', retailer_description || '');
    formdata.append('company_website', retailer_website || '');

    axiosAuthInstance
      .post(`${API_BASE}${RETAILER_ENDPOINTS.addProfile}`, formdata)
      .then((res) => {
        if (res.data.data === 'retailer_profile_successfully_added') {
          dispatch(
            enqueueSnackbar({
              message: 'Retailer profile successfully added',
              variant: 'success',
            }),
          );
        }
      });
  };

export const generateApiKey =
  ({ email }): AppThunk =>
  async (dispatch) => {
    if (!email) return;
    const formdata = new FormData();
    formdata.append('email', email);

    const res = await axiosAuthInstance.post(
      `${API_BASE}${RETAILER_ENDPOINTS.generateApiKey}`,
      formdata,
    );

    if (res.data.data === 'apikey_successfully_generated') {
      dispatch(
        enqueueSnackbar({ message: 'API key generated', variant: 'success' }),
      );
    }

    if (res.data.message === 'phone_is_not_verified') {
      dispatch(
        enqueueSnackbar({ message: 'Phone is not verified', variant: 'error' }),
      );
    }

    if (res.data.message === 'retailer_profile_must_be_added') {
      dispatch(
        enqueueSnackbar({
          message: 'Retailer profile must be added',
          variant: 'error',
        }),
      );
    }

    return res.data;
  };

export const getApiKey =
  ({ email }): AppThunk =>
  async (): Promise<{ apikey: string }> => {
    if (!email) return;

    const formdata = new FormData();
    formdata.append('email', email);
    const res = await axiosAuthInstance.post(
      `${API_BASE}${RETAILER_ENDPOINTS.getApiKey}`,
      formdata,
    );

    return res.data;
  };

export default profleSlice.reducer;
