import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_BASE, CROSS_ENDPOINTS } from '../constants';
import { axiosAuthInstance } from '../utils/axios-utils';
import { setData, initialState as profileInitialState } from './profileSlice';
import {
  setData as setDashboardData,
  initialState as dashBoardInitialState,
} from './dashboardSlice';
import { enqueueSnackbar } from './snackbarSlice';
import { AppThunk } from '.';

export type AuthState = {
  token: string;
  email: string;
  loading: boolean;
  error: boolean;
};

const token = localStorage.getItem('token') || '';
const emailStore = localStorage.getItem('email') || '';

const initialState = {
  token,
  email: emailStore,
  loading: false,
  error: false,
} as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAuthSuccess: (state, action) => {
      state.token = action.payload.token;
      state.email = action.payload.email;
    },
    setAuthFailed: (state, action) => {
      state.error = action.payload;
    },
    setLogout: (state) => {
      state.token = '';
      state.email = '';
    },
  },
});

export const { setAuthSuccess, setLoading, setAuthFailed, setLogout } =
  authSlice.actions;

export const login =
  ({ email, password }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const formdata = new FormData();
      formdata.append('email', email);
      formdata.append('password', password);

      const res = await axios.post(
        `${API_BASE}${CROSS_ENDPOINTS.login}`,
        formdata,
      );

      if (res.data.session_token) {
        localStorage.setItem('token', res.data.session_token);
        localStorage.setItem('email', email);

        dispatch(setAuthSuccess({ token: res.data.session_token, email }));
      }

      if (res.data.message === 'user_not_exist' || res.data.message === false) {
        dispatch(
          enqueueSnackbar({
            message: 'User or password is not correct',
            variant: 'error',
          }),
        );
        dispatch(setAuthFailed(true));
      }
      if (res.data.message === 'email_is_not_confirmed') {
        dispatch(
          enqueueSnackbar({
            message: 'Email is not confirmed',
            variant: 'warning',
          }),
        );

        dispatch(setAuthFailed(true));
      }
      

      return res.data;
    } catch (error) {
      console.error(error);
      dispatch(setAuthFailed(true));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const logout = (email) => (dispatch) => {
  try {
    const formdata = new FormData();
    formdata.append('email', email);
    axiosAuthInstance.post(`${API_BASE}${CROSS_ENDPOINTS.logout}`, formdata);
  } catch (error) {
    console.error(error);
  } finally {
    localStorage.clear();
    dispatch(setLogout());
    dispatch(setData(profileInitialState.data));
    dispatch(setDashboardData(dashBoardInitialState.data));
  }
};

export default authSlice.reducer;
