export const breakPoints = (
  start: number,
  startWidth: number,
  startHeight: number,
  step = 50,
  to = 300,
  upperCase = false,
) => {
  const res: any = {};
  let width = startWidth - step;
  for (start; start > to; ) {
    res[`@media (max${upperCase ? 'W' : '-w'}idth:${start}px)`] = {
      height: (startHeight / startWidth) * width,
      width,
    };
    width -= step;
    start -= 50;
  }
  return res;
};
