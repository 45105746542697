import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardMedia,
  Link,
  Modal,
  ThemeProvider,
  Typography,
} from '@mui/material';
import styles from './src/styles';
import React, { useEffect, useState } from 'react';
import { customColors } from '../theme/palette';
import VR from './src/VR.png';
import Header from '../Header/Header';
import { lightTheme } from '../theme';
import BGroundRectangle from './components/BGroundRectangle';
import { breakPoints } from './src/breakpoints';
import Footer from '../Footer/Footer';
import { accordions } from './constants';
import { ExpandMore } from '@mui/icons-material';
import { ContactService } from './api/services/get-landing';
import { LpDataParser } from './src/lpDataParser';
import Loader from './components/Loader';

const breaks = breakPoints(600, 440, 330, 20, 250);

const accordionStyles = {
  borderRadius: '15px',
  backgroundColor: customColors.lightGray,
  marginBottom: '5px',
  boxShadow: '0px 0px',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    backgroundColor: customColors.white,
  },
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [doc, setDoc]: any = useState({});
  const [links, setLinks]: any = useState({});
  //const [modalTrigger, setModalTrigger] = useState(false);
  //const [cVid, setCVid] = useState(``);
  useEffect(() => {
    (async () => {
      try {
        const data = await ContactService.getLanding();
        if (data) {
          const { apiDoc, appLinks }: any = LpDataParser(data);
          setDoc(apiDoc);
          setLinks(appLinks);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <Header />
        {/*<Modal*/}
        {/*  open={modalTrigger}*/}
        {/*  onClose={() => setModalTrigger(false)}*/}
        {/*  aria-labelledby="modal-modal-title"*/}
        {/*  aria-describedby="modal-modal-description"*/}
        {/*>*/}
        {/*  <Box sx={{ ...styles.modal }}>*/}
        {/*    <CardMedia component='iframe' sx={{*/}
        {/*      height: "400px",*/}
        {/*      width: "100%",*/}
        {/*    }} src={cVid} />*/}
        {/*  </Box>*/}
        {/*</Modal>*/}
        <Box
          sx={{
            width: '98%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <BGroundRectangle
            {...styles.rectLeft}
            height="500px"
            backgroundColor={customColors.purple}
            {...styles.aboutRects}
            right="80%"
          />
          <Box
            sx={{
              ...styles.contentBox,
              maxWidth: '90%',
              alignItems: 'center',
              justifyContent: 'center',
              ['@media (max-width:1000px)']: {
                flexDirection: 'column',
                textAlign: 'center',
                maxHeight: '600px',
                marginBottom: '50px',
                marginTop: '30px',
              },
            }}
          >
            <Box
              sx={{
                ...styles.innerBox,
                minHeight: '400px',
                minWidth: '200px',
                maxWidth: '1000px',
                marginRight: '100px',
                ['@media (max-width:1400px)']: {
                  minWidth: '200px',
                  maxWidth: '300px',
                },
                ['@media (max-width:1000px)']: {
                  minHeight: '100px',
                  marginRight: '0px',
                },
              }}
            >
              <Typography
                variant="h1"
                color={customColors.black}
                sx={{
                  ...styles.defaultMargin,
                  fontWeight: 'bold',
                  ...styles.resizeH1,
                }}
              >
                DEVELOPERS
              </Typography>
              <Typography
                variant="h3"
                color={customColors.black}
                sx={{ ...styles.resizeH3, fontWeight: 500, maxWidth: '300px' }}
              >
                Technology and Logistics to grow your business with confidence
              </Typography>
            </Box>
          </Box>
          <BGroundRectangle
            {...styles.rectRight}
            height="500px"
            backgroundColor={customColors.lightGreen}
            {...styles.aboutRects}
            left="80%"
          />
          {loading ? (
            <Loader />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '60%',
                ['@media (max-width:1680px)']: {
                  width: '80%',
                },
                ['@media (max-width:900px)']: {
                  flexDirection: 'column',
                },
              }}
            >
              <Box sx={{}}>
                <Typography
                  variant="h2"
                  color={customColors.black}
                  sx={{
                    ...styles.defaultMargin,
                    fontWeight: 'bold',
                    ...styles.resizeH2,
                  }}
                >
                  API DOCUMENTATION
                </Typography>
                <Typography
                  variant="h3"
                  color={customColors.black}
                  sx={{
                    ...styles.defaultMargin,
                    ...styles.resizeH3,
                    fontWeight: 500,
                    maxWidth: '400px',
                  }}
                >
                  GoFlyy’s robust API and in-house engineering team helps
                  clients get started with GoFlyy in days instead of weeks.
                </Typography>
                <Link
                  href={doc.api_doc}
                  target="_blank"
                  color={customColors.purple}
                >
                  GoFlyy External API {doc.api_doc_version}
                </Link>
              </Box>
              <Button
                sx={{
                  backgroundImage: `url(${VR})`,
                  ...styles.videoImage,
                  width: '520px',
                  height: '380px',
                  backgroundSize: '500px',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  ['@media (max-width:1400px)']: {
                    height: '225px',
                    width: '295px',
                    backgroundSize: '300px',
                  },
                }}
                href={doc.api_doc_video_guide}
                target="_blank"
              >
                <Typography
                  sx={{
                    color: customColors.purple,
                    fontWeight: 'bold',
                    fontSize: 56,
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                >
                  Play video
                </Typography>
              </Button>
            </Box>
          )}
          {loading ? (
            <Loader />
          ) : (
            <Box
              sx={{
                ...styles.contentBox,
                maxWidth: '90%',
                alignItems: 'center',
                justifyContent: 'center',
                width: '66%',
                ['@media (max-width:1680px)']: {
                  width: '80%',
                },
                ['@media (max-width:1000px)']: {
                  flexDirection: 'column',
                  maxHeight: '600px',
                  marginBottom: '50px',
                  marginTop: '30px',
                },
              }}
              id="FAQ"
            >
              <Box sx={{ ...styles.faqTabletBox, width: '100%' }}>
                <Typography
                  variant="h2"
                  sx={{
                    ...styles.defaultMargin,
                    fontWeight: 500,
                    ...styles.resizeH2,
                  }}
                >
                  INTEGRATION VIDEO GUIDES
                </Typography>
                <Box>
                  {accordions.map((qna: any, index) => {
                    const { question, answerKey, videoKey } = qna;
                    if (!doc[answerKey]) {
                      return null;
                    }
                    return (
                      <Accordion key={index} sx={{ ...accordionStyles }} square>
                        <AccordionSummary
                          expandIcon={
                            <Box
                              sx={{
                                backgroundColor: customColors.primary,
                                borderRadius: '30%',
                                width: '24px',
                                height: '24px',
                              }}
                            >
                              <ExpandMore />
                            </Box>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h4" sx={{ fontWeight: 500 }}>
                            {question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500 }}
                          >
                            {doc[answerKey]}
                          </Typography>
                          {doc[videoKey] ? (
                            <Button
                              sx={{
                                backgroundImage: `url(${VR})`,
                                ...styles.videoImage,
                                '&:hover': {
                                  cursor: 'pointer',
                                },
                              }}
                              href={doc[videoKey]}
                              target="_blank"
                            >
                              <Typography
                                sx={{
                                  color: customColors.purple,
                                  fontWeight: 'bold',
                                  fontSize: 36,
                                  '&:hover': {
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  },
                                }}
                              >
                                Play video
                              </Typography>
                            </Button>
                          ) : null}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Footer appLinks={links}/>
      </ThemeProvider>
    </Box>
  );
};
