import { Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';


export const AlertSnackbar = ({
    open,
    setOpen,
    message,
    type
  }: any) => {
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  if (!type) return null;

  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={5000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      sx={{
        height: "200px",
      }}
    >
      <Alert severity={type}
        sx={{
          alignItems: "center",
          "& .MuiAlert-icon": {
            width: "22px",
            height: "22px",
          }
        }}
      >
        <Typography sx={{
          fontSize: 18,
        }}>{message}</Typography>
      </Alert>
    </Snackbar>
  );
};
