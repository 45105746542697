import { Box, Button, CardMedia, ThemeProvider, Typography } from '@mui/material';
import styles from './src/styles';
import React, { useEffect, useState } from 'react';
import { customColors } from '../theme/palette';
import CEO from './src/CEO.png';
import ln from './src/ln.png';
import inst from './src/inst.png';
import fb from './src/fb.png';
import twitter from './src/twitter.png';
import Header from '../Header/Header';
import { lightTheme } from '../theme';
import BGroundRectangle from './components/BGroundRectangle';
import { breakPoints } from './src/breakpoints';
import Footer from '../Footer/Footer';
import { ContactService } from './api/services/get-landing';
import { LpDataParser } from './src/lpDataParser';

const breaks = breakPoints(600, 440, 330, 20, 250);

export default () => {
  const [loading, setLoading] = useState(true);
  const [socials, setSocials]: any = useState({});
  const [links, setLinks]: any = useState({});
  useEffect (() => {
    (async () => {
      try {
        const data = await ContactService.getLanding();
        if (data) {
          const {social, appLinks}: any = LpDataParser(data);
          setSocials(social);
          setLinks(appLinks);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    })()
  }, []);
  return (
    <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%"}}>
      <ThemeProvider theme={lightTheme}>
        <Header />
        <Box sx={{...styles.landingBox}}>
          <BGroundRectangle {...styles.rectLeft} height="500px" {...styles.aboutRects} right="80%"/>
          <Box sx={{...styles.contentBox, maxWidth: "90%", alignItems: "center", justifyContent: "center",
            ['@media (max-width:1000px)']: {
              flexDirection: "column",
              textAlign: "center",
              maxHeight: "600px",
              marginBottom: "50px",
              marginTop: "30px"
            },}}>
            <Box sx={{...styles.innerBox, minHeight: "400px", minWidth: "200px", maxWidth: "500px", marginRight: "100px",
              ['@media (max-width:1400px)']: {
                minWidth: "200px", maxWidth: "300px",
              },
              ['@media (max-width:1000px)']: {
                minHeight: "100px",
                marginRight: "0px"
              },}}>
              <Typography variant="h1" color={customColors.black} sx={{...styles.defaultMargin, fontWeight: "bold", ...styles.resizeH1}}>
                ABOUT US
              </Typography>
              <Typography variant="h3" color={customColors.black} sx={{...styles.resizeH3, fontWeight: 500,}}>
                Technology and Logistics
                to grow your business with confidence
              </Typography>
            </Box>
            <CardMedia sx={{
              height: "330px",
              width: "440px",
              ...breaks
            }} image={require("./src/R107.png")}/>
          </Box>
          <Box sx={{...styles.contentBox, justifyContent: "center",
            ['@media (max-width:1000px)']: {
              maxHeight: "50%",
              minHeight: "50%"
            },}}>
            <Box sx={{...styles.innerBox, maxWidth: "70%",
              ['@media (max-width:1000px)']: {
                justifyContent: "flex-start",
                maxHeight: "100%",
                minHeight: "100%"
              },}}>
              <Typography variant="h2" color={customColors.purple} sx={{...styles.defaultMargin, ...styles.resizeH2, fontWeight: "bold"}}>
                WHO WE ARE
              </Typography>
              <Typography variant="h3" color={customColors.black} sx={{...styles.defaultMargin, ...styles.resizeH3}}>
                We get it.  Life is busy.  That doesn’t mean you shouldn’t be able to spend your time doing the things you love, and look flyy doing it.  The world has changed where you can get what you want anytime you want for a number of things, and fashion shouldn’t be any different.  ​
              </Typography>
              <Typography variant="h3" color={customColors.black} sx={{...styles.defaultMargin, ...styles.resizeH3}}>
                I remember sitting at my desk working on a number of tasks for several clients, trying my best to prioritize them as well as make it to an event that night.  I kept wondering, ‘why can’t someone just go pick up this dress for me?’. This was my life, in addition to last minute packing for every single trip.  Landing in the most beautiful city with left behind essentials. I mean, who wants to spend time looking for a strapless bra on their dream trip?
              </Typography>
              <Typography variant="h3" color={customColors.black} sx={{...styles.defaultMargin, fontWeight: "bold", ...styles.resizeH3}}>
                That’s how I got here. Creating a service for you that I love.
              </Typography>
              <Box sx={{...styles.ceoBox}}>
                <Box>
                  <img src={CEO} style={{maxHeight: "100px", maxWidth: "100px"}}/>
                </Box>
                <Box sx={{marginLeft: "3%"}}>
                  <Typography variant="h3" sx={{fontWeight: 'bold', color: customColors.darkPurple, ...styles.resizeH3}}>
                    JORESA BLOUNT
                  </Typography>
                  <Typography variant="h4" sx={{color: customColors.gray}}>
                    FOUNDER-CEO, GOFLYY
                  </Typography>
                </Box>
              </Box>
              {!loading && <Box sx={{ marginTop: "30px", display: "flex", flexDirection: "row" }}>
                <Typography variant="h3" color={customColors.black}
                            sx={{ fontWeight: "bold", ...styles.resizeH3, marginRight: "15px" }}>Follow Me</Typography>
                <Button sx={{maxHeight: '30px', maxWidth: '30px', minHeight: '30px', minWidth: '30px', marginRight: '5px'}} href={socials.social_facebook} target='_blank'>
                  <img src={fb} style={{ maxHeight: '30px', maxWidth: '30px', }} />
                </Button>
                <Button sx={{ maxHeight: '30px', maxWidth: '30px', minHeight: '30px', minWidth: '30px', marginRight: '5px' }} href={socials.social_instagram} target="_blank">
                  <img src={inst} style={{ maxHeight: '30px', maxWidth: '30px', }} />
                </Button>
                <Button sx={{maxHeight: '30px', maxWidth: '30px', minHeight: '30px', minWidth: '30px', marginRight: '5px'}} href={socials.social_linkedin} target="_blank">
                  <img src={ln} style={{ maxHeight: '30px', maxWidth: '30px',  }} />
                </Button>
                <Button sx={{maxHeight: '30px', maxWidth: '30px', minHeight: '30px', minWidth: '30px', marginRight: '5px'}} href={socials.social_twitter} target="_blank">
                  <img src={twitter} style={{ maxHeight: '30px', maxWidth: '30px' }} />
                </Button>
              </Box>
              }
            </Box>
          </Box>
          <BGroundRectangle {...styles.rectRight} height="500px" backgroundColor={customColors.lightGreen} {...styles.aboutRects} left="80%"/>
        </Box>
        <Footer appLinks={links} />
      </ThemeProvider>
    </Box>
  );
}