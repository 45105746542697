import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';

import { API_BASE, CROSS_ENDPOINTS } from '../../constants';
import Card from '../../components/Card';
import LoginLayout from '../../components/LoginLayout';
import { useAuthRedirect } from '../../utils/use-auth';
import Input from '../../components/Input';
import Box from '../../components/Box';
import Button from '../../components/Button';
import { AppDispatch } from '../../store';
import { enqueueSnackbar } from '../../store/snackbarSlice';

export default function Login() {
  useAuthRedirect(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const searchSplit = search
    .replaceAll('%3D', '=')
    .replaceAll('%26', '&')
    .split('&');

  const email = searchSplit[0].replace('?email=', '');
  const code = searchParams.get('code');

  const [newPassState, setNewPassState] = useState({
    password: '',
    repeatPassword: '',
  });

  const savePassword = async () => {
    const { password } = newPassState;

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('new_password', password);
    formdata.append('security_code', code);

    await axios
      .post(`${API_BASE}${CROSS_ENDPOINTS.changePassword}`, formdata)
      .then((res) => {
        if (res.data.message === 'password_successfully_changed') {
          dispatch(
            enqueueSnackbar({
              message: 'Password successfully changed',
              variant: 'success',
            }),
          );
        }
        if (res.data.message === 'wrong_security_code') {
          dispatch(
            enqueueSnackbar({
              message: 'Wrong security code',
              variant: 'error',
            }),
          );
        }
        if (res.data.message === 'user_not_exist') {
          dispatch(
            enqueueSnackbar({
              message: 'User not exist',
              variant: 'error',
            }),
          );
        }
        navigate('/login');
      });
  };

  return (
    <LoginLayout>
      <Card
        css={{
          width: '70%',
          maxWidth: '25rem',
          display: 'flex',
          flexDirection: 'column',
        }}
        color="gray"
        padd="medium"
      >
        <Box
          as="h1"
          css={{ alignSelf: 'center', margin: '2rem', fontSize: '1.3rem' }}
        >
          New password
        </Box>
        <Input
          color="white"
          css={{
            marginBottom: '1.5rem',
          }}
          value={newPassState.password}
          onChange={(e) =>
            setNewPassState((state) => ({
              ...state,
              password: e.target.value,
            }))
          }
          type="password"
          label="Password"
        />
        <Input
          color="white"
          value={newPassState.repeatPassword}
          onChange={(e) =>
            setNewPassState((state) => ({
              ...state,
              repeatPassword: e.target.value,
            }))
          }
          type="password"
          label=" Repeat Password"
        />
        <Box as="div" css={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            as="p"
            css={{
              fontFamily: '$light',
              color: '$gray3',
              fontSize: '.7rem',
              textAlign: 'center',
            }}
          >{`10–20 characters, one of which must support uppercase, lowercase, numeric or special characters such as ~ ! @ # $ % ^ * - _ = + [ { ] } / ; : , . ? except spaces.`}</Box>
        </Box>
        <Box
          as="div"
          css={{
            marginTop: '3rem',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={savePassword}
            color="calmYellow"
            css={{ width: '48%', padding: 0 }}
          >
            save
          </Button>
        </Box>
      </Card>
    </LoginLayout>
  );
}
