import { useEffect, useState, useRef } from 'react';
import * as Tabs from '@radix-ui/react-tabs';

import PagesLayout from '../../components/PagesLayout';
import Box from '../../components/Box';
import { styled } from '../../stitches.config';
import Info from './Info';
import Payment from './Payment';
import Notification from './Notification';
import { useAuth, useAuthRedirect } from '../../utils/use-auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getProfile } from '../../store/profileSlice';

//import { getOnboardingStatus } from '../../store/profileSlice';


import Integrations from './Integrations';

import InnerHTML from 'dangerously-set-html-content'

 

const TabList = styled(Tabs.List, {
  display: 'flex',
  maxWidth: '100%',
  br: '$6',
  bc: '$gray',
});

const TabTrigger = styled(Tabs.Trigger, {
  all: 'unset',
  fontFamily: '$blod',
  backgroundColor: '$gray',
  padding: '0 20px',
  height: '3.5rem',
  //width: '300px',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  color: '$gray2',
  br: '$6',
  lineHeight: 1,
  userSelect: 'none',
  cursor: 'pointer',
  '&:hover': { color: '$calmYellow' },
  '&[data-state="active"]': {
    color: '$dark',
    fontFamily: '$bold',
    bc: '$calmYellow',
    br: '$6',
  },
});

const TabsContent = styled(Tabs.Content, {});

const Profile = () => {
  
  useAuthRedirect();
  const { email } = useAuth();
  const phoneRef = useRef<undefined | string>();
  const dispatch = useDispatch<AppDispatch>();
  const profile = useSelector((state: RootState) => state.profile);
  phoneRef.current = profile.data.retailer_contact_phone;



  //alert(profile.data.info_completed)

  //CHECKING PROFILE COMPLETION
  if (profile.data.info_completed === true) {
    document.getElementById("info_completed_true").hidden = false;
    document.getElementById("info_completed_false").hidden = true;
  }
  if (profile.data.info_completed === false) {
    document.getElementById("info_completed_true").hidden = true;
    document.getElementById("info_completed_false").hidden = false;
  }


//CHECKING PAYMENT COMPLETION
if (profile.data.payment_added === true) {
  document.getElementById("payment_added_true").hidden = false;
  document.getElementById("payment_added_false").hidden = true;
}
if (profile.data.payment_added === false) {
  document.getElementById("payment_added_true").hidden = true;
  document.getElementById("payment_added_false").hidden = false;
}

//CHECKING AGREEMENT COMPLETION
if (profile.data.agreement_signed === true) {
  //document.getElementById("agreement_signed_true").hidden = false;
  //document.getElementById("agreement_signed_false").hidden = true;
}
if (profile.data.agreement_signed === false) {
  //document.getElementById("agreement_signed_true").hidden = true;
  //document.getElementById("agreement_signed_false").hidden = false;
}






  useEffect(() => {
    if (profile.data.status === '200' || profile.loading || !email) {
      return;
    }
    dispatch(getProfile({ email }));
  }, [profile, email, dispatch]);


  //AVOCHATO OLD
  //const avochato_tag = '<script type="text/javascript">!function(e,s){function a(o){function e(e){switch(e.data.type){case"sizeUpdate":o.style.height=e.data.height,o.style.width=e.data.width,o.style.bottom=e.data.bottom,o.style.right=e.data.right;break;case"fetchSession":var t;try{t=JSON.parse(s.localStorage.getItem(n))}catch(a){t={}}t.type="fetchSession",t.accessedAt=(new Date).getTime(),o.contentWindow.postMessage(t,o.src);break;case"saveSession":s.localStorage.setItem(n,JSON.stringify(e.data))}}var n="avochato-widget-7D9JaYQBmP";return e}function t(){if(!e.getElementById("avochato-text-us")&&n===s.location.href){s._avo_handle_message_func&&s.removeEventListener("message",s._avo_handle_message_func);var t=e.createElement("iframe");t.id="avochato-text-us",t.src="https://www.avochato.com/live_chat/7D9JaYQBmP?iframe_domain="+encodeURIComponent(s.location.href),t.setAttribute("style","position:fixed;bottom:2%;right:2%;border:none;height:100px;width:130px;z-index: 999999;"),s._avo_handle_message_func=a(t),s.addEventListener("message",s._avo_handle_message_func),s.Avochato=s.Avochato||{},s.Avochato.widgets=s.Avochato.widgets||{},s.Avochato.widgets["7D9JaYQBmP"]={hide:function(){t.style.setProperty("display","none")},openWithOption:function(e){t.style.setProperty("display",null),t.contentWindow.postMessage({type:"openWithOption",value:e},t.src)}},e.body.appendChild(t)}}function o(){i||(i=!0,t())}var n=s.location.href,i=!1;e.addEventListener&&e.addEventListener("DOMContentLoaded",o,!1),/loaded|interactive|complete/.test(e.readyState)&&o(),s.addEventListener("load",o)}(document,window);</script>';

  //AVOCHATO NEW
  const avochato_tag = '<script type="text/javascript">!function(e,s){function a(o){function e(e){switch(e.data.type){case"sizeUpdate":o.style.height=e.data.height,o.style.width=e.data.width,o.style.bottom=e.data.bottom,o.style.right=e.data.right;break;case"fetchSession":var t;try{t=JSON.parse(s.localStorage.getItem(n))}catch(a){t={}}t.type="fetchSession",t.accessedAt=(new Date).getTime(),o.contentWindow.postMessage(t,o.src);break;case"saveSession":s.localStorage.setItem(n,JSON.stringify(e.data))}}var n="avochato-widget-Q6EyZyrB2D";return e}function t(){if(!e.getElementById("avochato-text-us")&&n===s.location.href){s._avo_handle_message_func&&s.removeEventListener("message",s._avo_handle_message_func);var t=e.createElement("iframe");t.id="avochato-text-us",t.src="https://www.avochato.com/text_us/Q6EyZyrB2D?iframe_domain="+encodeURIComponent(s.location.href),t.setAttribute("style","position:fixed;bottom:2%;right:2%;border:none;height:100px;width:130px;z-index: 999999;"),s._avo_handle_message_func=a(t),s.addEventListener("message",s._avo_handle_message_func),s.Avochato=s.Avochato||{},s.Avochato.widgets=s.Avochato.widgets||{},s.Avochato.widgets.Q6EyZyrB2D={hide:function(){t.style.setProperty("display","none")},openWithOption:function(e){t.style.setProperty("display",null),t.contentWindow.postMessage({type:"openWithOption",value:e},t.src)}},e.body.appendChild(t)}}function o(){i||(i=!0,t())}var n=s.location.href,i=!1;e.addEventListener&&e.addEventListener("DOMContentLoaded",o,!1),/loaded|interactive|complete/.test(e.readyState)&&o(),s.addEventListener("load",o)}(document,window);</script>'

//alert(email)


  return (
    <PagesLayout>
      <Box as="header">
        <Box as="h1" css={{ fontFamily: '$bold', fontSize: '2rem', paddingBottom: '5px' }}>
        Complete three simple onboarding steps
        </Box>

        <Box as="h1" css={{ fontSize: '1.0rem', paddingBottom: '30px' }}>
        
        You are a momnet away from start shipping with GoFlyy!
        </Box>


        
      </Box>
      <Box as="div">
        <Tabs.Root defaultValue="information">
          <TabList>

            
            <TabTrigger 
              value="information">Profile
              <div id="info_completed_true" hidden>
                <svg fill="green" style={{marginLeft:"10px"}} width="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                  <path d="M854.344 1317.685 503.209 966.55l79.85-79.85 271.285 271.285 520.207-520.32 79.849 79.962-600.056 600.057ZM960.056 0c-529.355 0-960 430.645-960 960s430.645 960 960 960c529.243 0 960-430.645 960-960S1489.3 0 960.056 0Z" fill-rule="evenodd"/>
                </svg>
              </div>
              <div id="info_completed_false">
                <svg fill="gray" style={{marginLeft:"10px"}} width="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                <path d="M854.344 1317.685 503.209 966.55l79.85-79.85 271.285 271.285 520.207-520.32 79.849 79.962-600.056 600.057ZM960.056 0c-529.355 0-960 430.645-960 960s430.645 960 960 960c529.243 0 960-430.645 960-960S1489.3 0 960.056 0Z" fill-rule="evenodd"/>
                </svg>
              </div>
            </TabTrigger>

            <TabTrigger
              disabled={!profile.data.retailer_contact_first_name}
              value="payment">Payment

              <div id="payment_added_true" hidden>
                <svg fill="green" style={{marginLeft:"10px"}} width="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                  <path d="M854.344 1317.685 503.209 966.55l79.85-79.85 271.285 271.285 520.207-520.32 79.849 79.962-600.056 600.057ZM960.056 0c-529.355 0-960 430.645-960 960s430.645 960 960 960c529.243 0 960-430.645 960-960S1489.3 0 960.056 0Z" fill-rule="evenodd"/>
                </svg>
              </div>
              <div id="payment_added_false">
                <svg fill="gray" style={{marginLeft:"10px"}} width="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                <path d="M854.344 1317.685 503.209 966.55l79.85-79.85 271.285 271.285 520.207-520.32 79.849 79.962-600.056 600.057ZM960.056 0c-529.355 0-960 430.645-960 960s430.645 960 960 960c529.243 0 960-430.645 960-960S1489.3 0 960.056 0Z" fill-rule="evenodd"/>
                </svg>
              </div>
                          
            </TabTrigger>

            {
            //<TabTrigger
              //disabled={!profile.data.retailer_contact_first_name}
              //value="integrations">Agreement

               // <div id="agreement_signed_true" hidden>
              //  <svg fill="green" style={{marginLeft:"10px"}} width="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
               //   <path d="M854.344 1317.685 503.209 966.55l79.85-79.85 271.285 271.285 520.207-520.32 79.849 79.962-600.056 600.057ZM960.056 0c-529.355 0-960 430.645-960 960s430.645 960 960 960c529.243 0 960-430.645 960-960S1489.3 0 960.056 0Z" fill-rule="evenodd"/>
              //  </svg>
             // </div>
            //  <div id="agreement_signed_false">
            //    <svg fill="gray" style={{marginLeft:"10px"}} width="30px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
            //    <path d="M854.344 1317.685 503.209 966.55l79.85-79.85 271.285 271.285 520.207-520.32 79.849 79.962-600.056 600.057ZM960.056 0c-529.355 0-960 430.645-960 960s430.645 960 960 960c529.243 0 960-430.645 960-960S1489.3 0 960.056 0Z" fill-rule="evenodd"/>
            //    </svg>
           //   </div>
          //  </TabTrigger>
            
              //<TabTrigger
              //  disabled={!profile.data.retailer_contact_first_name}
              //  value="integrations"
            // >
              //</TabList>  
              //</TabTrigger>
            }
          </TabList>
          <TabsContent
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            value="information"
          >
            <Info phoneRef={phoneRef.current} />
          </TabsContent>
          <TabsContent
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            value="payment"
          >
            <Payment />
          </TabsContent>
          <TabsContent
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            value="notification"
          >
            <Notification />
          </TabsContent>
          <TabsContent
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            value="integrations"
          >
            <Integrations />
          </TabsContent>
        </Tabs.Root>
      </Box>

      <InnerHTML html={avochato_tag} />

    </PagesLayout>
  );
};

export default Profile;
