import styles from '../src/styles';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../theme/palette';
import { breakPoints } from '../src/breakpoints';
import BGroundRectangle from './BGroundRectangle';
import StyledButton from '../../UI/StyledButton';

const breaks = breakPoints(1450, 684, 517, 20);

export default () => (

<Box
    sx={{
      ...styles.contentBox,
      alignItems: 'center',
      ...styles.contentBoxRight,
      justifyContent: 'center',
      flexWrap: 'wrap-reverse',
      ['@media (max-width:1500px)']: {
        flexDirection: 'column',
      },
    }}
  >
    <BGroundRectangle {...styles.rectLeft} />
    <CardMedia
      sx={{
        height: '593px',
        width: '784px',
        ['@media (max-width:1700px)']: {
          height: '517px',
          width: '684px',
        },
        ...breaks,
      }}
      image={require('../src/R65.png')}
    />
    <Box
      sx={{
        ...styles.innerBox,
        minWidth: '200px',
        width: '25%',
        marginLeft: '10%',
        ['@media (max-width:1500px)']: {
          width: '30%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'center',
          marginLeft: '0px',
          minHeight: '10%',
          marginTop: '40px',
        },
        ['@media (max-width:900px)']: {
          width: '70%',
        },
      }}
    >
      <Typography variant="h2" sx={{ fontWeight: 'bold', ...styles.resizeH2 }}>
        REACH NEW
      </Typography>
      <Typography
        variant="h2"
        sx={{
          ...styles.defaultMargin,
          fontWeight: 'bold',
          color: customColors.purple,
          ...styles.resizeH2,
        }}
      >
        CLIENTS
      </Typography>
      <Typography
        variant="h3"
        sx={{
          ...styles.defaultMargin,
          width: '390px',
          ...styles.resizeH3,
          ['@media (max-width:900px)']: {
            width: '80%',
          },
        }}
      >
          Whether you need same-day delivery, return pickups or at-home try on experiences, our stellar technology and customer service will help you gain new customers and retain the ones you have.
      </Typography>
      <StyledButton
          sx={{ height: '65px', minWidth: '170px' }}
          text="Get started"
          href="/signup"
        />
      
    </Box>
  </Box>
);
