import { styled } from '../stitches.config';

const Footer = styled('footer', {
  display: 'none',
  height: '9%',
  width: '100%',
  justifyContent: 'space-between',
  '@sm': { display: 'flex' },
});

export default Footer;
