import axios from 'axios';

const axiosAuthInstance = axios.create();

axiosAuthInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  config.headers.token = token;
  return config;
});

axiosAuthInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    /**
     * If the session is expired, redirect to login
     */
    if (response.data.message === 'wrong_token') {
      localStorage.clear();
      window.location.href = '/login';
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export { axiosAuthInstance };
