import {
  UserCircleIcon,
  SupportIcon,
  ViewBoardsIcon,
  LogoutIcon,
} from '@heroicons/react/outline';
import type { ReactNode } from 'react';
import { useAuth, useAuthRedirect } from '../utils/use-auth';
import Box from './Box';
import Button from './Button';
import ButtomLink from './ButtonLink';
import Footer from './Footer';
import LinkStyled from './LinkStyled';
import Logo from './Logo';

import { logout as doLogout } from '../store/authSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

type Props = {
  children: ReactNode;
};

export default function PagesLayout({ children }: Props) {
  useAuthRedirect();
  const dispatch = useDispatch<AppDispatch>();
  const { email } = useAuth();
  const { pathname } = useLocation();
  const profile = useSelector((state: RootState) => state.profile);

  const logOut = async () => {
    dispatch(doLogout(email));
  };

  return (
    <Box
      as="section"
      css={{
        bc: '$white',
        minHeight: '110vh',
        maxHeight: '90vh',
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '1rem',
        '@sm': { padding: '3rem' },
        
      }}
    >
      <Box
        as="header"
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: '100vw',
          marginBottom: '3rem',
        }}
      >
        <a href="/"><Logo /></a>
        
        <Box
          as="div"
          css={{
            display: 'flex',
            ' .hide-mobile': {
              display: 'none',
            },
            '@sm': {
              ' .hide-mobile': {
                display: 'block',
                marginLeft: '.3rem',
              },
              '@sm': { '.hide-desk': { display: 'none' } },
            },
          }}
        >
          <LinkStyled to="/dashboard">
            <Button
              color={pathname === '/dashboard' ? 'black' : 'white'}
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                height: '2.5rem',
                padding: '.6rem .5rem .5rem .5rem',
                '@sm': {
                  paddingRight: '.8rem',
                },
              }}
            >
              <Box as="span" className="hide-desk">
                <ViewBoardsIcon style={{ height: '22px', width: '22px' }} />
              </Box>
              <Box as="span" className="hide-mobile">
                Orders
              </Box>
            </Button>
          </LinkStyled>
          {/*
          <LinkStyled to="/support">
            <Button
              color={pathname === '/support' ? 'black' : 'white'}
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                height: '2.5rem',
                padding: '.5rem',
              }}
            >
              <Box as="span" className="hide-desk">
                <SupportIcon style={{ height: '22px' }} />
              </Box>
              <Box as="span" className="hide-mobile">
                Support
              </Box>
            </Button>
          </LinkStyled>
            */}

          <LinkStyled to="/billingHistory">
            <Button
              color={pathname === '/billingHistory' ? 'black' : 'white'}
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                height: '2.5rem',
                padding: '.5rem',
              }}
            >
              <Box as="span" className="hide-desk">
                <SupportIcon style={{ height: '22px' }} />
              </Box>
              <Box as="span" className="hide-mobile">
                Billing
              </Box>
            </Button>
          </LinkStyled>

          <LinkStyled to="/profile">
            <Button
              color={pathname === '/profile' ? 'black' : 'white'}
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                padding: '.5rem',
                height: '2.5rem',
              }}
            >
              <Box as="span" className="hide-mobile">
                Profile &nbsp;
              </Box>

              <UserCircleIcon style={{ height: '22px' }} />

            </Button>
          </LinkStyled>
          <Button
            color="white"
            css={{ padding: '.5rem .4rem', height: '2.5rem' }}
            onClick={logOut}
          >
            
            <LogoutIcon style={{ height: '20px' }} />
            
          </Button>
        </Box>
      </Box>
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          width: '90%',
          '@md': { width: '85%' },
        }}
      >
        {children}
      </Box>
      <Footer css={{ marginTop: 'auto', paddingBottom: '1rem',}}>
        <Box as="span" css={{ alignSelf: 'flex-end', fontFamily: '$medium'}}>
          &#169;Goflyy,2024
        </Box>
        <Box as="div" css={{ display: 'none', '@md': { display: 'block' } }}>
          <ButtomLink
            href="/TermsofService"
            css={{
              marginLeft: '.5rem',
              marginRight: '.5rem',
              color: '$gray3',
              textDecorationColor: '$calmYellow',
            }}
          >
            Terms of Service
          </ButtomLink>
          <ButtomLink
            href="/PrivacyPolicy"
            css={{
              marginLeft: '1rem',
              marginRight: '1rem',
              color: '$gray3',
              textDecorationColor: '$calmYellow',
            }}
          >
            Privacy Policy
          </ButtomLink>
          <ButtomLink
            href="/OfferAgreement"
            css={{
              marginLeft: '.5rem',
              marginRight: '.5rem',
              color: '$gray3',
              textDecorationColor: '$calmYellow',
            }}
          >
            Offer Agreement
          </ButtomLink>
        </Box>
        <Box as="span" css={{ alignSelf: 'flex-end', fontFamily: '$medium' }}>
          Design — <b>Atwinta</b>
        </Box>
      </Footer>
    </Box>
  );
}
