import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { API_BASE, CROSS_ENDPOINTS } from '../../constants';
import Card from '../../components/Card';
import LoginLayout from '../../components/LoginLayout';
import { useAuthRedirect } from '../../utils/use-auth';
import Input from '../../components/Input';
import Box from '../../components/Box';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import { AppDispatch } from '../../store';
import { enqueueSnackbar } from '../../store/snackbarSlice';

export default function Login() {
  useAuthRedirect(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState({
    email: '',
  });

  const resetPassword = async () => {
    const { email } = loginState;
    const formdata = new FormData();
    formdata.append('email', email);

    await axios
      .post(`${API_BASE}${CROSS_ENDPOINTS.resetPassword}`, formdata)
      .then((res) => {
        if (res.data.message === 'email_successfully_sent') {
          dispatch(
            enqueueSnackbar({
              message: 'Email successfully sent',
              variant: 'success',
            }),
          );
        }
        if (res.data.message === 'user_not_exist') {
          dispatch(
            enqueueSnackbar({
              message: 'User not exist',
              variant: 'error',
            }),
          );
        }

        navigate('/login');
      });
  };

  return (
    <LoginLayout>
      <Card
        css={{
          width: '70%',
          maxWidth: '25rem',
          display: 'flex',
          flexDirection: 'column',
        }}
        color="gray"
        padd="medium"
      >
        <Box
          as="h1"
          css={{ alignSelf: 'center', margin: '2rem', fontSize: '1.3rem' }}
        >
          Forgot password?
        </Box>
        <Box as="p">
          Enter the email address you used when you joined and we`ll send you
          instructions to reset your password.
        </Box>
        <br></br>
        <Input
          color="white"
          value={loginState.email}
          onChange={(e) =>
            setLoginState((state) => ({
              ...state,
              email: e.target.value,
            }))
          }
          label="Email address"
        />
        <Box
          as="div"
          css={{
            marginTop: '4rem',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={resetPassword}
            color="calmYellow"
            css={{
              width: '100%',
              textAlign: 'center',
              padding: 0,
              '@sm': {
                width: '48%',
              },
            }}
          >
            Send reset instructions
          </Button>
          <ButtonLink
            css={{
              width: '100%',
              textAlign: 'center',
              '@sm': {
                width: '48%',
                paddingRight: 0,
                paddingLeft: 0,
              },
            }}
            href="/login"
            color="gray"
          >
            Back to log in
          </ButtonLink>
        </Box>
      </Card>
    </LoginLayout>
  );
}
