import {
  Box,
  Button,
  Checkbox,
  debounce,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import styles from '../src/styles';
import React, { useCallback, useEffect, useState } from 'react';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { ContactService } from '../api/services/contact-service';

const validateEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regex.test(email);
};

export default ({
  setOpen: setSnackOpen,
  setMessage: setSnackMessage,
  setType: setSnackType,
}: any) => {
  const [cName, setCName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [message, setMessage] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [token, setToken] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const ApiToken = `6Ldr9icgAAAAAAGPItDD4CG6mxmmBK-llD6gdI5U`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const resp = await ContactService.submitContactForm(
      email,
      name,
      cName,
      message,
    );

    if (resp.status === 200) {
      setSubmitted(true);
      setSnackMessage(`Successfully sent`);
      setSnackType('success');
      setSnackOpen(true);
    } else {
      setSnackMessage(`Error! Please try again.`);
      setSnackType('error');
      setSnackOpen(true);
    }
  };
  return (
    <Box
      sx={{ ...styles.specialContentBox, justifyContent: 'center' }}
      className="Contact Us"
    >
      <Box sx={{ ...styles.innerBox, ...styles.contactUsBox }}>
        <GoogleReCaptchaProvider
          reCaptchaKey={ApiToken}
          useRecaptchaNet={true}
          scriptProps={{ async: true, defer: true }}
        >
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h2"
              sx={{ ...styles.defaultMargin, ...styles.resizeH2 }}
            >
              CONTACT US
            </Typography>
            <Box
              sx={{
                marginBottom: '20px',
                display: 'flex',
                flexDirection: 'row',
                ['@media (max-width:1300px)']: {
                  flexDirection: 'column',
                },
              }}
            >
              <TextField
                required
                label="Company name"
                variant="outlined"
                type="required"
                sx={{ ...styles.input }}
                onChange={(e) => setCName(e.target.value)}
              />
              <TextField
                required
                label="Contact name"
                variant="outlined"
                sx={{ ...styles.input, marginLeft: '10px' }}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                required
                label="Contact email"
                variant="outlined"
                sx={{ ...styles.input, marginLeft: '10px' }}
                onChange={(e) => {
                  setEmail(e.target.value);
                  const validated = validateEmail(e.target.value);
                  setValidEmail(validated);
                }}
              />
            </Box>
            <TextField
              required
              label="Message"
              variant="outlined"
              sx={{
                ...styles.bigInput,
                marginBottom: '20px',
                width: '100%',
                height: '100px',
              }}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                ['@media (max-width:1300px)']: {
                  flexDirection: 'column',
                },
              }}
            >
              <Box
                sx={{
                  marginRight: '10px',
                  width: '250px',
                  ['@media (max-width:1300px)']: {
                    marginRight: '0px',
                    marginBottom: '10px',
                  },
                }}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label="I agree with the Terms of Service, Privacy Policy, Offer agreement."
                  onChange={() => setAccepted((prevState) => !prevState)}
                />
              </Box>
              {token && (
                <Button
                  variant="outlined"
                  type="submit"
                  sx={{
                    ...styles.createAnAcc,
                    ['@media (max-width:1300px)']: {
                      marginTop: '20px',
                      width: '100%',
                    },
                    ['@media (max-width:400px)']: {
                      width: '90%',
                    },
                  }}
                  color="info"
                  disabled={
                    !cName ||
                    !name ||
                    !validEmail ||
                    !message ||
                    !accepted ||
                    !token ||
                    submitted
                  }
                >
                  Send
                </Button>
              )}
              <GoogleReCaptcha
                onVerify={(prop) => {
                  if (prop) {
                    setToken(true);
                  }
                }}
              />
            </Box>
          </form>
        </GoogleReCaptchaProvider>
      </Box>
    </Box>
  );
};
