import { Box, CircularProgress, Fab, ThemeProvider } from '@mui/material';
import Header from '../Header/Header';
import { lightTheme } from '../theme';
import styles from './src/styles';
import Experience from './components/Experience';
import EasyTo from './components/EasyTo';
import OurRetailParts from './components/OurRetailParts';
import Testimonials from './components/Testimonials';
import QNA from './components/QNA';
import QNATablet from './components/QNATablet';
import ScrollTop from '../UI/ScrollTop';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { customColors } from '../theme/palette';
import EasyTo2 from './components/EasyTo2';
import IndustyLeading from './components/IndustyLeading';
import CompleteVisibility from './components/CompleteVisibility';
import FlexibleAndScalable from './components/FlexibleAndScalable';
import Tryouts from './components/Tryouts';
import DontWannaCall from './components/DontWannaCall';
import ContactUs from './components/ContactUs';
import HowItWorks from './components/HowItWorks';
import IntegrationWith from './components/IntegrationWith';
import Footer from '../Footer/Footer';
import { useEffect, useMemo, useState } from 'react';
import { ContactService } from './api/services/get-landing';
import { LpDataParser } from './src/lpDataParser';
import Loader from './components/Loader';
import { AlertSnackbar } from './components/Snackbar';

import InnerHTML from 'dangerously-set-html-content'

import { webEvent as dowebEvent } from '../../store/api';

export default () => {
  const [loading, setLoading] = useState(true);
  const [tests, setTests] = useState([]);
  const [faq, setFaq] = useState([]);
  const [partners, setPartners]: any = useState([]);
  const [links, setLinks]: any = useState([]);
  const [phone, setPhone] = useState(``);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState(`Success`);
  const [snackType, setSnackType] = useState('success')

  //const [apiDoc, setApiDoc] = useState({});

  //AVOCHATO OLD
  //const avochato_tag = '<script type="text/javascript">!function(e,s){function a(o){function e(e){switch(e.data.type){case"sizeUpdate":o.style.height=e.data.height,o.style.width=e.data.width,o.style.bottom=e.data.bottom,o.style.right=e.data.right;break;case"fetchSession":var t;try{t=JSON.parse(s.localStorage.getItem(n))}catch(a){t={}}t.type="fetchSession",t.accessedAt=(new Date).getTime(),o.contentWindow.postMessage(t,o.src);break;case"saveSession":s.localStorage.setItem(n,JSON.stringify(e.data))}}var n="avochato-widget-7D9JaYQBmP";return e}function t(){if(!e.getElementById("avochato-text-us")&&n===s.location.href){s._avo_handle_message_func&&s.removeEventListener("message",s._avo_handle_message_func);var t=e.createElement("iframe");t.id="avochato-text-us",t.src="https://www.avochato.com/live_chat/7D9JaYQBmP?iframe_domain="+encodeURIComponent(s.location.href),t.setAttribute("style","position:fixed;bottom:2%;right:2%;border:none;height:100px;width:130px;z-index: 999999;"),s._avo_handle_message_func=a(t),s.addEventListener("message",s._avo_handle_message_func),s.Avochato=s.Avochato||{},s.Avochato.widgets=s.Avochato.widgets||{},s.Avochato.widgets["7D9JaYQBmP"]={hide:function(){t.style.setProperty("display","none")},openWithOption:function(e){t.style.setProperty("display",null),t.contentWindow.postMessage({type:"openWithOption",value:e},t.src)}},e.body.appendChild(t)}}function o(){i||(i=!0,t())}var n=s.location.href,i=!1;e.addEventListener&&e.addEventListener("DOMContentLoaded",o,!1),/loaded|interactive|complete/.test(e.readyState)&&o(),s.addEventListener("load",o)}(document,window);</script>';

  //AVOCHATO NEW
  const avochato_tag = '<script type="text/javascript">!function(e,s){function a(o){function e(e){switch(e.data.type){case"sizeUpdate":o.style.height=e.data.height,o.style.width=e.data.width,o.style.bottom=e.data.bottom,o.style.right=e.data.right;break;case"fetchSession":var t;try{t=JSON.parse(s.localStorage.getItem(n))}catch(a){t={}}t.type="fetchSession",t.accessedAt=(new Date).getTime(),o.contentWindow.postMessage(t,o.src);break;case"saveSession":s.localStorage.setItem(n,JSON.stringify(e.data))}}var n="avochato-widget-Q6EyZyrB2D";return e}function t(){if(!e.getElementById("avochato-text-us")&&n===s.location.href){s._avo_handle_message_func&&s.removeEventListener("message",s._avo_handle_message_func);var t=e.createElement("iframe");t.id="avochato-text-us",t.src="https://www.avochato.com/text_us/Q6EyZyrB2D?iframe_domain="+encodeURIComponent(s.location.href),t.setAttribute("style","position:fixed;bottom:2%;right:2%;border:none;height:100px;width:130px;z-index: 999999;"),s._avo_handle_message_func=a(t),s.addEventListener("message",s._avo_handle_message_func),s.Avochato=s.Avochato||{},s.Avochato.widgets=s.Avochato.widgets||{},s.Avochato.widgets.Q6EyZyrB2D={hide:function(){t.style.setProperty("display","none")},openWithOption:function(e){t.style.setProperty("display",null),t.contentWindow.postMessage({type:"openWithOption",value:e},t.src)}},e.body.appendChild(t)}}function o(){i||(i=!0,t())}var n=s.location.href,i=!1;e.addEventListener&&e.addEventListener("DOMContentLoaded",o,!1),/loaded|interactive|complete/.test(e.readyState)&&o(),s.addEventListener("load",o)}(document,window);</script>'


  useEffect(() => {

    //const webEvent;



  }, []);


  useEffect(() => {
    (async () => {
      try {
        const data = await ContactService.getLanding();
        if (data) {
          const {
            testimonials,
            faq: parsedFaq,
            retailers,
            appLinks,
            telephone
          } = LpDataParser(data);
          setTests(testimonials);
          setFaq(parsedFaq);
          setPartners(retailers);
          setLinks(appLinks);
          setPhone(telephone);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const Landing = useMemo(
    () => () =>
      (
        <>
        <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              //backgroundColor: 'red'
            }}
          >
            <ThemeProvider theme={lightTheme}>

              <Header isTests={!loading && tests.length} />
              <Box sx={{ ...styles.landingBox }}>
                <Experience appLinks={links}/>
              </Box>
            </ThemeProvider>
        </Box>

              
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            //backgroundColor: 'yellow'
          }}
        >
          <ThemeProvider theme={lightTheme}>

              <EasyTo />
              <EasyTo2 />
              <IntegrationWith />
              
              
              <FlexibleAndScalable />
              <CompleteVisibility />
              <IndustyLeading />
              <Tryouts />
              <HowItWorks />
              {!loading ? (
                partners.length ? (
                  <OurRetailParts partners={partners} />
                ) : null
              ) : (
                <Loader />
              )}
              {!loading ? (
                tests.length ? (
                  <Testimonials tests={tests} />
                ) : null
              ) : (
                <Loader />
              )}
              {!loading && faq.length ? (
                <Box sx={{ width: '100%', height: '0px' }} className="FAQ" />
              ) : null}
              {!loading ? faq.length ? <QNA faq={faq} phone={phone}/> : null : <Loader />}

              {/*
              
              {!loading ? (
                faq.length ? (
                  <QNATablet faq={faq} phone={phone}/>
                ) : null
              ) : (
                <Loader />
              )}
              */}

              <DontWannaCall/>

              <ContactUs setOpen={setOpenSnack} setMessage={setSnackMessage} setType={setSnackType} />

              {/* 
              <ScrollTop>
                <Fab
                  color="primary"
                  size="large"
                  aria-label="scroll back to top"
                  sx={{
                    height: '97px',
                    width: '97px',
                    marginBottom: '5px',
                    '&:hover': { backgroundColor: customColors.secondary },
                  }}
                >
                  <KeyboardArrowUpIcon />
                </Fab>
              </ScrollTop>
            */}


</ThemeProvider>

            </Box>
            
            <AlertSnackbar open={openSnack} setOpen={setOpenSnack} message={snackMessage} type={snackType} />
            <Footer appLinks={links} />
          
         
        
         <InnerHTML html={avochato_tag}/>
         </>
        
      ),
    [loading, tests, faq, partners, openSnack, snackMessage, snackType, links, phone],
  );
  
  return <Landing />;
};
