import styles from '../src/styles';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../theme/palette';
import StyledButton from '../../UI/StyledButton';
import { breakPoints } from '../src/breakpoints';
import BGroundRectangle from './BGroundRectangle';

const breaks = breakPoints(1450, 684, 517, 20);

export default () => (
  <Box
    sx={{
      ...styles.contentBox,
      ...styles.contentBoxLeft,
      alignItems: 'center',
      flexWrap: 'wrap-reverse',
      ['@media (max-width:1450px)']: {
        flexDirection: 'column-reverse',
      },
    }}
  >
    <Box
      sx={{
        ...styles.innerBox,
        minWidth: '200px',
        width: '25%',
        marginRight: '10%',
        ['@media (max-width:1450px)']: {
          width: '30%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'center',
          marginRight: '0px',
          minHeight: '10%',
          marginTop: '40px',
        },
        ['@media (max-width:900px)']: {
          width: '70%',
        },
      }}
    >
      <Typography variant="h2" sx={{ fontWeight: 'bold', ...styles.resizeH2 }}>
        LET'S CHAT
      </Typography>
     
      <Typography
        variant="h3"
        sx={{ ...styles.defaultMargin, width: '300px', ...styles.resizeH3 }}
      >
        Our sales team is ready to get you started. No time for a call? Sign up with one click.
      </Typography>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start",
        ['@media (max-width:1450px)']: {
          alignItems: "center",
        },
      }}>
      
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center",}}>
          <StyledButton
            sx={{
              height: '60px',
              minWidth: '200px',
              marginTop: '20px',
            }}
            text="Get Started"
            href="/registration"
          />
         
        </Box>
      </Box>
    </Box>
    <CardMedia
      sx={{
        height: '593px',
        width: '784px',
        ['@media (max-width:1700px)']: {
          height: '517px',
          width: '684px',
        },
        ...breaks,
      }}
      image={require('../src/R107.png')}
    />

    <BGroundRectangle
      {...styles.rectRight}
      backgroundColor={customColors.informational}
    />
  </Box>
);
