import { styled } from '../stitches.config';
import * as Dialog from '@radix-ui/react-dialog';
import Box from './Box';
import { XIcon } from '@heroicons/react/outline';

const Trigger = styled(Dialog.Trigger, {
  br: '$5',
  border: 'none',
});

const Close = styled(Dialog.Close, {
  br: '$5',
  border: 'none',
});

const StyledOverlay = styled(Dialog.Overlay, {
  backgroundColor: 'transparent',
  position: 'fixed',
  inset: 0,
});

const StyledContent = styled(Dialog.Content, {
  backgroundColor: 'white',
  borderRadius: '$5',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  maxHeight: '85%',
  padding: '2rem 1.5rem',
  overflowY: 'auto',
  '@sm': { padding: '2rem 3rem' },
  '&:focus': { outline: 'none' },
});

const Modal = ({
  children = null,
  title,
  content,
  onOk = () => {},
  onClose = () => {},
  okText = '',
  cssTrigger = {},
  open = undefined,
  ...props
}) => {
  return (
    <Dialog.Root open={open}>
      <Trigger css={cssTrigger}>{children}</Trigger>
      <Dialog.Portal>
        <StyledOverlay />
        <StyledContent {...props}>
          <Box
            className="header"
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <Dialog.Title style={{ fontSize: '1.5rem' }}>{title}</Dialog.Title>
            <Close css={{ bc: '$white' }} onClick={onClose}>
              <XIcon style={{ height: '20px' }} />
            </Close>
          </Box>
          <Box className="content">{content}</Box>
          {okText !== '' ? (
            <Box
              className="footer"
              css={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <Close
                onClick={onOk}
                css={{
                  bc: '$calmYellow',
                  padding: '0rem 1rem',
                  br: '$5',
                  height: '3rem',
                }}
              >
                {okText}
              </Close>
            </Box>
          ) : (
            <></>
          )}
        </StyledContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
