import styles from '../src/styles';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../theme/palette';
import { breakPoints } from '../src/breakpoints';
import StyledButton from '../../UI/StyledButton';
import BGroundRectangle from './BGroundRectangle';
import { useNavigate } from 'react-router-dom';

const breaks = breakPoints(1450, 684, 517, 20);

export default () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        ...styles.contentBox,
        alignItems: 'center',
        height: '593px',
        flexWrap: 'wrap-reverse',
        flexDirection: 'column',
        ['@media (max-width:1360px)']: {
          minHeight: '370px',
          height: '370px',
        },
      }}
    >
      <Box
        sx={{
          ...styles.innerBox,
          minHeight: '593px',
          height: '100%',
          minWidth: '700px',
          width: '90%',
          ['@media (max-width:1360px)']: {
            marginRight: '0px',
            alignItems: 'flex-start',
            width: '100%',
            minHeight: '370px',
            height: '370px',
          },
          ['@media (max-width:900px)']: {
            width: '80%',
            minWidth: '0px',
          },
        }}
      >
        <Typography variant="h2" sx={{ ...styles.resizeH2 }}>
        WE INTEGRATE
        </Typography>
        <Typography
          variant="h2"
          sx={{ ...styles.defaultMargin, ...styles.resizeH2 }}
        >
          WITH
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            ['@media (max-width:1360px)']: {
              width: '100%',
            },
          }}
        >
          <Box sx={{}}>
            <StyledButton
              sx={{
                minWidth: '170px',
                marginRight: '10px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                  minWidth: '130px',
                  marginBottom: '10px',
                },
              }}
              text="BigCommerce"
              onClick={() => navigate('/develop')}
            />
            <StyledButton
              sx={{
                minWidth: '130px',
                marginRight: '10px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: '10px',
                },
              }}
              text="Shopwired"
              onClick={() => navigate('/develop')}
            />
            <StyledButton
              sx={{
                minWidth: '110px',
                marginRight: '10px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: '10px',
                },
              }}
              text="Shopify"
              onClick={() => navigate('/develop')}
            />
            <StyledButton
              sx={{
                minWidth: '80px',
                marginRight: '10px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: '10px',
                },
              }}
              text="Wix"
              onClick={() => navigate('/develop')}
            />
            <StyledButton
              sx={{
                minWidth: '80px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: '10px',
                },
              }}
              text="Zyro"
              onClick={() => navigate('/develop')}
            />
          </Box>
          <Box>
            <StyledButton
              sx={{
                minWidth: '140px',
                marginRight: '10px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: '10px',
                },
              }}
              text="Shiftforshop"
              onClick={() => navigate('/develop')}
            />
            <StyledButton
              sx={{
                minWidth: '110px',
                marginRight: '10px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: '10px',
                },
              }}
              text="Magento"
              onClick={() => navigate('/develop')}
            />
            <StyledButton
              sx={{
                minWidth: '80px',
                marginRight: '10px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: '10px',
                },
              }}
              text="Wix"
              onClick={() => navigate('/develop')}
            />
            <StyledButton
              sx={{
                minWidth: '110px',
                marginRight: '10px',
                marginBottom: '10px',
                ['@media (max-width:1360px)']: {
                  fontSize: 16,
                  fontWeight: 500,
                },
              }}
              text="Volusion"
              onClick={() => navigate('/develop')}
            />
          </Box>
        </Box>

        
      </Box>
      
      <BGroundRectangle
        {...styles.rectRight}
        backgroundColor={customColors.lightGreen}
        width="2000px"
        left="60%"
      />
    </Box>
  );
};
