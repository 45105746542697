import { styled } from '../stitches.config';

const ButtonLink = styled('a', {
  variants: {
    color: {
      calmYellow: {
        bc: '$calmYellow',
      },
      gray: {
        border: '$calmYellow solid 1px',
        textDecoration: 'none',
        color: 'Black',
        padding: '.79rem 2.3rem .79rem 2.3rem',
        br: '$5',
        marginBottom: '.5rem',
      },
      black: {
        bc: '$dark',
        color: '$white',
        br: '$5',
        fontSize: '.8rem',
        border: 'none',
        padding: '.5rem .5rem .3rem .5rem',
        marginBottom: '1.5rem',
        textDecoration: 'none',
        marginRight: '.2rem',
        '@sm': { padding: '.75rem .65rem .3rem .5rem', marginBottom: '.5rem' },
      },
      borderGray: {
        bc: '$white',
        color: '$dark',
        br: '$5',
        fontSize: '.8rem',
        border: 'solid .15rem $gray',
        padding: '.5rem .5rem .3rem .5rem',
        marginBottom: '1.5rem',
        textDecoration: 'none',
        marginRight: '.2rem',
        '@sm': { padding: '.75rem .65rem .3rem .5rem', marginBottom: '.5rem' },
      },
    },
  },
});

export default ButtonLink;
