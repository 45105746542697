import Box from '../../components/Box';
import Button from '../../components/Button';
import Card from '../../components/Card';
import * as Checkbox from '@radix-ui/react-checkbox';
import { useAuth } from '../../utils/use-auth';
import { axiosAuthInstance } from '../../utils/axios-utils';
import { styled } from '../../stitches.config';
import { API_BASE, CROSS_ENDPOINTS } from '../../constants';
import { CheckIcon } from '@heroicons/react/outline';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const CheckIconStyled = styled(CheckIcon, { height: '18px' });

const CheckboxRoot = styled(Checkbox.Root, {
  backgroundColor: 'white',
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '$4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid .15rem $calmYellow',
  paddingTop: '.5rem',
  '&:hover': { backgroundColor: '$gray' },
  '&[data-state="checked"]': { backgroundColor: '$calmYellow' },
});

const CheckboxIndicator = styled(Checkbox.Indicator, {
  color: '$dark',
});

const Notification = () => {
  const { email } = useAuth();

  const [notificationStatus, setNotificationStatus] = useState<{
    [k: string]: any;
  }>({});

  const profile = useSelector((state: RootState) => state.profile);

  const updateNotifications = async () => {
    if (!email) return;

    await axiosAuthInstance.post(
      `${API_BASE}${CROSS_ENDPOINTS.updateNotifications}`,
      {
        email,
        settings: notificationStatus,
      },
    );
  };

  useEffect(() => {
    const getNotifications = async () => {
      if (!email) return;

      const res = await axiosAuthInstance.get(
        `${API_BASE}${
          CROSS_ENDPOINTS.getNotificationsStatus
        }?email=${email.replaceAll('+', '%2B')}`,
      );

      delete res.data.status;
      setNotificationStatus(res.data || {});
    };

    getNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      as="section"
      css={{
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem',
        width: '100%',
      }}
    >
      <Card
        color="gray"
        css={{
          display: 'grid',
          gridTemplateColumns: '60% 40%',
          padding: '1rem',
          br: '$5',
          height: '3.5rem',
        }}
      >
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            as="span"
            css={{ fontSize: '.75rem', '@sm': { fontSize: '1rem' } }}
          >
            E-mail address
          </Box>
          <Box
            as="span"
            css={{ fontSize: '.75rem', '@sm': { fontSize: '1rem' } }}
          >
            Phone number
          </Box>
        </Box>
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            as="span"
            css={{
              fontSize: '.75rem',
              overflowX: 'auto',
              '@sm': { fontSize: '1rem' },
            }}
          >
            {email}
          </Box>
          <Box
            as="span"
            css={{ fontSize: '.75rem', '@sm': { fontSize: '1rem' } }}
          >
            {profile.data?.retailer_contact_phone
              ? `+${profile.data?.retailer_contact_phone.replace('+', '')}`
              : ' - '}
          </Box>
        </Box>
      </Card>
      <Card
        color="white"
        css={{
          display: 'grid',
          gridTemplateColumns: '69% 10% 10% 10%',
          gridRowGap: '$2',
          padding: '1rem',
          marginTop: '1rem',
        }}
      >
        <Box />
        <Box
          as="span"
          css={{ fontSize: '.75rem', '@sm': { fontSize: '1rem' } }}
        >
          Email
        </Box>
        <Box
          as="span"
          css={{ fontSize: '.75rem', '@sm': { fontSize: '1rem' } }}
        >
          Text
        </Box>
        <Box
          as="span"
          css={{ fontSize: '.75rem', '@sm': { fontSize: '1rem' } }}
        >
          Push
        </Box>
        {notificationStatus &&
          Object.keys(notificationStatus).map((noti) => (
            <React.Fragment key={noti}>
              <Box>{notificationStatus[noti].name}</Box>
              <CheckboxRoot
                onCheckedChange={(checked) =>
                  setNotificationStatus((state) => ({
                    ...state,
                    [noti]: { ...state[noti], email: checked },
                  }))
                }
                checked={notificationStatus[noti].email}
              >
                <CheckboxIndicator>
                  <CheckIconStyled />
                </CheckboxIndicator>
              </CheckboxRoot>
              <CheckboxRoot
                onCheckedChange={(checked) =>
                  setNotificationStatus((state) => ({
                    ...state,
                    [noti]: { ...state[noti], txt: checked },
                  }))
                }
                checked={notificationStatus[noti].txt}
              >
                <CheckboxIndicator>
                  <CheckIconStyled />
                </CheckboxIndicator>
              </CheckboxRoot>
              <CheckboxRoot
                onCheckedChange={(checked) =>
                  setNotificationStatus((state) => ({
                    ...state,
                    [noti]: { ...state[noti], push: checked },
                  }))
                }
                checked={notificationStatus[noti].push}
              >
                <CheckboxIndicator>
                  <CheckIconStyled />
                </CheckboxIndicator>
              </CheckboxRoot>
            </React.Fragment>
          ))}
      </Card>
      <Button
        color="white"
        css={{ width: '15.5rem', margin: '2rem 0', alignSelf: 'center' }}
        onClick={updateNotifications}
      >
        Save
      </Button>
    </Box>
  );
};

export default Notification;
