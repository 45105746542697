import axios from 'axios';

const URL = '/getLanding/retailer';
const baseUrl = `https://api.goflyy.com/v1`;

export class ContactService {
  static async getLanding() {
    try {
      const resp = await axios.get(baseUrl + URL);
      if (resp.status === 200) {
        return resp.data;
      }
      return undefined;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }
}
