import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useCallback } from 'react';
import GFIcon from '../UI/GFIcon';
import { customColors } from '../theme/palette';
import StyledButton from '../UI/StyledButton';
import { useNavigate } from 'react-router-dom';

// TODO: fix routing


const linksByName: { [index: string]: any } = {
  About: '/about',
  'For Developers': '/develop',
};

const settings = ['Profile', 'Dashboard', 'Logout'];

const styles = {
  appBar: {
    backgroundColor: customColors.white,
    justifyContent: 'space-between',
    boxShadow: '0px 0px',
  },
  appBarSticky: {
    background: 'rgba(255, 255, 255, 0.9)',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 200,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px',
  },
  signUp: {
    color: customColors.black,
    backgroundColor: customColors.primary,
    marginLeft: '15px',
    width: '200px',
    borderRadius: '15px',
  },
  toolBar: {
    ['@media (max-width:1200px)']: {
      justifyContent: 'space-between',
    },
    marginTop: '20px'
  },
};

export default ({isTests, }: any) => {
  const history = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 64,
  });

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = useCallback(
    ({ target }: any) => {
      const element = document.getElementsByClassName(target.innerText)[0];
      const yOffset = -72;
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      } else {
        window.scrollTo({top: 0,});
        if (linksByName[target.innerText]) {
          history(linksByName[target.innerText]);
        } else {
          history('/');
        }
      }
      handleCloseNavMenu();
    },
    [history],
  );

  return (
    <AppBar
      sx={trigger ? { ...styles.appBarSticky } : { ...styles.appBar }}
      position="static"
    >
      <Container maxWidth="xl" >
        <Toolbar disableGutters sx={{ ...styles.toolBar }}>
          <Button
            sx={{
              marginRight: '25px',
              ['@media (max-width:500px)']: {
                marginRight: '1%',
                maxWidth: '100px',
              },
            }}
            onClick={() => history('/')}
          >
            <GFIcon />
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
            <Button
              key={'Benefits'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              Benefits
            </Button>
            <Button
              key={'How it works'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              How it works
            </Button>
            <Button
              key={'About'}
              onClick={() => history('/about')}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              About
            </Button>
            <Button
              key={'Partners'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              Partners
            </Button>
            {isTests ? <Button
              key={'Testimonials'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color='inherit'
            >
              Testimonials
            </Button> : null}
            <Button
              key={'FAQ'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              FAQ
            </Button>
            <Button
              key={'Contact Us'}
              onClick={handleMenuClick}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              Contact Us
            </Button>
            <Button
              key={'For Developers'}
              onClick={() => history('/develop')}
              sx={{ my: 2, display: 'block' }}
              color="inherit"
            >
              Developers
            </Button>
          </Box>
       
          <Box sx={{ flexGrow: 0 }}>
            <StyledButton
              sx={{
                ...styles.signUp,
                ['@media (max-width:1500px)']: {
                  minWidth: '85px',
                  width: '95px',
                  fontSize: 17,
                },
              }}
              text="Sign In"
              href="/login"
            />
            
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
