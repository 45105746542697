import { configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';

import auth from './authSlice';
import signup from './signupSlice';
import profile from './profileSlice';
import dashboard from './dashboardSlice';
import support from './supportSlice';
import snackbar from './snackbarSlice';

const store = configureStore({
  reducer: {
    auth,
    dashboard,
    signup,
    profile,
    support,
    snackbar,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export default store;
