import styles from '../src/styles';
import { Box, CardMedia, Typography } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const imgStyles = {
  width: '200px',
  height: '100px',
  '&:hover': {
    width: '220px',
    height: '110px',
    position: 'absolute',
  },
  ['@media (max-width:800px)']: {
    width: '140px',
    height: '70px',
    '&:hover': {
      width: '180px',
      height: '90px',
      position: 'absolute',
    },
  },
  ['@media (max-width:600px)']: {
    width: '100px',
    height: '50px',
    '&:hover': {
      width: '120px',
      height: '60px',
      position: 'absolute',
    },
  },
};

export default ({ partners }: any) => (
  <Box sx={{...styles.specialContentBox, alignItems: "center", ['@media (max-width:1300px)']: {
    flexDirection: "column",
  }}} className="How it works">
  <Box sx={{...styles.retailBox, height: "600px"}}>
    <Box sx={{width: "75%"}}>
      <Box>
        <Typography variant="h2">
          OUR
        </Typography>
        <Typography variant="h2">
          RETAIL PARTNERS
        </Typography>
      </Box>
     
    </Box>
  </Box>
    <Box
      sx={{
        width: '66%',
        minWidth: '400px',
        ['@media (max-width:1280px)']: {
          width: '100%',
          minWidth: '98%',
        },
      }}
    >
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        interval={1000}
        centerMode={true}
        centerSlidePercentage={20}
      >
        {partners.map((partner: any) => {
          return (
            <CardMedia
              component="image"
              sx={{ ...imgStyles }}
              key={partner}
              image={partner}
            />
          );
        })}
      </Carousel>
    </Box>
  </Box>
);
