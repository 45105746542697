import { customColors } from '../../theme/palette';
import typography from '../../theme/typography';

export default {
  landingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    ['@media (max-width:1200px)']: {
      alignItems: 'flex-start',
    },
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '5%',
    width: '90%',
    ['@media (max-width:1200px)']: {
      width: '96%',
      marginBottom: '100px',
    },
    ['@media (max-width:900px)']: {
      width: '98%',
    },
  },
  specialContentBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '5%',
    width: '100%',
  },
  contentBoxLeft: {
    paddingLeft: '4.5%',
    ['@media (max-width:1600px)']: {
      paddingLeft: '0px',
    },
  },
  contentBoxRight: {
    paddingLeft: '10%',
    ['@media (max-width:1501px)']: {
      paddingLeft: '0px',
    },
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '800px',
    minWidth: '25%',
    maxWidth: '75%',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  expBox: {
    backgroundPosition: '300px -100px',
    backgroundSize: '2000px',
    backgroundRepeat: 'no-repeat',
    alignContent: 'center',
    justifyContent: 'flex-start',
    ['@media (max-width:1200px)']: {
      backgroundPosition: '100px -100px',
    },
    ['@media (max-width:1000px)']: {
      backgroundPosition: '0px -100px',
    },
    ['@media (max-width:800px)']: {
      backgroundSize: '1500px',
      backgroundPosition: '-100px 200px',
    },
    ['@media (max-width:550px)']: {
      backgroundPosition: '-200px 200px',
    },
    ['@media (max-width:450px)']: {
      backgroundPosition: '-250px 200px',
    },
  },
  sidedContent: {
    width: '30%',
  },
  signUp: {
    color: customColors.black,
    backgroundColor: customColors.primary,
    borderRadius: '26px',
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    minWidth: '48px',
    minHeight: '48px',
  },
  assets: {
    backgroundColor: customColors.black,
    display: 'flex',
    width: '48px',
    height: '54px',
    justifyContent: 'center',
    alignItems: 'center',
    color: customColors.primary,
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: customColors.darkGray,
    },
  },
  defaultMargin: {
    marginBottom: '5%',
  },
  createAnAcc: {
    width: '250px',
    color: customColors.black,
    borderRadius: '10px',
    borderWidth: '3px',
    fontSize: 'subtitle2',
    '&:hover': {
      borderWidth: '3px',
    },
  },
  iconBox: {
    width: '50px',
    backgroundColor: customColors.white,
    color: customColors.darkPurple,
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  retailBox: {
    backgroundColor: customColors.primary,
    color: customColors.black,
    display: 'flex',
    justifyContent: 'center',
    height: '60%',
    width: '34%',
    minWidth: '220px',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottomRightRadius: '100%',
    borderTopRightRadius: '100%',
    ['@media (max-width:1300px)']: {
      minWidth: "0px",
      width: "100%",
      height: "400px",
      marginBottom: "50px"
    },
  },
  ceoBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  twoSidedPics: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '30px',
  },
  faqBox: {
    color: customColors.black,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '10%',
    maxHeight: '340px',
    maxWidth: '55%',
    width: '55%',
    flexDirection: 'column',
    backgroundColor: customColors.lightGreen,
    borderBottomRightRadius: '50%',
    borderTopRightRadius: '50%',
  },
  faqTabletBox: {
    color: customColors.black,
    padding: '5%',
    borderRadius: '7px',
    flexDirection: 'column',
  },
  joinUsBox: {
    backgroundColor: customColors.darkPurple,
    color: customColors.white,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '12%',
    minHeight: '40%',
    maxWidth: '80%',
    borderRadius: '15px',
    flexDirection: 'column',
    ['@media (max-width:1630px)']: {
      marginTop: '20px',
      width: '100%',
      maxWidth: '100%',
    },
  },
  testsBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ['@media (max-width:900px)']: {
      minWidth: '100%',
      justifyContent: 'center',
    },
  },
  sliderButtons: {
    borderRadius: '100%',
    backgroundColor: customColors.green,
    color: customColors.white,
    minWidth: '50px',
    minHeight: '50px',
    maxWidth: '50px',
    maxHeight: '50px',
    '&:hover': {
      backgroundColor: customColors.darkGreen,
    },
    ['@media (max-width:900px)']: {
      minWidth: '40px',
      minHeight: '40px',
      maxWidth: '40px',
      maxHeight: '40px',
      padding: '0px',
    },
  },
  reviewBox: {
    width: '470px',
    height: '350px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginLeft: '10%',
    marginRight: '10%',
    ['@media (max-width:700px)']: {
      width: '350px',
    },
    ['@media (max-width:600px)']: {
      width: '300px',
    },
    ['@media (max-width:500px)']: {
      width: '200px',
      marginLeft: '0px',
      marginRight: '0px',
    },
    ['@media (max-width:400px)']: {
      width: '200px',
    },
  },
  contactUsBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '98%',
    width: '82%',
    height: '97%',
    minHeight: '800px',
    border: `5px solid ${customColors.primary}`,
    borderRadius: "800px",
    ['@media (max-width:1200px)']: {
      borderRadius: '25%',
      height: '96%',
      width: '98%',
      minHeight: '900px',
    },
    ['@media (max-width:700px)']: {
      minHeight: '700px',
    },
  },
  gradientBox: {
    background: 'linear-gradient(to bottom right, #1CF5C1, #1C24F5)',
    maxWidth: '98%',
    width: '55%',
    borderRadius: '35%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    alignContent: 'center',
    minHeight: '800px',
    ['@media (max-width:1200px)']: {
      width: '98%',
      borderRadius: '25%',
    },
  },
  input: {
    [`& fieldset`]: {
      fontSize: '16px',
      borderRadius: '16px',
    },
    ['@media (max-width:1300px)']: {
      width: '100%',
      marginLeft: '0px',
      marginTop: '10px',
    },
    ['@media (max-width:400px)']: {
      width: '90%',
    },
  },
  bigInput: {
    [`& fieldset`]: {
      height: '90px',
      fontSize: '16px',
      borderRadius: '16px',
    },
    ['@media (max-width:1300px)']: {
      width: '100%',
      marginLeft: '0px',
    },
    ['@media (max-width:400px)']: {
      width: '90%',
    },
  },
  resizeH1: {
    ['@media (max-width:1400px)']: {
      fontSize: typography.h2.fontSize,
    },
    ['@media (max-width:760px)']: {
      fontSize: 32,
    },
  },
  resizeH2: {
    ['@media (max-width:1400px)']: {
      fontSize: 28,
    },
    ['@media (max-width:760px)']: {
      fontSize: 24,
    },
  },
  resizeH3: {
    ['@media (max-width:1400px)']: {
      fontSize: 16,
    },
    ['@media (max-width:760px)']: {
      fontSize: 18,
    },
  },
  bckGroundRect: {
    width: '784px',
    height: '593px',
    borderRadius: '300px',
    backgroundColor: customColors.primary,
    position: 'absolute',
    zIndex: -1,
    ['@media (max-width:1500px)']: {
      display: 'none',
    },
  },
  rectRight: {
    left: '85%',
  },
  rectLeft: {
    right: '85%',
  },
  aboutRects: {
    ['@media (max-width:1680px)']: {
      display: 'none',
    },
  },
  unlockBox: {
    backgroundPosition: '100px -300px',
    backgroundSize: '1150px',
    backgroundRepeat: 'no-repeat',
    ['@media (max-width:1400px)']: {
      backgroundPosition: '600px -210px',
      backgroundSize: '900px',
    },
    ['@media (max-width:1000px)']: {
      backgroundPosition: '0px -200px',
      backgroundSize: '910px',
    },
  },
  videoImage: {
    height: '225px',
    width: '295px',
    backgroundSize: '300px',
    backgroundRepeat: 'no-repeat',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
};
