import { globalCss } from '@stitches/react';
import { Provider } from 'react-redux';

import store from './store';
import Routes from './config/Routes';

import './assets/styles/index.css';
import Notifier from './components/Notifier';

const globalStyles = globalCss({
  '*': { margin: 0, padding: 0, fontFamily: '$bold', fontSize: '16px' },
  button: { cursor: 'pointer' },
  body: { overflowY: 'overlay' },
});

const App = () => {
  globalStyles();

  return (
    <Provider store={store}>
      <Routes />
      <Notifier />
    </Provider>
  );
};

export default App;
