import styles from '../src/styles';
import { customColors } from '../../theme/palette';
import { Box, Button, Typography } from '@mui/material';
import StyledButton from '../../UI/StyledButton';
import React from 'react';
import { ReactComponent as Apple } from '../src/assets/Apple.svg';
import { ReactComponent as GooglePlay } from '../src/assets/GP.svg';
import { ReactComponent as Windows } from '../src/assets/Windows.svg';
import { ReactComponent as Shopify } from '../src/assets/Shopify.svg';
import typography from '../../theme/typography';
import Union from '../src/Union.png';
import DeliveryBox from '../src/R1.jpg';

export default ({appLinks}: any) => (
  
  <Box
    sx={{
      ...styles.contentBox,
      width: '100%',
      alignItems: 'center',
      //backgroundImage: `url(${DeliveryBox})`,
      //backgroundImage: `url(linear-gradient(red, yellow))`,
      //background: "radial-gradient(ellipse at left bottom, rgb(90, 52, 0) 0%,rgba(19, 8, 39, 0.9) 69%, rgba(0, 0, 0, 0.9) 100%)",
      
      ...styles.unlockBox,
    }}
  >
    <Box
      sx={{
        ...styles.innerBox,
        ...styles.sidedContent,
        minWidth: '800px',
        width: '80%',
        marginLeft: '10%',
        ['@media (max-width:1300px)']: {
          minWidth: '800px',
        },
        ['@media (max-width:760px)']: {
          minWidth: '300px',
          justifyContent: 'flex-start',
          paddingTop: '10%',
        },
      }}
    >
      <Typography variant="h1" sx={{ ...styles.resizeH1, color: customColors.purple,}}>
        UNLOCK POTENTIAL
      </Typography>
      <Typography variant="h1" sx={{ ...styles.resizeH1 }}>
        WITH ON-DEMAND
      </Typography>
      <Typography variant="h1" sx={{ marginBottom: '8%', ...styles.resizeH1 }}>
        DELIVERY & RETURNS
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '8%',
          ['@media (max-width:1300px)']: {
            flexDirection: 'column',
          },
        }}
      >
        <Box sx={{ marginRight: '100px' }}>
          <Typography variant="h3" sx={{ ...styles.resizeH3 }}>
            Technology and Logistics
          </Typography>
          <Typography variant="h3" sx={{ ...styles.resizeH3 }}>
            to grow your business with
          </Typography>
          <Typography variant="h3" sx={{ ...styles.resizeH3 }}>
            confidence
          </Typography>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center",
          ['@media (max-width:1300px)']: {
            alignItems: "flex-start",
            flexDirection: "row",
            marginTop: "20px"
          },
        }}>
          <Box sx={{display: "flex", flexDirection: "column", alignItems: "center",}}>
            <StyledButton
              sx={{
                height: '60px',
                minWidth: '200px',
                ['@media (max-width:1300px)']: {
                  marginTop: '20px',
                },
              }}
              text="Get Started"
              href="/signup"
            />


            {//<StyledButton
            //  sx={{
            //    height: '60px',
            //    minWidth: '200px',
            //    marginTop: '20px',
            //    color: "black",
            //    borderWidth: "0px",
            //    textDecoration: "underline",
            //  }}
            //  text="Shoppers Portal"
            //  href="https://shop.goflyy.com"
            //  target="_blank"
            //  variant="outlined"
            ///>
            //<StyledButton
            //  sx={{
            //    height: '60px',
            //    minWidth: '200px',
            //    marginTop: '5px',
            //    color: "black",
            //    borderWidth: "0px",
            //    textDecoration: "underline",
            //  }}
            //  text="Drivers Portal"
            //  href="https://driver.goflyy.com"
            //  target="_blank"
            //  variant="outlined"
            ///> 
            }


          </Box>
        </Box>
      </Box>
      <Box sx={{}}>
        { appLinks.ios_app_link ?
          <Button href={appLinks.ios_app_link}>
            <Apple style={{ ...styles.imageIcon }} />
          </Button>
        : null}
        { appLinks.android_app_link ?
          <Button href={appLinks.android_app_link}>
            <GooglePlay style={{ ...styles.imageIcon }} />
          </Button>
        : null}
        { appLinks.win_app_link ?
          <Button href={appLinks.win_app_link}>
            <Windows style={{ ...styles.imageIcon }} />
          </Button>
        : null}
        { appLinks.shopify_app_link ?
          <Button href={appLinks.shopify_app_link}>
            <Shopify style={{ ...styles.imageIcon, maxHeight: '48px', maxWidth: '48px' }} />
          </Button>
        : null}
      </Box>
    </Box>
    <Box sx={{ width: '100%' }}></Box>
  </Box>
);
