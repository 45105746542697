import { useEffect, useState } from 'react';
import { useAuth, useAuthRedirect } from '../../utils/use-auth';
import Box from '../../components/Box';
import Input from '../../components/Input';
import Button from '../../components/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useFileUpload } from 'use-file-upload';
import { PhotographIcon } from '@heroicons/react/solid';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadProfilePicture,
  addContact,
  addPhone as doAddPhone,
  verifyPhone as doVerifyPhone,
  addAddress,
  addSocial,
  addProfile,
  generateApiKey,
} from '../../store/profileSlice';

import {
  CardMedia,
  Checkbox,
  FormControlLabel, IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  //Select,
  MenuItem
} from '@mui/material';

import Modal from '../../components/Modal';

import {
  getApiKey,
  generateApiKey as doGenerateApiKey,
} from '../../store/profileSlice';

import { enqueueSnackbar } from '../../store/snackbarSlice';

import Autocomplete from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { usePlacesWidget } from "react-google-autocomplete";

const Info = ({ phoneRef }) => {

  const [addressStreet, setStreet] = useState("");
  const [addressCity, setCity] = useState("");
  const [addressState, setState] = useState("");
  const [addressZip, setZip] = useState("");
  const [addressCountry, setCountry] = useState("");

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyAFpUvr6VeIKf--CTji6Y_Cd0odWV3u5DE',
    onPlaceSelected: (place) => {
      console.log(place);
      //alert(JSON.stringify(place))
      //alert(JSON.stringify(place.address_components[6].long_name))

      const house_number = place.address_components[0].long_name
      const street_name = place.address_components[1].long_name
      const city_name = place.address_components[2].long_name
      const state_name = place.address_components[4].long_name
      const country_name = place.address_components[5].long_name
      const zip_code = place.address_components[6].long_name

      setStreet(house_number + " " + street_name)
      setCity(city_name)
      setState(state_name)
      setZip(zip_code)
      setCountry(country_name)


      //alert(house_number)

    },
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "us" },
    },
  });

  function autoAddress(e) {

    //alert("HERE" + e)

  }


  useAuthRedirect();
  const { email } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const [file, selectFile] = useFileUpload();
  const profile = useSelector((state: RootState) => state.profile);

  const [verifyPhonedata, setVerifyPhone] = useState({
    code: '',
  });

  const [apiKey, setApiKey] = useState('');

  const [showVerifyPhone, setShowVerifyPhone] = useState(false);

  const [currentProfile, setCurrentProfile] = useState(profile.data);

  useEffect(() => {
    setCurrentProfile(profile.data);
  }, [profile.data]);





  const addPhone = () => {
    if (!email) return;
    const { retailer_contact_phone, retailer_contact_country_code } =
      currentProfile;

    if (!retailer_contact_phone || !retailer_contact_country_code) {
      return;
    }

    if (
      phoneRef?.replace('+', '') !== retailer_contact_phone.replace('+', '')
    ) {
      dispatch(
        doAddPhone({
          email,
          retailer_contact_phone,
          retailer_contact_country_code,
        }),
      );

      setShowVerifyPhone(true);
    }
   
  };

  const verifyPhone = () => {
    if (!email) return;

    const { code } = verifyPhonedata;
    const { retailer_contact_phone, retailer_contact_country_code } =
      currentProfile;

    if (!retailer_contact_phone || !retailer_contact_country_code) {
      return;
    }

    dispatch(
      doVerifyPhone({
        code,
        email,
        retailer_contact_phone,
        retailer_contact_country_code,
      }),
    );

    setShowVerifyPhone(false);
  };

  const handleSubmit = () => {



    const {
      retailer_contact_first_name,
      retailer_contact_last_name,
      retailer_contact_title,
      retailer_contact_notes,
      //retailer_pickup_or_dropoff_address1,
      //retailer_pickup_or_dropoff_city,
      //retailer_pickup_or_dropoff_state,
      //retailer_pickup_or_dropoff_zip,
      //retailer_pickup_or_dropoff_country,
      retailer_pickup_or_dropoff_details,
      retailer_social_fb,
      retailer_social_tw,
      retailer_social_in,
      retailer_social_ln,
      retailer_name,
      retailer_description,
      retailer_website,
    } = currentProfile;



    const retailer_pickup_or_dropoff_address1 = addressStreet;
    const retailer_pickup_or_dropoff_city = addressCity;
    const retailer_pickup_or_dropoff_state = addressState;
    const retailer_pickup_or_dropoff_zip = addressZip;
    const retailer_pickup_or_dropoff_country = addressCountry;

    sessionStorage.setItem("retailer_pickup_or_dropoff_address1", retailer_pickup_or_dropoff_address1);


    //alert("addressStreetVal" + addressStreetVal)



    if(!retailer_contact_first_name) {dispatch(enqueueSnackbar({ message: 'Please enter first name', variant: 'error' }),); return }
    if(!retailer_contact_last_name) {dispatch(enqueueSnackbar({ message: 'Please enter last name', variant: 'error' }),); return }
    if(!retailer_name) {dispatch(enqueueSnackbar({ message: 'Please enter company name', variant: 'error' }),); return }


    //if(!currentProfile.retailer_pickup_or_dropoff_address1) {

        //if(!retailer_pickup_or_dropoff_address1) {dispatch(enqueueSnackbar({ message: 'Please enter pickup address', variant: 'error' }),); return }
        //if(!retailer_pickup_or_dropoff_city) {dispatch(enqueueSnackbar({ message: 'Please enter pickup city', variant: 'error' }),); return }
        //if(!retailer_pickup_or_dropoff_state) {dispatch(enqueueSnackbar({ message: 'Please enter pickup state', variant: 'error' }),); return }
        //if(!retailer_pickup_or_dropoff_zip) {dispatch(enqueueSnackbar({ message: 'Please enter pickup zip', variant: 'error' }),); return }
        //if(!retailer_pickup_or_dropoff_country) {dispatch(enqueueSnackbar({ message: 'Please enter pickup country', variant: 'error' }),); return }

      //}

    const { retailer_contact_phone, retailer_contact_country_code } =
      currentProfile;

    //alert (currentProfile.retailer_contact_phone + " " + currentProfile.retailer_contact_phone.length)
    if (!currentProfile.retailer_contact_phone) {
      dispatch(enqueueSnackbar({ message: 'Please enter phone number', variant: 'error' }),); return
    } else {
      //alert (retailer_contact_phone.length)
      if (currentProfile.retailer_contact_phone.length < 11) { dispatch(enqueueSnackbar({ message: 'Please enter correct phone number', variant: 'error' }),); return }
    }

    dispatch(
      addContact({
        email,
        retailer_contact_first_name,
        retailer_contact_last_name,
        retailer_contact_title,
        retailer_contact_notes,
      }),
    );
    dispatch(
      addProfile({
        email,
        retailer_name,
        retailer_description,
        retailer_website,
      }),
    );
    //dispatch(
    //  addSocial({
    //    email,
    //    retailer_social_fb,
    //    retailer_social_tw,
    //    retailer_social_in,
    //    retailer_social_ln,
    //  }),
    //);
    dispatch(uploadProfilePicture({ email, file }));

    //alert(retailer_pickup_or_dropoff_state)

    dispatch(
      addAddress({
        email,

        retailer_pickup_or_dropoff_address1,
        retailer_pickup_or_dropoff_city,
        retailer_pickup_or_dropoff_state,
        retailer_pickup_or_dropoff_zip,
        retailer_pickup_or_dropoff_country,

        retailer_pickup_or_dropoff_details,

      }),
    );
    addPhone();

    //GENERATE API KEY
    //dispatch(doGenerateApiKey({ email }));




  };

  const retailer_pickup_or_dropoff_address1 = currentProfile.retailer_pickup_or_dropoff_address1;
  const retailer_pickup_or_dropoff_city = currentProfile.retailer_pickup_or_dropoff_city;
  const retailer_pickup_or_dropoff_state = currentProfile.retailer_pickup_or_dropoff_state;
  const retailer_pickup_or_dropoff_zip = currentProfile.retailer_pickup_or_dropoff_zip;
  const retailer_pickup_or_dropoff_country = currentProfile.retailer_pickup_or_dropoff_country;
  
  const placeholder_address = 
  retailer_pickup_or_dropoff_address1 + " " +
  retailer_pickup_or_dropoff_city + " " +
  retailer_pickup_or_dropoff_state + " " +
  retailer_pickup_or_dropoff_zip + " " +
  retailer_pickup_or_dropoff_country;


  useEffect(() => {


    

    //(document.getElementById('pickup_address') as HTMLInputElement).placeholder = currentProfile.retailer_pickup_or_dropoff_address1;

    //alert(sessionStorage.getItem("retailer_pickup_or_dropoff_address1"))
    //if (sessionStorage.getItem("retailer_pickup_or_dropoff_address1") == "null") {
      //alert("HERE")
    //  const inputElement = document.getElementById("pickup_address") as HTMLInputElement;
    //  inputElement.placeholder = "";
    //}



  }, []);
  




  return (
    <>
      <Box
        as="section"
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '2rem',
          width: '100%',
          '@md': {
            display: 'grid',
            gridTemplateColumns: '50% 40%',
            columnGap: '10%',
          },
        }}
      >
        <Box
          as="div"
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            '@sm': {
              display: 'grid',
              gridTemplateColumns: '49% 49%',
              columnGap: '2%',
            },
          }}
          className="left-side"
        >
          <Box
            as="span"
            css={{
              color: '$gray2',
              '@sm': { gridColumnStart: '1', gridColumnEnd: '3' }, paddingBottom: '20px'
            }}
          >
            Contact information
          </Box>

          <Box
            as="span"
            css={{
              color: '$gray2',
              '@sm': { gridColumnStart: '1', gridColumnEnd: '3' }, paddingBottom: '20px'
            }}
          >
            <TextField 
            required 
            disabled
            placeholder=""
            label="Email" 
            type="text"
            style = {{width: '100%'}}
            variant="outlined"
            InputProps={{ sx: { borderRadius: 4, backgroundColor: 'white'} }}
            value={email || ''}
          />
          </Box>


          <Box
            as="span"
            css={{
              color: '$gray2',
              '@sm': { gridColumnStart: '1', gridColumnEnd: '3' }, paddingBottom: '20px'
            }}
          >
            <TextField 
            required 
            placeholder=""
            label="Company name" 
            type="text"
            style = {{width: '100%'}}
            variant="outlined"
            InputProps={{ sx: { borderRadius: 4, backgroundColor: 'white'} }}
            value={currentProfile.retailer_name}
            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_name: e.target.value,
              }))
            }
          />
          </Box>

          

          <TextField 
            required 
            placeholder=""
            label="First Name" 
            type="text"
            style = {{width: '100%'}}
            variant="outlined"
            InputProps={{ sx: { borderRadius: 4, backgroundColor: 'white'} }}
            value={currentProfile.retailer_contact_first_name}
            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_contact_first_name: e.target.value,
              }))
            }
          />

          <TextField 
            required 
            placeholder=""
            label="Last Name" 
            type="text"
            style = {{width: '100%'}}
            variant="outlined"
            InputProps={{ sx: { borderRadius: 4, backgroundColor: 'white'} }}
            value={currentProfile.retailer_contact_last_name}

            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_contact_last_name: e.target.value,
              }))
            }
          />

          <Box
            css={{
              width: '100%', paddingTop: '5px', paddingBottom: '0px'
            }}
          >

            <PhoneInput
              containerStyle={{
                margin: '0.625rem 0',
              }}
              buttonStyle={{
                borderRadius: '16px 0 0 16px',
                backgroundColor: 'white',
                border: 'solid 1px #C4C4C4',
                borderRight: 'none',
              }}
              inputStyle={{
                height: '3.8rem',
                borderRadius: '16px',
                border: 'solid 1px #C4C4C4',
                width: '100%',
              }}
              placeholder="Phone number *"
              value={currentProfile.retailer_contact_phone}
              onChange={(value, data: { dialCode: string }) => {
                setCurrentProfile((state) => ({
                  ...state,
                  retailer_contact_phone: value,
                  retailer_contact_country_code: data.dialCode,
                }));
              }}
              country="us"
              onlyCountries={['us']}
            />

          </Box>

        {/*
          <Box
            as="span"
            css={{
              gridColumnStart: '1',
              gridColumnEnd: '3',
              paddingTop: '20px',
              paddingBottom: '10px',
              color: '$gray2',
            }}
          >
            Pickup location
          </Box>

          
          

          <Box
            as="span"
            css={{
              color: '$gray2',
              '@sm': { gridColumnStart: '1', gridColumnEnd: '3' }, paddingBottom: '20px'
            }}
          >
            {/*
            <TextField 
            required 
            placeholder=""
            label="Pickup address" 
            type="text"
            style = {{width: '100%', paddingBottom: '10px'}}
            variant="outlined"

            InputProps={{ sx: { borderRadius: 4, backgroundColor: 'white'} }}
            value={currentProfile.retailer_pickup_or_dropoff_address1}
            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_pickup_or_dropoff_address1: e.target.value,
              }))
            }
          />

         

          <input id="pickup_address" 
          
              placeholder={placeholder_address} 
              
              ref={ref} style={{ width: '92%', lineHeight: '25px', padding: '15px', borderRadius:'16px', border: 'solid 1px #C4C4C4',  
              fontWeight: 'normal',
              fontFamily: 'pt_root_uimedium'
              
              
              
              }} onChange={(e) => autoAddress(e.target.value)} />




          </Box>

          
 */}
 
{
          //<Input
          //css={{
          //  '@sm': { gridColumnStart: '1', gridColumnEnd: '3'},
          //}}
          //  color="white"
          //  margin="normal"
          //  label="Company name *"
          //  value={currentProfile.retailer_name}
          //  onChange={(e) =>
          //    setCurrentProfile((state) => ({
          //      ...state,
          //      retailer_name: e.target.value,
          //    }))
          //  }
          ///>

          //<Input
          //  color="white"
          //  label="Web link"
          //  margin="normal"
          //  value={currentProfile.retailer_website}
          //  onChange={(e) =>
          //    setCurrentProfile((state) => ({
          //      ...state,
          //      retailer_website: e.target.value,
          //    }))
          //  }
          // />

          }

          {
            //<Input
            //  label="Description"
            //  color="white"
            //  margin="normal"
            //  value={currentProfile.retailer_description}
            //  onChange={(e) =>
            //    setCurrentProfile((state) => ({
            //      ...state,
            //      retailer_description: e.target.value,
            //    }))
            //  }
            //  css={{
            //    '@sm': { gridColumnStart: '1', gridColumnEnd: '3' },
            //  }}
            // />
          }

          {/*
    
          <Input
            label="Pickup city *"
            color="white"
            margin="normal"
            required
            value={currentProfile.retailer_pickup_or_dropoff_city}
            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_pickup_or_dropoff_city: e.target.value,
              }))
            }
          />
          <Input
            label="Pickup state *"
            color="white"
            margin="normal"
            required
            value={currentProfile.retailer_pickup_or_dropoff_state}
            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_pickup_or_dropoff_state: e.target.value,
              }))
            }
          />
          <Input
            label="Pickup country *"
            required
            color="white"
            margin="normal"
            value={currentProfile.retailer_pickup_or_dropoff_country}
            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_pickup_or_dropoff_country: e.target.value,
              }))
            }
          />
          <Input
            color="white"
            margin="normal"
            label="ZIP *"
            required
            value={currentProfile.retailer_pickup_or_dropoff_zip}
            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_pickup_or_dropoff_zip: e.target.value,
              }))
            }
          />
          
                
        */}
         

{/*
         <Box
            as="span"
            css={{
              color: '$gray2',
              '@sm': { gridColumnStart: '1', gridColumnEnd: '3' }, paddingBottom: '0px'
            }}
          >
           
           <TextField 
            required 
            placeholder=""
            label="Pickup instruction" 
            type="text"
            style = {{width: '100%'}}
            variant="outlined"
            multiline rows={4}
            
            InputProps={{ sx: { borderRadius: 4, backgroundColor: 'white'} }}
            value={currentProfile.retailer_pickup_or_dropoff_details}
            onChange={(e) =>
              setCurrentProfile((state) => ({
                ...state,
                retailer_pickup_or_dropoff_details: e.target.value,
              }))
            }
          />


          </Box>

          */}

        </Box>
        <Box
          as="div"
          className="right-side"
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '@md': { width: '16rem' },
          }}
        >
          <Box css={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Box as="span" css={{ color: '$gray2' }}>
              Avatar
            </Box>
            <Button
              onClick={() => {
                selectFile({ multiple: false } as any, ({ source }: any) => {
                  setCurrentProfile((state) => ({
                    ...state,
                    retailer_profile_pic: `${source}`,
                  }));
                });
              }}
              css={{
                border: 'solid 2px $gray',
                br: '$5',
                width: '15rem',
                height: '9rem',
                bc: '$white',
                color: '$gray3',
                p: 0,
              }}
            >
              {currentProfile.retailer_profile_pic ? (
                <Box
                  as="img"
                  src={currentProfile.retailer_profile_pic}
                  css={{
                    objectFit: 'cover',
                    width: 'calc(15rem - 2px)',
                    height: 'calc(9rem - 2px)',
                    br: '$5',
                  }}
                />
              ) : (
                <>
                  <Box css={{ color: '$gray' }}>
                    <PhotographIcon style={{ height: '60px' }} />
                  </Box>
                  Upload file
                  <br />
                  no more 10 Mb
                </>
              )}
            </Button>
          </Box>
          <Modal
            open={showVerifyPhone}
            title="Verify Phone"
            onClose={() => setShowVerifyPhone(false)}
            okText="Verify"
            onOk={verifyPhone}
            css={{ width: '30%', 'z-index':'1000'}}
            content={
              <Box css={{ width: '100%' }}>
                <p>We sent a verification code to your number </p>
                <br></br>
                <Box
                  css={{
                    height: '3.2rem',
                    alignSelf: 'end',
                    marginBottom: '1rem',
                  }}
                >
                  <PhoneInput
                    buttonStyle={{
                      borderRadius: '16px 0 0 16px',
                      backgroundColor: 'white',
                      border: 'solid 1px #79838A',
                      borderRight: 'none',
                    }}
                    inputStyle={{
                      height: '3.2rem',
                      borderRadius: '16px',
                      border: 'solid 1px #79838A',
                      width: '100%',
                    }}
                    placeholder="Phone number *"
                    value={currentProfile.retailer_contact_phone}
                    disabled
                  />
                </Box>
                <Input
                  //css={{ width: '50%' }}
                  color="white"
                  label="Code from SMS"
                  onChange={(e) =>
                    setVerifyPhone((state) => ({
                      ...state,
                      code: e.target.value,
                    }))
                  }
                />
              </Box>
            }
          />






        </Box>
        <Box
          css={{
            display: 'flex',
            alignSelf: 'start',
            gridColumnStart: '1',
            gridColumnEnd: '3',
            marginTop: '2rem',
          }}
        >
          <Button
            color="calmYellow"
            
            //disabled={
              //!currentProfile.retailer_contact_first_name ||
              //!currentProfile.retailer_contact_last_name
              //!currentProfile.retailer_pickup_or_dropoff_address1 ||
              //!currentProfile.retailer_pickup_or_dropoff_city ||
              //!currentProfile.retailer_pickup_or_dropoff_state ||
              //!currentProfile.retailer_pickup_or_dropoff_zip ||
              //!currentProfile.retailer_pickup_or_dropoff_country ||
              //!currentProfile.retailer_name
            //}
            onClick={handleSubmit}
            css={{ width: '15.5rem' }}
          >
            Save
          </Button>
         

          <Modal
            css={{ marginLeft: '1rem', marginBottom: '2rem' }}
            okText="OK and continue"
            title="Generate API Key"
            cssTrigger={{ bc: '$calmYellow', height: '3rem' }}
            content={
              <Box
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '2rem',
                }}
              >
                {/* TODO: API MUST BE MAPPED WITH THE RESPONSE ON GENERATE APIKEY */}
                <Input
                  disabled
                  label="apikey"
                  color="white"
                  css={{ width: '95%', margin: '1rem 0rem' }}
                  value={apiKey}
                />
                <Box
                  as="p"
                  css={{
                    fontSize: '.8rem',
                    textAlign: 'center',
                    margin: '1rem 0rem',
                  }}
                >
                  API key created and it will be displayed only now. If you lose
                  access to this key, you can generate the new API key again on
                  the dashboard.
                </Box>
              </Box>
            }
          >
            {
              //<Button
              //  disabled={
              //    !currentProfile.retailer_contact_first_name ||
              //    !currentProfile.retailer_contact_last_name ||
              //    !currentProfile.retailer_pickup_or_dropoff_address1 ||
              //    !currentProfile.retailer_pickup_or_dropoff_city ||
              //    !currentProfile.retailer_pickup_or_dropoff_state ||
              //    !currentProfile.retailer_pickup_or_dropoff_zip ||
              //    !currentProfile.retailer_pickup_or_dropoff_country ||
              //    !currentProfile.retailer_name
              //  }
              //  onClick={async () => {
              //    const res: any = await dispatch(generateApiKey({ email }));
              //    setApiKey(res.apikey);
              //  }}
              //  css={{
              //    all: 'unset',
              //    width: '15.5rem',
              //  }}
             // >
              //</Modal>  Generate API Key
              //</Button>
            }  
            
          </Modal>
        </Box>
      </Box>
      <br></br>
      <br></br>
    </>
  );
};

export default Info;
