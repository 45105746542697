import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk } from '.';

import { API_BASE, CROSS_ENDPOINTS } from '../constants';
import { enqueueSnackbar } from './snackbarSlice';

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    loading: false,
    error: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFailed: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setFailed } = signupSlice.actions;

export const addWaitingList =
  ({ email, zipCode, navigate }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const formdata = new FormData();
      formdata.append('email', email);
      formdata.append('type', 'retailer');
      formdata.append('zip', zipCode);
      const res = await axios.post(
        `${API_BASE}${CROSS_ENDPOINTS.addWaitingList}`,
        formdata,
      );

      if (res.data.message === 'record_successfully_added') {
        dispatch(
          enqueueSnackbar({ message: 'Zip code sent', variant: 'success' }),
        );
        navigate('/login');
      }
    } catch (error) {
      console.error(error);
      dispatch(setFailed(true));
    } finally {
      dispatch(setLoading(false));
    }
  };

export default signupSlice.reducer;
