import axios from 'axios';

const URL = '/submitContactForm';
const baseUrl = `https://api.goflyy.com/v1`;

export class ContactService {
  static async submitContactForm(
    email: string,
    personName: string,
    businessName: string,
    message: string,
  ) {
    const contactUrl = baseUrl + URL;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('person_name', personName);
    formData.append('business_name', businessName);
    formData.append('message', message);
    formData.append('type', 'retailer');
    try {
      const response = await axios.post(contactUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
