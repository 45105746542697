import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ArrowLeftIcon } from '@heroicons/react/solid';

import Box from '../../components/Box';
import PagesLayout from '../../components/PagesLayout';
import Button from '../../components/Button';
import LinkStyled from '../../components/LinkStyled';
import Card from '../../components/Card';
import TextArea from '../../components/TextArea';
import { useAuth, useAuthRedirect } from '../../utils/use-auth';
import { axiosAuthInstance } from '../../utils/axios-utils';
import { API_BASE, CROSS_ENDPOINTS } from '../../constants';
import { AppDispatch } from '../../store';
import { enqueueSnackbar } from '../../store/snackbarSlice';

const Ticket = () => {
  useAuthRedirect();

  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { email } = useAuth();
  const [ticketData, setTicketData] = useState({
    created_at: '',
    details: '',
    messages: [],
    status: '',
    ticket_count: '',
    ticket_id: '',
    type: '',
    updated_at: null,
  });
  const [message, setMessage] = useState('');

  const getTickets = useCallback(async () => {
    if (!email) return;
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('max', '10');

    const res = await axiosAuthInstance.post(
      `${API_BASE}${CROSS_ENDPOINTS.getTickets}`,
      formdata,
    );

    if (!res.data.message) {
      setTicketData(res.data.find((tcket) => tcket.ticket_id === id));
    }
  }, [id, email]);

  useEffect(() => {
    getTickets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTicketMessage = () => {
    if (!email) return;
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('ticket_id', id);
    formdata.append('author_email', email);
    formdata.append('message', message || '');
    axiosAuthInstance
      .post(`${API_BASE}${CROSS_ENDPOINTS.addTicketMessage}`, formdata)
      .then((res) => {
        if (res.data.message === 'ticket_not_exist') {
          dispatch(
            enqueueSnackbar({
              message: 'Ticket not exist',
              variant: 'error',
            }),
          );
        }
        if (res.data.message === 'author_not_exist') {
          dispatch(
            enqueueSnackbar({
              message: 'Ticket not exist',
              variant: 'error',
            }),
          );
        }

        getTickets();
        setMessage('');
      });
  };

  if (!id) {
    return null;
  }

  if (!ticketData) {
    return null;
  }

  return (
    <PagesLayout>
      <Box
        as="section"
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box css={{ display: 'flex', alignItems: 'center' }}>
          <LinkStyled to="/support">
            <Button
              color="white"
              css={{ padding: '2px 5px 1px 5px', width: '3rem' }}
            >
              <ArrowLeftIcon style={{ height: '22px' }} />
            </Button>
          </LinkStyled>
          <Box
            as="h1"
            css={{
              fontSize: '1.5rem',
              marginLeft: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '7rem',
            }}
          >
            Ticket
            <Box as="span" css={{ color: '$gray2', fontSize: '3rem' }}>
              &#183;
            </Box>
            <Box as="span" css={{ color: '$gray2', fontSize: '1.5rem' }}>
              {ticketData?.ticket_id ?? ' - '}
            </Box>
          </Box>
        </Box>
        <Card
          color="gray"
          css={{
            width: '92%',
            display: 'flex',
            flexDirection: 'column',
            padding: '.8rem',
            marginTop: '1rem',
            '@sm': { width: '50%' },
          }}
        >
          <Box
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box as="span" css={{ color: '$gray2', fontSize: '.7rem' }}>
              {ticketData?.type ?? ' - '}
            </Box>
            <Box
              css={{
                border: 'solid .12rem $calmYellow',
                width: '4rem',
                textAlign: 'center',
                br: '$5',
              }}
            >
              {ticketData?.status ?? ' - '}
            </Box>
          </Box>
          <Box
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '.5rem',
            }}
          >
            <Box as="span" css={{ color: '$gray2', fontSize: '.7rem' }}>
              {email}
            </Box>
            <Box as="span" css={{ color: '$gray2', fontSize: '.7rem' }}>
              {ticketData?.created_at ?? ' - '}
            </Box>
          </Box>
          <Box
            as="p"
            css={{
              marginTop: '2rem',
              width: '96%',
              padding: '.5rem',
            }}
          >
            {ticketData?.details ?? ' - '}
          </Box>
        </Card>
        {ticketData?.messages.map((msg) => {
          return (
            <Card
              key={msg.created_at}
              color="white"
              css={{
                width: '92%',
                display: 'flex',
                flexDirection: 'column',
                padding: '.8rem',
                marginTop: '1rem',
                '@sm': { width: '50%' },
              }}
            >
              <Box
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '11rem',
                  }}
                >
                  <Box
                    css={{
                      color: '$calmYellow',
                      bc: '$dark',
                      textAlign: 'center',
                      br: '$4',
                      padding: '0 $1',
                      mr: '$1',
                    }}
                  >
                    {msg.author_email[0].toUpperCase()}
                  </Box>
                  <Box as="span" css={{ fontSize: '.8rem' }}>
                    {msg.author_email}
                  </Box>
                </Box>
                <Box as="span" css={{ color: '$gray2', fontSize: '.7rem' }}>
                  {msg.created_at}
                </Box>
              </Box>
              <Box
                as="p"
                css={{
                  marginTop: '2rem',
                  width: '96%',
                  padding: '.5rem',
                }}
              >
                {msg.message}
              </Box>
            </Card>
          );
        })}

        <Card
          color="white"
          css={{
            border: 'solid 1px $gray2',
            width: '92%',
            display: 'flex',
            flexDirection: 'column',
            padding: '.8rem',
            marginTop: '1rem',
            '@sm': { width: '50%' },
          }}
        >
          <Box
            as="span"
            css={{ fontSize: '.7rem', padding: '.5rem', color: '$gray2' }}
          >
            User message
          </Box>
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={3}
            css={{ border: 'none' }}
          />
        </Card>
        <Button
          color="calmYellow"
          disabled={!message}
          css={{
            width: '100%',
            marginTop: '1rem',
            marginBottom: '2rem',
            '@sm': { width: '15rem' },
          }}
          onClick={addTicketMessage}
        >
          Send message
        </Button>
      </Box>
    </PagesLayout>
  );
};

export default Ticket;
