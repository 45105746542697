import { Avatar, Box, Button, CardMedia, Fab, Typography } from '@mui/material';
import styles from '../src/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { customColors } from '../../theme/palette';
import StyledButton2 from '../../UI/StyledButton2';
import ScrollBottom from '../../UI/ScrollBottom';


const phoneImageStyles = {
  height: "900px",
  width: "500px",
  ['@media (max-width:950px)']: {
    height: "666px",
    width: "370px",
  },
  ['@media (max-width:650px)']: {
    height: "432px",
    width: "240px",
  },
}

const tests = [
  <CardMedia sx={{...phoneImageStyles}} image={require("../src/Iphone1.png")}/>,
  <CardMedia sx={{...phoneImageStyles}} image={require("../src/Iphone2.png")}/>,
];


function scrollDown() {
  window.scrollTo(0, 500); // Scroll to Y-coordinate 500
}



export default () => {
  const [index, setIndex] = useState(0);
  const next = useCallback(() => {
    setIndex(prev => prev + 1);
  }, []);
  const previous = useCallback(() => {
    setIndex(prev => prev - 1);
  }, []);
  const TestSlider = useMemo(() => {
    const isNext = !tests[index + 1];
    const isPrev = !tests[index - 1];

    return () => (
      <Box sx={{...styles.testsBox, minHeight: "700px",
        ['@media (max-width:500px)']: {
          minHeight: "400px",
        },}}>
        <Button sx={{...styles.sliderButtons}} onClick={previous} disabled={isPrev}>
          <NavigateBefore />
        </Button>
        {tests[index]}
        <Button sx={{...styles.sliderButtons}} onClick={next} disabled={isNext}>
          <NavigateNext />
        </Button>
      </Box>
    )}, [index, next, previous]);

  return (
    <Box sx={{...styles.specialContentBox, alignItems: "center", ['@media (max-width:1300px)']: {
        flexDirection: "column",
      }}} className="How it works">
      <Box sx={{...styles.retailBox, height: "600px"}}>
        <Box sx={{width: "75%"}}>
          <Box>
            <Typography variant="h2">
              HOW
            </Typography>
            <Typography variant="h2">
              IT WORKS
            </Typography>
          </Box>
          <Typography variant="h3" sx={{...styles.resizeH3}}>
          Setup a demo today.
          </Typography>
          <br></br>

          <ScrollBottom>
            <StyledButton2
            //onClick={scrollDown()}
            sx={{ height: '65px', minWidth: '170px'}}
            text="Contact Us"
            //href="/signup"
            />
           </ScrollBottom>
         
        </Box>
      </Box>
      
      <Box sx={{...styles.innerBox, minHeight: "1000px", marginLeft: "100px",
        ['@media (max-width:1300px)']: {
          justifyContent: "flex-start",
          marginTop: "20px",
          marginLeft: "0px",
          minWidth: "100%",
          width: "100%",
          minHeight: "400px",
        },}}>
        <TestSlider />
      </Box>
    </Box>
  )};