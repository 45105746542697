import { useRoutes } from 'react-router-dom';

import Login from '../pages/Login';
import Forgot from '../pages/Forgot';
import NewPassword from '../pages/NewPassword';
import Profile from '../pages/Profile';
import Signup from '../pages/Signup';
import ConfirmEmail from '../pages/ConfirmEmail';
import Dashboard from '../pages/Dashboard';
import Support from '../pages/Support';
import NewTicket from '../pages/NewTicket';
import Ticket from '../pages/Ticket';
import NewOrder from '../pages/NewOrder';
import LandingPage from '../components/Landing Page/LandingPage';
import About from '../components/Landing Page/About';
import ForDevelopers from '../components/Landing Page/ForDevelopers';
import TransactionStatus from '../pages/TransactionStatus';
import BillingHistory from '../pages/BillingHistory';

const Routes = () => {
  const routes = useRoutes([
    { path: '/', element: <LandingPage /> },
    { path: '/about', element: <About /> },
    { path: '/develop', element: <ForDevelopers /> },
    { path: '/login', element: <Login /> },
    { path: '/forgot', element: <Forgot /> },
    { path: '/resetPassword', element: <NewPassword /> },
    { path: '/signup', element: <Signup /> },
    { path: '/profile', element: <Profile /> },
    { path: '/confirmEmail', element: <ConfirmEmail /> },
    { path: '/dashboard', element: <Dashboard /> },
    { path: '/support', element: <Support /> },
    { path: '/ticket/:id', element: <Ticket /> },
    { path: '/newticket', element: <NewTicket /> },
    { path: '/neworder', element: <NewOrder /> },
    { path: '/transactionCompleted', element: <TransactionStatus /> },
    { path: '/transactionCanceled', element: <TransactionStatus /> },
    { path: '/billingHistory', element: <BillingHistory /> },
  ]);
  return routes;
};

export default Routes;
