import type { CSS } from '@stitches/react';
import NumberFormat from 'react-number-format';
import { styled } from '../stitches.config';

const StyledDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid $gray2',
  height: '3rem',
  br: '$5',
  padding: '0 1rem',
  label: { fontSize: '.7rem', color: '$gray2' },
  input: {
    border: 'none',
    outline: 'none',
    flex: 1,
    width: '100%'
  },

  variants: {
    color: {
      white: {
        bc: '$white',
      },
      gray: {
        bc: '$gray',
        input: { bc: '$gray' },
      },
    },
    disabled: {
      true: {
        bc: '$gray',
      },
    },
    margin: {
      normal: {
        my: '$1',
      },
    },
  },
});

type Props = {
  forwardedRef?: React.Ref<any>;
  label?: string;
  color?: string;
  margin?: string;
  disabled?: boolean;
  css?: CSS;
  usd?: boolean;
  [key: string]: unknown;
};
const Input = ({
  forwardedRef,
  css,
  color,
  usd = false,
  label,
  margin,
  disabled = false,
  ...props
}: Props) => {
  return (
    <StyledDiv
      ref={forwardedRef}
      disabled={disabled}
      color={color as any}
      margin={margin as any}
      css={css}
    >
      <label>{label}</label>
      {usd === false ? (

        <>
        <form>
          <input  {...props} disabled={disabled} />
        </form>
        </>
        
      ) : (
        <NumberFormat
          thousandSeparator={true}
          prefix={'$'}
          disabled={disabled}
          {...props}
        />
      )}
    </StyledDiv>
  );
};

export default Input;
