import { styled } from '../stitches.config';

const Card = styled('div', {
  br: '$6',
  variants: {
    color: {
      gray: {
        bc: '$gray',
      },
      white: {
        bc: '$white',
        border: '2px solid $gray',
      },
      borderYellow: {
        bc: '$white',
        border: '2px solid $calmYellow',
      },
      linearGradientCalm: {
        background:
          'linear-gradient(360deg, rgba(253, 221, 22, 0.15) 0%, rgba(253, 221, 22, 0) 100%)',
      },
      borderBlue: {
        border: 'solid .15rem $blue',
        br: '$6',
        padding: '1.5rem',
      },
    },
    padd: {
      big: {
        p: '$11',
      },
      medium: {
        py: '$6',
        px: '$8',
      },
    },
  },
});

export default Card;
