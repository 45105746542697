import { styled } from '../stitches.config';

const Button = styled('button', {
  px: '$4',
  py: '$2',
  variants: {
    color: {
      calmYellow: {
        bc: '$calmYellow',
        br: '$5',
        border: 'none',
        height: '3rem',
        width: '7.93rem',
        marginBottom: '.5rem',
      },
      gray: {
        bc: '$gray',
        border: 'none',
        height: '3rem',
        maxWidth: '8rem',
        minWidth: '3rem',
        br: '$5',
      },
      black: {
        bc: '$dark',
        color: '$white',
        br: '$5',
        fontSize: '.8rem',
        paddingTop: '.5rem',
        border: 'none',
        marginRight: '.2rem',
        height: '2rem',
        '@sm': { height: '3rem', paddingTop: '.8rem' },
      },
      white: {
        border: 'solid .15rem $gray',
        bc: '$white',
        br: '$5',
        paddingTop: '.4rem',
        fontSize: '.8rem',
        marginRight: '.2rem',
        height: '2rem',
        '@sm': { height: '3rem', paddingTop: '.7rem' },
        '&[data-state="active"]': {
          color: '$white',
          fontFamily: '$bold',
          bc: '$dark',
          br: '$6',
        },
      },
      blue: {
        bc: '$blue',
        border: 'none',
        color: '$white',
        br: '$5',
      },
      borderCalmYellow: {
        border: 'solid .15rem $calmYellow',
        br: '$5',
        bc: '$white',
      },
      red: {
        border: 'solid .15rem $lightRed',
        br: '$5',
        bc: '$white',
      },
    },
  },
});

export default Button;
