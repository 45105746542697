import { useDispatch, useSelector } from 'react-redux';

import Box from '../../components/Box';
import Card from '../../components/Card';
import { useAuth, useAuthRedirect } from '../../utils/use-auth';
import { axiosAuthInstance } from '../../utils/axios-utils';
import { API_BASE, CROSS_ENDPOINTS, RETAILER_ENDPOINTS, SHOPIFY_ENDPOINTS } from '../../constants';
import Button from '../../components/Button';
import type { AppDispatch, RootState } from '../../store';
import { enqueueSnackbar } from '../../store/snackbarSlice';

import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';

import ReactDOM from 'react-dom';


import { useEffect, useState } from 'react';

import {
  Document,
  Page,
  View,
  Text,
  Link,
  Font,
  StyleSheet,
} from '@react-pdf/renderer';

import type { Profile } from '../../store/profileSlice';


const Integrations = () => {
  useAuthRedirect();

  const dispatch = useDispatch<AppDispatch>();
  const { email } = useAuth();
  //const profile = useSelector((state: RootState) => state.profile);

  const [profile, setProfile] = useState({
    agreement_signed: false,
    current_balance_usd: null,
  });



  useEffect(() => {
    const getProfile = async () => {
      if (!email) return;

      const formdata = new FormData();
      formdata.append('email', email);
      const res = await axiosAuthInstance.post(
        `${API_BASE}${RETAILER_ENDPOINTS.getProfile}`,
        formdata,
      );

      setProfile(res.data);
    };

    const getCountAllOrders = async () => {
      if (!email) return;

      const formdata = new FormData();
      formdata.append('email', email);
      const res = await axiosAuthInstance.post(
        `${API_BASE}${CROSS_ENDPOINTS.getCountAllOrders}`,
        formdata,
      );

      //setCountOrder(res.data);
    };

    getProfile();
    getCountAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);






  

  const styles = StyleSheet.create({

    
    title: {
      margin: 20,
      fontSize: 25,
      textAlign: 'center',
      backgroundColor: '#e4e4e4',
      textTransform: 'uppercase',
      fontFamily: 'Oswald',
    },
    body: {
      flexGrow: 1,
      height: '1000px'
    },
    row: {
      flexGrow: 1,
      flexDirection: 'row',
    },
    block: {
      flexGrow: 1,
    },
    text: {
      width: '60%',
      margin: 10,
      fontFamily: 'Oswald',
      textAlign: 'justify',
    },
    fill1: {
      width: '40%',
      backgroundColor: '#e14427',
    },
    fill2: {
      flexGrow: 2,
      backgroundColor: '#e6672d',
    },
    fill3: {
      flexGrow: 2,
      backgroundColor: '#e78632',
    },
    fill4: {
      flexGrow: 2,
      backgroundColor: '#e29e37',
    },
  });
  
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  });

// Create Document Component
const MyDocument = () => (
  <Document >
    <Page size="A4">
      <Link
        style={styles.title}
        src="https://es.wikipedia.org/wiki/Lorem_ipsum"
      >
        Lorem Ipsum
      </Link>
      <View style={styles.body}>
        <View style={styles.row}>
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum.
          </Text>
          <View style={styles.fill1} />
        </View>
        <View style={styles.row}>
          <View style={styles.fill2} />
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum.
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum.
          </Text>
          <View style={styles.fill3} />
        </View>
      </View>
    </Page>
  </Document>
);




  //CHECK TO SHOW SIGN BUTTON OR NOT
  if (profile.agreement_signed == true) {
    //location.reload();
    //navigate('/profile');
    //window.location.replace("/profile");
    //window.location.href = "/profile";
    //alert("HERE")
    document.getElementById("sign_button").hidden = true; 
  }
  








function signRetailerContract() {

  //document.getElementById("upload_area").hidden = true; 

  const data = new FormData();
  data.append("email", email);
  const xhr = new XMLHttpRequest();
  xhr.addEventListener("readystatechange", function() {
    if(xhr.readyState == 4 && xhr.status == 200) {
      //console.log(this.responseText);

      const response = xhr.responseText;
      console.log(response);
      //alert(response)
      const resp_obj = JSON.parse(response); 
      const message = resp_obj.message;

      if (message == "success") {

        document.getElementById("sign_button").hidden = true; 
        //window.location.replace("/dashboard");
        location.reload();
      }

    }
  });
  
  xhr.open("POST", "https://api.goflyy.com/v1/retailer/signRetailerContract", true);
  xhr.setRequestHeader("token", localStorage.getItem('token'));
  xhr.send(data);
}


  return (
    <Box
      as="section"
      css={{
        display: 'flex',
        flexDirection: 'column',
        margin: '2rem 0',
        width: '100%',
       
      }}
    >

      <PDFViewer height={"700px"} width="100%">
        <MyDocument />
      </PDFViewer>

      <div id="sign_button">

        <Box as="h1" css={{ fontSize: '1.0rem', paddingBottom: '20px', paddingTop: '20px' }}>By clicking the button "Sign agreement," I agree to be bound 
          by and comply with the terms and conditions outlined in the 
          agreement document provided. I understand that my acceptance 
          of this agreement signifies my commitment to fulfill all 
          obligations, responsibilities, and requirements set forth 
          in the document.</Box>

        <Button
          
          color="calmYellow"
          onClick={signRetailerContract}
          css={{ width: '15.5rem' }}
        >
          Sign agreement
        </Button>
    
      </div>

    </Box>
  );
};

export default Integrations;
