import {
  CardMedia,
  Checkbox,
  FormControlLabel, IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  //Select,
  MenuItem
} from '@mui/material';

import Select from '@mui/material/Select';

import * as React from 'react';

import Box from '../../components/Box';
import Card from '../../components/Card';
import { useAuth, useAuthRedirect } from '../../utils/use-auth';
import { axiosAuthInstance } from '../../utils/axios-utils';
import { API_BASE, CROSS_ENDPOINTS, RETAILER_ENDPOINTS } from '../../constants';
import { useEffect, useState } from 'react';


import Input from '../../components/Input';
//import Select from '../../components/Select';
import Button from '../../components/Button';
import LinkStyled from '../../components/LinkStyled';
import { usdFormat } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { enqueueSnackbar } from '../../store/snackbarSlice';
import { getProfile } from '../../store/profileSlice';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal';
import { styled } from '../../stitches.config';

const Payment = () => {
  useAuthRedirect();
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const { email } = useAuth();
  const [amount, setAmount] = useState('');
  const [pricingData, setPricingData] = useState([]);
  const [showCard, setShowCard] = useState(false);
  //const [cardData, setCardData] = useState({
  //  number: '',
  //  expMonth: '',
  //  expYear: '',
  //  cvc: '',
  //});

  const [cardNumber, setCardNumber] = useState();
  const [cardExpMonth, setCardExpMonth] = useState();
  const [cardExpYear, setCardExpYear] = useState();
  const [cardCvc, setCardCvc] = useState();


  const profile = useSelector((state: RootState) => state.profile);


  //alert(JSON.stringify(profile))

  const addBalance = async () => {
    if (!email) return;
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('amount', amount);

    const res = await axiosAuthInstance.post(
      `${API_BASE}${RETAILER_ENDPOINTS.addBalance}`,
      formdata,
    );

    if (res.data.message === 'transaction_successfully_completed') {
      dispatch(
        enqueueSnackbar({
          message: 'Transaction successfully completed',
          variant: 'success',
        }),
      );
      navigate('/dashboard');
    }

    if (res.data.data === 'payment_method_not_found') {
      dispatch(
        enqueueSnackbar({
          message: 'Payment method not found',
          variant: 'error',
        }),
      );
    }
    if (res.data.data === 'transaction_declined') {
      dispatch(
        enqueueSnackbar({
          message: 'Transaction declined',
          variant: 'error',
        }),
      );
    }
  };

  useEffect(() => {
    const getAllPrices = async () => {
      if (!email) return;

      const formdata = new FormData();
      formdata.append('email', email);
      const res = await axiosAuthInstance.post(
        `${API_BASE}${CROSS_ENDPOINTS.getAllPrices}`,
        formdata,
      );
      setPricingData(res.data.message);
    };

    getAllPrices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile.data?.creditcards?.length) {
      setShowCard(true);
    }
  }, [profile.data.creditcards]);

  const pricingFilter = pricingData.filter(
    (filter) => filter.state === profile.data.retailer_pickup_or_dropoff_state,
  );



 
  function setCardInfo(type, value){
  
    if (type == "cardNumber") {
      setCardNumber(value)
    }

    if (type == "cardExpMonth") {
      setCardExpMonth(value)
    }

    if (type == "cardExpYear") {
      setCardExpYear(value)
    }

    if (type == "cardCvc") {
      setCardCvc(value)
    }

  }



  const addCard = async () => {
    if (!email) return;
    //const { number, expMonth, expYear, cvc } = cardData;
    //const { number, expYear, expMonth, cvc } = cardData;

    const Number = cardNumber;
    const ExpMonth = cardExpMonth;
    const ExpYear = cardExpYear;
    const Cvc = cardCvc;

    //alert (Number)

    if (String(Number).length !== 16) {
      dispatch(
        enqueueSnackbar({
          message: 'Incomplete card number',
          variant: 'Error',
        }),
      );
      return;
    }

    if (ExpMonth === "" || ExpMonth === undefined) {
      dispatch(
        enqueueSnackbar({
          message: 'Incomplete Exp Month',
          variant: 'Error',
        }),
      );
      return;
    }

    if (ExpYear === "" || ExpYear === undefined) {
      dispatch(
        enqueueSnackbar({
          message: 'Incomplete Exp Year',
          variant: 'Error',
        }),
      );
      return;
    }

    if (Cvc === "" || Cvc === undefined) {
      dispatch(
        enqueueSnackbar({
          message: 'Incomplete CVC',
          variant: 'Error',
        }),
      );
      return;
    }

    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('number', Number);
    formdata.append('exp_month', ExpMonth);
    formdata.append('exp_year', ExpYear);
    formdata.append('cvc', Cvc);

    const res = await axiosAuthInstance.post(
      `${API_BASE}${CROSS_ENDPOINTS.addCard}`,
      formdata,
    );

    if (res.data.message === 'card_successfully_added') {
      dispatch(
        enqueueSnackbar({
          message: 'Card successfully added',
          variant: 'success',
        }),
      );
    }
    if (res.data.message === 'wrong_card_information') {
      dispatch(
        enqueueSnackbar({
          message: 'Wrong card information',
          variant: 'Error',
        }),
      );
    }

    //setCardData({
    //  number: '',
    //  expMonth: '',
    //  expYear: '',
    //  cvc: '',
    //});

    dispatch(getProfile({ email }));
  };



  const StyledDiv = {
    //flexDirection: 'column',
    border: '1px solid gray',
    height: '3rem',
    borderRadius: '15px',
    padding: '0 1rem',
    label: { fontSize: '.7rem', color: 'gray' },
    marginRight: '10px',
    width: '250px',
    gridColumnStart: '1',
    gridColumnEnd: '4',
    //maxWidth: '275px',
    display: 'flex',
   


  br: '$5',


  input: {
    border: 'none',
    outline: 'none',
    flex: 1,
  },
    
  }

  return (
    <Box
      as="section"
      css={{
        display: 'flex',
        flexDirection: 'column',
        margin: '2rem 0',
        width: '100%',
      }}
    >

      <Box >
       
        {!showCard ? (
          <>
          <div style={{"color":"gray"}}>Payment information</div>
          <br></br>
          
          <table>
            <tbody>
              <tr>
                <td colSpan={2} style={{paddingBottom:'10px'}}>

                  <form>

                  <input type="text" style={{"display":"none"}} />

                  <TextField 
                    required 
                    placeholder="XXXXXXXXXXXXXXXX"
                    label="Card number" 
                    style = {{width: '100%'}}
                    variant="outlined"
                    inputProps={{ maxLength: 16 }}
                    InputProps={{ sx: { borderRadius: 4 } }}

                    onChange={(e) => setCardInfo("cardNumber", e.target.value)}

                    //onChange={(e) =>
                    //  setCardData((state) => ({
                    //    ...state,
                    //    number: e.target.value,
                    //  }))
                    //}
                  />
                  </form>
                  
                </td>
              </tr>
 
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td style={{paddingRight:'10px'}}>

                  <Select
                  
                    sx={{
                      width: '200px',
                      borderRadius: 4
                      }}
                      onChange={(e) => setCardInfo("cardExpMonth", e.target.value)}
                  >
                    <MenuItem value="01">January</MenuItem>
                    <MenuItem value="02">February</MenuItem>
                    <MenuItem value="03">March</MenuItem>
                    <MenuItem value="04">April</MenuItem>
                    <MenuItem value="05">May</MenuItem>
                    <MenuItem value="06">June</MenuItem>
                    <MenuItem value="07">July</MenuItem>
                    <MenuItem value="08">August</MenuItem>
                    <MenuItem value="09">September</MenuItem>
                    <MenuItem value="10">October</MenuItem>
                    <MenuItem value="11">November</MenuItem>
                    <MenuItem value="12">December</MenuItem>

                  </Select>

                </td>
                <td>
                  
                <Select
                    sx={{
                      width: '200px',
                      borderRadius: 4
                    }}
                    onChange={(e) => setCardInfo("cardExpYear", e.target.value)}
                  >
                    <MenuItem value="2023">2023</MenuItem>
                    <MenuItem value="2024">2024</MenuItem>
                    <MenuItem value="2025">2025</MenuItem>
                    <MenuItem value="2026">2026</MenuItem>
                    <MenuItem value="2027">2027</MenuItem>
                    <MenuItem value="2028">2028</MenuItem>
                    <MenuItem value="2029">2029</MenuItem>
                    <MenuItem value="2030">2030</MenuItem>

                  </Select>

                </td>
              </tr>
              <tr >
                <td style={{paddingTop:'10px', paddingRight:'10px'}}>
                <TextField 
                    required 
                    placeholder="XXX"
                    label="CVC" 
                    style = {{width: '100%'}}
                    variant="outlined"
                    inputProps={{ maxLength: 3 }}
                    InputProps={{ sx: { borderRadius: 4 } }}

                    onChange={(e) => setCardInfo("cardCvc", e.target.value)}

                    //onChange={(e) =>
                    //  setCardData((state) => ({
                    //    ...state,
                    //    cvc: e.target.value,
                    //  }))
                    //}
                  />

                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={2} style={{textAlign:'left', paddingTop:'20px'}}>

                <Button
              color="calmYellow"
              onClick={addCard}
              css={{ width: '17.1rem'}}
            >
              Add card
            </Button>

                </td>
              </tr>
            </tbody>
          </table>

          </>
        ) : (
          <>
           <Box
            as="span"
            css={{
              color: '$gray2',
              '@sm': { gridColumnStart: '1', gridColumnEnd: '1' }, paddingBottom: '20px'
            }}
          >
            Payment method
          </Box>
          <br></br>
            <Card
              //color="linearGradientCalm"
              css={{
                width: '100%',
                height: '6rem',
                padding: '2.5rem',
                display: 'flex',
                flexDirection: 'column',
                margin: '.5rem',
                border: 'solid 1px #C4C4C4',
                '@md': { minWidth: '5rem', maxWidth: '20rem' },
              }}
            >
              <Box
                as="div"
                css={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {!!profile.data?.creditcards?.length && (
                  <>
                    <Box
                      as="img"
                      height={50}
                      src={
                        profile.data?.creditcards[
                          profile.data?.creditcards.length - 1
                        ].logo_url
                      }
                    />
                    <Box as="span">
                      { 
                        profile.data?.creditcards[
                          profile.data?.creditcards.length - 1
                        ].brand
                      }
                      ••••
                      {
                        profile.data?.creditcards[
                          profile.data?.creditcards.length - 1
                        ].last4
                      }
                    </Box>
                  </>
                )}
              </Box>
              <Box
                as="div"
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  marginTop: '2rem',
                }}
              >
                <Box
                  as="p"
                  css={{
                    marginRight: '2rem',
                    fontSize: '.7rem',
                    color: '$calmYellow',
                    textAlign: 'left',
                  }}
                >
                  Address for sending receipts
                </Box>
                <Box as="p" css={{ fontSize: '.8rem' }}>
                  {profile.data.retailer_pickup_or_dropoff_address1},{' '}
                  {profile.data.retailer_pickup_or_dropoff_city}
                </Box>
              </Box>
            </Card>
            <Box
              as="div"
              css={{
                display: 'flex',
                flexDirection: 'column',
                margin: '.5rem',
              }}
            >
              
            </Box>
          </>
        )}
      </Box>
      
    </Box>
  );
};

export default Payment;
