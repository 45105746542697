import { Box, CircularProgress } from '@mui/material';

export default () => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      minHeight: '200px',
      alignItems: 'center',
      marginBottom: '20px',
    }}
  >
    <CircularProgress sx={{}} color="primary" />
  </Box>
);
