import { useEffect, useRef, useState } from 'react';

import {
  CardMedia,
  Checkbox,
  FormControlLabel, IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  //Select,
  MenuItem
} from '@mui/material';

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import Card from '../../components/Card';
import LoginLayout from '../../components/LoginLayout';
import { useAuthRedirect } from '../../utils/use-auth';
import Input from '../../components/Input';
import Box from '../../components/Box';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { login as doLogin } from '../../store/authSlice';
import {
  addPhoneWithoutVerification,
  addAddress,
  addProfile,
  addContact,
} from '../../store/profileSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';

export default function Login() {
  const dispatch: AppDispatch = useDispatch();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const mountCalled = useRef(false);
  const navigate = useNavigate();

  const searchSplit = search.split('&');

  const urlEmail = searchSplit[0].replace('?email=', '');
  const urlPass = searchParams.get('pass');
  const urlName = searchParams.get('name');
  const urlDomain = searchParams.get('domain');
  const urlShopOwner = searchParams.get('shop_owner');
  const urlPhone = searchParams.get('phone');
  const urlAddress1 = searchParams.get('address1');
  const urlCity = searchParams.get('city');
  const urlProvince = searchParams.get('province');
  const urlCountryName = searchParams.get('country_name');
  const urlZip = searchParams.get('zip');

  useAuthRedirect(false, !!urlEmail);
  const [loginState, setLoginState] = useState({
    email: '',
    password: '',
  });

  const login = () => {
    const { email, password } = loginState;
    dispatch(doLogin({ email, password }));
  };

  useEffect(() => {
    const loginFromUrl = async () => {
      if (urlEmail && urlPass) {
        const shopifyName = urlShopOwner.split(' ');
        await dispatch(doLogin({ email: urlEmail, password: urlPass }));
        dispatch(
          addPhoneWithoutVerification({
            email: urlEmail,
            country_code: '+1',
            phone: urlPhone,
          }),
        );
        await dispatch(
          addAddress({
            email: urlEmail,
            retailer_pickup_or_dropoff_address1: urlAddress1,
            retailer_pickup_or_dropoff_city: urlCity,
            retailer_pickup_or_dropoff_state: urlProvince,
            retailer_pickup_or_dropoff_zip: urlZip,
            retailer_pickup_or_dropoff_country: urlCountryName,
          }),
        );
        await dispatch(
          addProfile({
            email: urlEmail,
            retailer_name: urlName,
            retailer_website: urlDomain,
          }),
        );

        await dispatch(
          addContact({
            email: urlEmail,
            retailer_contact_first_name: shopifyName[0],
            retailer_contact_last_name:
              shopifyName.length >= 2 ? shopifyName[1] : shopifyName[0],
          }),
        );
        navigate('/dashboard');
      }
    };
    if (!mountCalled.current) {
      loginFromUrl();
      mountCalled.current = true;
    }
  }, [
    urlEmail,
    urlPass,
    urlPhone,
    urlAddress1,
    urlCity,
    urlProvince,
    urlZip,
    urlCountryName,
    urlName,
    urlShopOwner,
    urlDomain,
    dispatch,
    navigate,
  ]);

  return (
    <LoginLayout>
      <Card
        css={{
          width: '70%',
          maxWidth: '25rem',
          display: 'flex',
          flexDirection: 'column',
        }}
        color="gray"
        padd="medium"
      >
        <Box
          as="h1"
          css={{ alignSelf: 'center', margin: '2rem', fontSize: '1.3rem' }}
        >
          Sign in account
        </Box>

                  <TextField 
                    required 
                    placeholder=""
                    label="Email address" 
                    type="email"
                    style = {{width: '100%'}}
                    variant="outlined"
                    InputProps={{ sx: { borderRadius: 4, backgroundColor: 'white'} }}
      
                    onChange={(e) =>
                      setLoginState((state) => ({
                        ...state,
                        email: e.target.value,
                      }))
                    }
                  />
                  <br></br>

                  <TextField 
                    required 
                    placeholder=""
                    type="password"
                    label="Password" 
                    style = {{width: '100%'}}
                    variant="outlined"
                    InputProps={{ sx: { borderRadius: 4, backgroundColor: 'white'} }}
      
                    onChange={(e) =>
                      setLoginState((state) => ({
                        ...state,
                        password: e.target.value,
                      }))
                    }
                  />


        <Box
          as="div"
          css={{
            marginTop: '4rem',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
            justifyContent: 'space-evenly',
          }}
        >
          <Button onClick={login} color="calmYellow">
            Sign in
          </Button>
          <ButtonLink href="/signup" color="gray">
            Sign up
          </ButtonLink>
         
        </Box>
        <br></br>
          <ButtonLink href="/forgot" css={{ color: '$gray2', textAlign:'center'}}>
            Forgot password?
          </ButtonLink>
      </Card>
      
      {/*
      <Box
        as="div"
        css={{
          bc: '$dark',
          color: '$white',
          br: '$7',
          height: '1.5rem',
          minWidth: '2.5rem',
          marginTop: '-.26rem',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 1,
        }}
      >
        or
      </Box>
      <Card
        css={{
          marginTop: '-.26rem',
          width: '70%',
          maxWidth: '25rem',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          ' .hide-mobile': {
            display: 'none',
          },
          '@sm': {
            ' .hide-mobile': {
              display: 'block',
              marginLeft: '.3rem',
            },
          },
        }}
        color="white"
        padd="medium"
      >
        <Button
          color="gray"
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <AppleIcon />
          <Box as="span" className="hide-mobile">
            Apple
          </Box>
        </Button>
        <Button
          color="gray"
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <FacebookRoundedIcon />
          <Box as="span" className="hide-mobile">
            Facebook
          </Box>
        </Button>
        <Button
          color="gray"
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <GoogleIcon />
          <Box as="span" className="hide-mobile">
            Google
          </Box>
        </Button>
      </Card>
      */}

    </LoginLayout>
  );
}
